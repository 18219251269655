import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from 'react-select'
import cx from 'classnames'

import {
  defaultFont
} from 'src/assets/jss/mvp'

const styles = theme => ({
  label: {
    ...defaultFont,
    marginLeft: 2,
    marginBottom: 6,
    fontSize: '13px',
    color: '#7F7F7F'
  },

  fullWidth: {
    width: '100%'
  }
})

const selectStyles = {
  container: (base, state) => ({
    ...base,
    ...defaultFont,
    fontSize: '13px',
  }),
  option: (base, state) => ({
    ...base,
    ...defaultFont,
    fontSize: '13px',
    fontWeight: 'bold',
    color: state.isSelected ? '#ED154E' : '#282828',
    backgroundColor: '#FFFFFF',
    padding: 9,
    '&:hover': {
      color: '#141414',
      backgroundColor: '#FADCE5'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ?
      '#D3D3D3' :
      '#ED154E',
    '&:hover': {
      color: state.isDisabled ?
      '#D3D3D3' :
      '#ED154E',
    }
  }),

  control: (base, state) => ({
    ...base,
    ...defaultFont,
    fontSize: '13px',
    outline: 0,
    color: '#282828',
    boxShadow: 0,
    minHeight: 35,
    height: 35,
    borderRadius: 0,
    border: state.isFocused ?
      '1px solid #ED154E' :
      '1px solid rgba(0, 0, 0, 0.05)',
    '&:hover': {
      border: state.isFocused ?
        '1px solid #ED154E' :
        '1px solid rgba(0, 0, 0, 0.15)',
    }
  }),

  placeholder: (base, state) => ({
    ...base,
    ...defaultFont,
    color: state.isDisabled ? '#D3D3D3' : '#CCC'
  }),

  noOptionsMessageCSS: (base, state) => ({
    ...base,
    ...defaultFont,
    fontSize: '13px',
    color: '#4d4d4d'
  })
}

const selectOptionValue = (options, value) => options.reduce(
  (prev, curr) => curr.value === value ? curr : prev,
  null
)

@withStyles(styles)
export default class  MVPSelect extends Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: '',
    }
  }

  render() {
    const { classes, label, value, options, fullWidth, ...props } = this.props
    return (
      <div className={
        cx({
          [classes.fullWidth] :fullWidth
        })
      }>
        {
          label ?
            <div className={classes.label}>{label}</div> :
            null
        }
        {
          this.props.isFixedPosition ?
          <Select
            styles={{
              ...selectStyles,
              menu: (base, state) => ({
                ...base,
                borderRadius: 0,
                position: 'relative'
              }),
            }}
            menuPosition="fixed"
            value={selectOptionValue(options, value)}
            options={options}
            {...props}
          /> :
          <Select
            styles={{
              ...selectStyles,
              menu: (base, state) => ({
                ...base,
                borderRadius: 0,
              }),
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })

            }}
            menuPortalTarget={document.body}
            menuPosition="absolute"
            value={selectOptionValue(options, value)}
            options={options}
            {...props}
          />
        }
      </div>
    )
  }
}

