import React, { useState, useEffect } from 'react'
import _flatten from 'lodash/flatten'
import { observer } from 'mobx-react'
import { useHistory, useParams, useLocation } from 'react-router-dom'

import { ErrorOutline } from '@material-ui/icons'

import MailingCard from 'components/Mailings/MailingCard'
import MailingDetailsDrawer from 'components/Mailings/MailingDetailsDrawer'
import MailingModel, { repository as mailsRepo } from 'models/Mailing'

import MUITable from '@material-ui/core/Table'
import TablePagination from 'components/MVP/Pagination/Pagination'
import { useStores } from 'config/store'

import { useMailingsStyles } from './Mailings.style'

interface BaseObject {
  id: string
  [K: string]: any
}

const Mailing: React.FC = () => {
  const { mailingsStore, mapsStore } = useStores()
  const { patientLists } = mapsStore
  const { totalCountDelivered, pageSize, currentPage } = mailingsStore
  const classes = useMailingsStyles()
  const history = useHistory()
  const { mailingId } = useParams()
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const showLessDelivered: boolean = false

  useEffect(() => {
    let mailing: BaseObject | undefined = undefined
    let newMailing: BaseObject | undefined = undefined
    let newId: string | undefined = undefined

    if (mailingId) {
      mailing = mailsRepo.get(mailingId)

      if (!mailing) {
        newMailing = new MailingModel()
        newMailing.id = mailingId

        newId = mailsRepo.upsert(newMailing)
        mailing = mailsRepo.get(newId)

        selectMailing(mailing)
      }

      setDrawerOpen(true)
    } else {
      setDrawerOpen(false)
    }
  }, [mailingId])

  const selectMailing = (mailing: BaseObject) => {
    if (mailing) {
      mailing.update()
      setDrawerOpen(true)
      mailingsStore.setDrawer(mailing)
    }
  }

  const handleViewMailing = (mailing: MailingModel) => {
    mailingsStore.setDrawer(mailing)
    history.push(`/mailings/${mailing.id}`)
  }

  const handleCloseDrawer = () => {
    history.push('/mailings')
  }

  return (
    <div className={classes.root}>
      {mailingsStore.isLoadingDeliveredMailings ? (
        <div className={`${classes.upcoming} ${classes.gridContainer}`}>
          <div className={classes.placeholderDelivered} />
          <div className={classes.placeholderDelivered} />
          <div className={classes.placeholderDelivered} />
          <div className={classes.placeholderDelivered} />
        </div>
      ) : totalCountDelivered > 0 ? (
        <div
          className={`${classes.upcoming} ${classes.gridContainer} ${
            showLessDelivered ? classes.showLessDelivered : null
          }`}
        >
          {mailingsStore.itemsPerPageDelivered
            .slice()
            .map((mailing: MailingModel, index: number) => {
              return (
                <MailingCard
                  index={index}
                  key={mailing.id}
                  mailing={mailing}
                  onViewAction={handleViewMailing}
                />
              )
            })}
        </div>
      ) : (
        <div className={classes.noResults}>
          <ErrorOutline /> You don't have any delivered mailings
        </div>
      )}

      <MailingDetailsDrawer open={drawerOpen} onClose={handleCloseDrawer} />

      {totalCountDelivered > 4 && (
        <MUITable>
          <TablePagination
            totalItems={totalCountDelivered}
            currentPage={currentPage}
            pageSize={pageSize}
            onChangePage={(page: number) => {
              mailingsStore.setCurrentPage(page)
            }}
            onChangePageSize={(pageSize: number) => {
              mailingsStore.setPageSize(pageSize)
            }}
          />
        </MUITable>
      )}
    </div>
  )
}

export default observer(Mailing)
