import React, { useState, Fragment } from 'react'
import {
  Person as ProfileIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'
import { Observer } from 'mobx-react'
import cx from 'classnames'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Paper, Link, Tooltip } from '@material-ui/core'

import UserAccountList from './UserAccountList'
import UserAccountSelectorModal from './UserAccountSelectorModal'
import styles from './UserAccountSelector.styles'
import AppState from '../../../config/store/AppState'
import AccountStore from '../../../config/store/AccountStore'

const UserAccountSelector = (props) => {
  const { classes, theme, drawerOpen } = props

  const [isOpen, setOpen] = useState(false)

  const handleMultipleExpand = () => {
    drawerOpen()
  }

  return (
    <Observer
      render={() => {
        const { profileName, profilePicture, accounts } = AppState

        const { _hiddenAccounts, hiddenAccounts } = AccountStore

        const _selectedAccounts = accounts
          .filter((account) => {
            return (
              account.children.length === 0 &&
              !_hiddenAccounts.includes(account.id)
            )
          })
          .map((account) => account.name)
        const selectedAccounts = accounts
          .filter((account) => {
            return (
              account.children.length === 0 &&
              !hiddenAccounts.includes(account.id)
            )
          })
          .map((account) => account.name)
        const totalAccounts = accounts
          .filter((account) => {
            return account.children.length === 0
          })
          .map((account) => account.name)

        const _selectedCount = _selectedAccounts.length
        const selectedCount = selectedAccounts.length
        const totalCount = totalAccounts.length

        const _description =
          _selectedCount == 0
            ? 'No accounts selected'
            : `Viewing ${
                (_selectedCount == 1 && _selectedAccounts[0]) ||
                `${_selectedCount}/${totalCount} Accounts`
              }`
        const description =
          selectedCount == 0
            ? 'No accounts selected'
            : `Viewing ${
                (selectedCount == 1 && selectedAccounts[0]) ||
                `${selectedCount}/${totalCount} Accounts`
              }`

        return (
          <Paper className={classes.paper} elevation={4}>
            <div className={classes.root}>
              {profilePicture ? (
                <Avatar
                  src={profilePicture}
                  className={classes.avatar}
                  onClick={handleMultipleExpand}
                />
              ) : (
                <Avatar
                  onClick={handleMultipleExpand}
                  className={classes.avatar}
                  style={{ backgroundColor: theme.palette.primary.light }}
                >
                  <ProfileIcon
                    className={classes.avatar}
                    style={{
                      width: 30,
                      height: 30,
                      marginRight: 0,
                      color: '#fff',
                    }}
                  />
                </Avatar>
              )}
              <div className={cx(classes.details)}>
                <div className={classes.profileName}>
                  {profileName}
                  {AppState.isLimitedAccess() && ' (Limited Access)'}
                </div>
                <div className={classes.accountSelector}>
                  {(totalCount == 1 && (
                    <Tooltip
                      classes={{ tooltip: classes.navTooltip }}
                      title={
                        totalAccounts[0].length > 25 ? totalAccounts[0] : ''
                      }
                      placement="right"
                    >
                      <span className={classes.singleAccountDescription}>
                        {totalAccounts[0]}
                      </span>
                    </Tooltip>
                  )) || (
                    <Fragment>
                      <Link
                        className={classes.pointer}
                        onClick={() => {
                          AccountStore.retrieveHidden()
                          setOpen(true)
                        }}
                      >
                        <FilterIcon className={classes.filterIcon} />
                      </Link>
                      <Tooltip
                        classes={{ tooltip: classes.navTooltip }}
                        title={
                          (selectedCount == 1 &&
                            selectedAccounts[0].length > 25 &&
                            selectedAccounts[0]) ||
                          ''
                        }
                        placement="right"
                      >
                        <Link
                          className={classes.accountDescription}
                          onClick={() => {
                            AccountStore.retrieveHidden()
                            setOpen(true)
                          }}
                        >
                          {description}
                        </Link>
                      </Tooltip>
                      <UserAccountSelectorModal
                        title={_description}
                        isOpen={isOpen}
                        onClose={() => {
                          setOpen(false)
                        }}
                        applyCallback={() => {
                          if (!_selectedAccounts.length) {
                            alert('Please select one or more accounts')
                          } else {
                            AccountStore.applyHidden()
                            setOpen(false)
                          }
                        }}
                      >
                        <UserAccountList />
                      </UserAccountSelectorModal>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Paper>
        )
      }}
    />
  )
}

export default withRouter(withStyles(styles)(UserAccountSelector))
