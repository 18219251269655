import React from 'react'
import { observer } from 'mobx-react'

import Call from 'models/Call'
import { useStores } from 'config/store'
import { Table } from 'components/MVP/Table'

import { renderColumns } from './CallsTable'

interface CallsDataGridProps {
  classes: any
  stopAudio: boolean
  handleSelectCall: (call: Call) => void
}

const CallsDataGrid: React.FC<CallsDataGridProps> = ({
  classes,
  stopAudio,
  handleSelectCall
}) => {
  const { callsStore } = useStores()
  const {
    isLoading,
    error,
    currentPage,
    pageSize,
    itemsPerPage,
    totalItemsPerPage,
    orderBy,
    dir,
    toggleSorting,
    setCurrentPage,
    setPageSize
  } = callsStore

  return (
    <Table
      columns={renderColumns({ classes, stopAudio })}
      data={itemsPerPage.slice()}
      isLoading={isLoading}
      isError={Boolean(error)}
      totalItems={totalItemsPerPage}
      currentPage={currentPage}
      pageSize={pageSize}
      orderBy={orderBy}
      dir={dir}
      onChangePage={(page: number) => {
        setCurrentPage(page)
      }}
      onChangePageSize={(pageSize: number) => {
        setPageSize(pageSize)
      }}
      onColumnSort={(column: string) => toggleSorting(column)}
      onDataRowClick={handleSelectCall}
      noResultsText={
        callsStore.searchQueryPattern && 'No matching calls or appointments.'
      }
    />
  )
}

export default observer(CallsDataGrid)
