import React from 'react'
import { observer } from 'mobx-react'

import Card from '@material-ui/core/Card'

import MailingCardInfo from 'components/Mailings/MailingCardInfo'
import MailingCardActions from 'components/Mailings/MailingCardActions'
import DeliveredMailingCardInfo from 'components/Mailings/DeliveredMailingCardInfo'
import DeliveredMailingCardActions from 'components/Mailings/DeliveredMailingCardActions'
import MailingThumbnail from 'components/Mailings/MailingThumbnail'
import AuditedMailingCardInfo from 'components/Mailings/AuditedMailingCardInfo'

import Mailing from 'models/Mailing'
import { useStores } from 'config/store'
import { useMailingCardStyles } from './MailingCard.style'

const MailingCard: React.FC<{
  index: number
  mailing: Mailing
  onViewAction: (data: Mailing) => void
}> = ({ index, mailing, onViewAction }) => {
  const classes = useMailingCardStyles()
  const { mapsStore } = useStores()
  const handleAction = (action: string, mailing: Mailing) => {
    if (action === 'view') {
      onViewAction(mailing)
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.card} elevation={0}>
        <div className={classes.header}>
          <MailingCardInfo mailing={mailing} index={index} />
        </div>
        <MailingThumbnail mailing={mailing} onAction={handleAction} />
        {mailing.status === 'delivered' ||
        mailing.status === 'deliveredInFirstCall' ? (
          <div className={classes.details}>
            {mailing.matchedNewPatients > 0 ? (
              <AuditedMailingCardInfo mailing={mailing} />
            ) : (
              <DeliveredMailingCardInfo mailing={mailing} />
            )}
          </div>
        ) : null}
        <div className={classes.actions}>
          {mailing.status === 'delivered' ||
          mailing.status === 'deliveredInFirstCall' ? (
            <DeliveredMailingCardActions
              mailing={mailing}
              onAction={handleAction}
            />
          ) : (
            <MailingCardActions mailing={mailing} onAction={handleAction} />
          )}
        </div>
      </Card>
    </div>
  )
}

export default observer(MailingCard)
