import React from 'react'
import { Tooltip } from '@material-ui/core'

import { useMailingAuditNoticeStyle } from './MailingAuditNotice.style'

const MailingAuditNotice: React.FC<{
  title: string
  description: string
  children: React.ReactElement<any, any>
  showNoticed: boolean
  index: number
}> = ({ title, description, children, showNoticed, index }) => {
  const classes = useMailingAuditNoticeStyle()

  return (
    <React.Fragment>
      <Tooltip
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.arrow,
        }}
        title={
          <span className={classes.contentWrapper}>
            <h3 className={classes.title}>{title}</h3>
            <p className={classes.description}>{description}</p>
          </span>
        }
        placement="top"
        open={showNoticed}
      >
        {children}
      </Tooltip>
    </React.Fragment>
  )
}

export default MailingAuditNotice
