import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import withStyles from '@material-ui/core/styles/withStyles'
import { Player } from 'react-media-player'

import medalImg from 'src/assets/img/medal.png'
import PlayPause from 'src/components/AudioPlayer/PlayPause'
import Media from 'src/components/AudioPlayer/Media'
import { CircularProgress } from '@material-ui/core'

import CallsBadge from 'src/components/Calls/CallsBadge'

import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import moment from 'moment'
import momentDuration from 'moment-duration-format'

import { defaultFont } from 'src/assets/jss/mvp'

// Setup moment duration plugin
momentDuration(moment)

const styles = (theme) => ({
  recentCallsWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '32px',
    '& h2': {
      fontFamily: 'Roboto Condensed',
      fontSize: '17px',
      color: '#282828',
      marginBottom: 14,
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      flexBasis: '100%',
      fontFamily: 'Roboto Condensed',
      fontSize: 14,
      marginBottom: 24,
      '& td': {
        padding: 9,
      },
      '& tr:nth-child(odd)': {
        backgroundColor: 'rgb(241,241,241)',
      },
    },
    '& a': {
      margin: '14px 0px',
      color: 'rgb(225,40,61)',
      fontFamily: 'Roboto Condensed',
      fontWeight: '400',
      fontSize: '14px',
      marginTop: '0',
    },
  },
  count: {
    position: 'relative',
    textAlign: 'center',
    width: '22px',
    height: '25px',
    margin: 'auto',
    '& span': {
      color: '#fff',
      fontFamily: 'Roboto Condensed',
      fontWeight: '600',
      fontSize: '10px',
      position: 'relative',
      top: '-30px',
    },
    '& img': {
      width: 22,
    },
  },
  media: {
    marginTop: -8,
  },
  callsLoader: {
    width: '100%',
    height: 215,
    background: '#f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h1': {
      fontFamily: 'Roboto Condensed',
      fontSize: '18px',
      color: '#e9414d',
    },
  },
  appointmentsCount: {
    position: 'absolute !important',
    top: '-6px !important',
    left: '9px !important',
  },
})

@inject('mailingsStore')
@withStyles(styles)
@observer
export default class MailingRecentCalls extends Component {
  state = {
    calls: {},
    callsCount: 0,
    noRelatedCalls: false,
  }

  async componentDidMount() {
    const { mailingsStore } = this.props
    let relatedCalls = await mailingsStore.fetchRecentCalls(
      mailingsStore.drawer.id
    )
    if (
      has(relatedCalls, 'meta.totalCount') &&
      relatedCalls.meta.totalCount != 0
    ) {
      this.setState({
        calls: relatedCalls.records,
        callsCount: relatedCalls.meta.totalCount,
      })
    } else {
      this.setState({ noRelatedCalls: true })
    }
  }

  render() {
    const { classes, mailingsStore, ...props } = this.props
    let { callsCount, calls } = this.state

    return (
      <div className={classes.recentCallsWrapper}>
        <h2>Most Recent Calls</h2>
        {calls.length > 0 ? (
          <a
            href={`/calls/?mailing=${
              calls.length > 0 ? mailingsStore.drawer.id : '#'
            }`}
          >
            View All ({callsCount})
          </a>
        ) : null}
        <table>
          <tbody>
            {!isEmpty(calls) ? (
              calls.map((call) => {
                const dateTime = moment(call.callDateTime)
                return (
                  <tr key={call.id}>
                    <td>
                      <span className={classes.count}>
                        {call.appointmentsCount <= 0 ? (
                          <CallsBadge />
                        ) : (
                          <img src={medalImg} />
                        )}
                        {call.appointmentsCount > 0 ? (
                          <span className={classes.appointmentsCount}>
                            {call.appointmentsCount}
                          </span>
                        ) : null}
                      </span>
                    </td>
                    <td>{call.callerName || '--'}</td>
                    <td>
                      {dateTime.isValid()
                        ? dateTime.format('M/D/YY @ h:mm a')
                        : '-'}
                    </td>
                    <td>
                      <Media>
                        <span className={classes.media}>
                          <span className="media-player">
                            <Player src={call.callAudio} vendor={'audio'} />
                          </span>
                          <span className="media-controls">
                            <PlayPause
                              callDuration={call.callDuration}
                              stopAudio={false}
                            />
                          </span>
                        </span>
                      </Media>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr className={classes.callsLoader}>
                <td>
                  <h1>
                    {this.state.noRelatedCalls ? (
                      <span>No recent calls</span>
                    ) : (
                      <span>
                        <CircularProgress
                          size={20}
                          style={{
                            marginRight: 8,
                            color: '#333',
                            opacity: 0.9,
                            top: 4,
                            position: 'relative',
                          }}
                        />{' '}
                        Loading calls...
                      </span>
                    )}
                  </h1>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
