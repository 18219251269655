import { makeStyles } from '@material-ui/core'
import placeholderDelivered from 'assets/img/thumbnail1.png'

export const useMailingsStyles = makeStyles({
  root: {},

  placeholderDelivered: {
    backgroundImage: `url(${placeholderDelivered})`,
    width: 300,
    height: 361,
    backgroundSize: 'cover',
    marginRight: 16,
    marginBottom: 16,
  },

  hideSection: {
    overflow: 'hidden',
    maxHeight: '0px !important',
    marginBottom: 24,
    position: 'relative',
    zIndex: 999,
  },
  separator: {
    borderBottom: 'solid 1px rgb(229,229,229)',
  },
  showLessDelivered: {
    overflow: 'hidden',
    height: 388,
    zIndex: 999,
    position: 'relative',
  },

  upcoming: {
    marginBottom: 20,
  },

  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },

  grid: {
    padding: '16px !important',
  },

  warning: {
    color: '#F50D0D',
    fontFamily: 'Roboto Condensed',
    fontWeight: 400,
    fontStyle: 'italic',
    fontSize: '15px',
    marginLeft: '16px',
  },
  noResults: {
    fontSize: '26px',
    height: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#d4d4d4',
    display: 'flex',
    borderTop: 'solid 1px rgb(229,229,229)',
    '& svg': {
      fontSize: '32px',
      marginRight: '8px',
    },
  },
})
