import axios, { AxiosRequestConfig } from 'axios'
import { when } from 'mobx'

import AppState from 'config/store/AppState'
import processAccessToken from 'services/grants/processAccessToken'

export const IDENTITY_API = 'identity'
export const DATA_API = 'data'
export const MAPS_API = 'maps'

class AppError extends Error {
  public data: any
  public status: any

  constructor(message: any, data = {}, status = 500) {
    super(message)
    this.data = data
    this.status = status
  }
}

// Authentication interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const response = error.response

    // Do something with response error
    if (
      response &&
      (response.status === 401 || response.status === 403) &&
      !response.config.useAuthInterceptor
    ) {
      // Maybe try to refresh the access token
      // or maybe alert Auth service that token is invalid (AuthService.checkSession)
      AppState.clearSession()
      AppState.errorMessage('You have been logged out')
    }

    if (response && response.status === 409) {
      const token = AppState.accessToken
      const session = await processAccessToken(token)
      await AppState.setSession(session)
      window.location.href = '/calls'
    }

    return Promise.reject(error)
  }
)

/**
 *
 * @param {string} api
 */
const API_BASE_URLS = {
  [IDENTITY_API]: process.env.REACT_APP_IDENTITY_URL,
  [DATA_API]: process.env.REACT_APP_DATA_URL,
  [MAPS_API]: process.env.REACT_APP_MAPS_URL,
}

export interface RequestConfig extends Partial<AxiosRequestConfig> {
  api: string
  skipAuthentication?: boolean
  useAuthInterceptor?: boolean
}

export const request = async <T>({
  api,
  method,
  url,
  params,
  data,
  headers,
  cancelToken,
}: RequestConfig) => {
  const baseURL = API_BASE_URLS[api as 'identity' | 'data' | 'maps']
  return axios.request<T>({
    url,
    method,
    baseURL,
    headers,
    params,
    data,
    cancelToken,
  })
}

/**
 * High level API request. Automatically builds authorization and other
 * relevant headers and provides handling of JSend format responses
 *
 * @param {object} options
 *
 * @returns {object} The returned data
 */
export const call = async <T>(options: RequestConfig = {} as RequestConfig) => {
  let { headers, skipAuthentication } = options

  const isLoggedIn = await AppState.isAuthenticated
  if (!isLoggedIn && !skipAuthentication) {
    await awaitAuthentication()
  }

  const accessToken = AppState.accessToken
  const authorizationHeaders = {} as any
  if (accessToken) {
    authorizationHeaders['Authorization'] = `Bearer ${accessToken}`
  }
  headers = Object.assign(
    authorizationHeaders || {},
    AppState.grantHeaders || {},
    headers
  )

  options.headers = headers

  // catch 401 errors
  options.useAuthInterceptor = true

  try {
    const response = await request<T>(options)
    const { status, message, data, code } = response.data as any

    if (status === 'error') {
      throw new Error(message)
    }

    if (status === 'fail') {
      throw new Error('API call validation failed')
    }

    return data
  } catch (error) {
    const response = error.response
    if (response && response.data && response.data.message) {
      throw new AppError(
        response.data.message,
        response.data.data,
        response.status
      )
    }
    throw error
  }
}

const awaitAuthentication = async () => {
  return when(() => AppState.isAuthenticated)
}

export const postRequest = async <T>(options: RequestConfig) => {
  options.method = 'post'
  return call<T>(options)
}

export const putRequest = async <T>(options: RequestConfig) => {
  options.method = 'put'
  return call<T>(options)
}

export const getRequest = async <T>(options: RequestConfig) => {
  options.method = 'get'
  return call<T>(options)
}

export const deleteRequest = async <T>(options: RequestConfig) => {
  options.method = 'delete'
  return call<T>(options)
}

export default {
  IDENTITY_API,
  DATA_API,
  call,
  get: getRequest,
  post: postRequest,
  put: putRequest,
  delete: deleteRequest,
  request,
}
