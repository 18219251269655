// ##############################
// // // Table styles
// #############################

import _merge from 'lodash/merge'

import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont
} from 'src/assets/jss/material-dashboard-react.jsx'

export default theme => ({
  warningTableHeader: {
    color: warningColor
  },
  primaryTableHeader: {
    color: primaryColor
  },
  dangerTableHeader: {
    color: dangerColor
  },
  successTableHeader: {
    color: successColor
  },
  infoTableHeader: {
    color: infoColor
  },
  roseTableHeader: {
    color: roseColor
  },
  grayTableHeader: {
    color: grayColor
  },

  tableResponsive: {
    width: '100%',
    overflowX: 'auto'
  },
})
