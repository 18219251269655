import {
  defaultFont
} from './index'

export default {
  container: {
    ...defaultFont,
    display: 'inline-block'
  },
  wrapper: {
    position: 'relative',
    userSelect: 'none',
    paddingBottom: '1rem',
    flexDirection: 'row'
  },
  interactionDisabled: {
    '& $day': {
      cursor: 'default'
    }
  },
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  month: {
    display: 'table',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    userSelect: 'none',
    margin: '0 1rem',
    position: 'relative',
    '&:last-of-type:before': {
      display: 'block',
      borderLeft: '1px solid rgba(210, 210, 210, 0.5)',
      content: '""',
      position: 'absolute',
      left: '-1rem',
      top: '0',
      height: '100%'
    }
  },

  navBar: {

  },
  navButtonPrev: {

  },
  navButtonNext: {

  },
  navButtonInteractionDisabled: {
    display: 'none'
  },

  caption: {
    // display: 'table-caption',
    display: 'none',
    padding: '0 0.5rem',
    textAlign: 'left',
    marginBottom: '0.5rem',
    '& > div': {
      fontSize: '1.15rem',
      fontWeight: '500'
    }
  },
  weekdays: {
    marginTop: '1rem',
    display: 'table-header-group'
  },
  weekdaysRow: {
    display: 'table-row'
  },
  weekday: {
    display: 'table-cell',
    padding: '0.5rem',
    fontSize: '0.875em',
    textAlign: 'center',
    color: '#8b9898',
    '& abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none'
    }
  },
  body: {
    display: 'table-row-group',
  },
  week: {
    display: 'table-row',
  },
  weekNumber: {
    display: 'table-cell',
    padding: '0.5rem',
    textAlign: 'right',
    verticalAlign: 'middle',
    minWidth: '1rem',
    fontSize: '0.75em',
    cursor: 'pointer',
    color: '#8b9898',
    borderRight: '1px solid #eaecec',
  },
  day: {
    display: 'table-cell',
    fontSize: '13px',
    padding: 0,
    width: 34,
    height: 34,
    opacity: 0.9999,
    textAlign: 'center',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  footer: {
    paddingTop: '0.5rem'
  },

  todayButton: {
    border: 'none',
    backgroundImage: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
    color: '#4a90e2',
    fontSize: '0.875em',
  },

  // default modifiers
  today: {
    color: '#d0021b',
    fontWeight: '700',
  },
  selected: {
    '&:not($disabled):not($outside)': {
      position: 'relative',
      // color: '#f0f8ff',
      backgroundColor: 'transparent',
      borderRadius: '100%',
    },
    '&:not($disabled):not($outside):before': {
      content: '""',
      backgroundColor: '#ededed',
      display: 'block',
      height: '28px',
      width: '28px',
      position: 'absolute',
      zIndex: '-1',
      left: 'calc(50% - 14px)',
      top: 'calc(50% - 15px)',
      borderRadius: '50%',
    },
    '&:not($disabled):not($outside):hover': {
      opacity: '0.9'
    }
  },
  disabled: {
    color: '#dce0e0',
    cursor: 'default',
  },
  outside: {
    cursor: 'default',
    color: '#8b9898',
  },
  sunday: {
    backgroundColor: '#f7f8f8',
    '&:not($today)': {
      color: '#dce0e0',
    }
  },
}
