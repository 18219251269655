import React, { Component } from 'react'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'

import mailingPlaceholder from 'src/assets/img/mailing-placeholder.png'

import withStyles from '@material-ui/core/styles/withStyles'
import MailingDesignPreview from './MailingDesignPreview'
import CircularProgress from '@material-ui/core/CircularProgress'

import { defaultFont } from 'src/assets/jss/mvp'

const styles = (theme) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
  },
  smallHeading: {
    display: 'block',
    color: 'rgb(124,124,124)',
    fontFamily: 'Roboto Condensed',
    fontSize: 14,
  },
  designImg: {
    width: '100%',
    position: 'relative',
    zIndex: 200,
  },
  loader: {
    position: 'absolute',
    zIndex: 100,
  },
  thumbWrapper: {
    backgroundColor: 'rgb(239,239,239)',
    border: 'solid 5px rgb(239,239,239)',
    marginTop: 8,
    marginBottom: 16,
    width: '100%',
    height: 'auto',
    minHeight: '262px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  status: {
    position: 'absolute',
    zIndex: '999',
    marginTop: '22px',
    color: '#cacaca',
  },
})

@inject('mailingsStore')
@withStyles(styles)
class MailingDesign extends Component {
  constructor(props) {
    super(props)
    const mailing = this.props.mailingsStore.drawer
    this.state = {
      frontDesignImageUrl: mailing.frontDesignThumbnailUrl,
      backDesignImageUrl: mailing.backDesignThumbnailUrl,
      isFailedFront: false,
      isFailedBack: false,
      showPreview: false,
      previewFace: '',
    }
  }

  handleAction = (action, mailing) => (event) => {
    const { onAction } = this.props
    onAction(action, {
      mailing,
      event,
    })
  }

  handleMissingDesignFront = () => {
    this.setState({
      isFailedFront: true,
    })
  }

  handleMissingDesignBack = () => {
    this.setState({
      isFailedBack: true,
    })
  }

  handleClosePreview = () => {
    this.setState({ showPreview: false })
  }

  handleFrontPreview = () => {
    this.setState({
      previewFace: 'front',
      showPreview: true,
    })
  }

  handleBackPreview = () => {
    this.setState({
      previewFace: 'back',
      showPreview: true,
    })
  }

  render() {
    const { mailingsStore, classes } = this.props
    const mailing = mailingsStore.drawer
    const {
      frontDesignImageUrl,
      backDesignImageUrl,
      isFailedFront,
      isFailedBack,
    } = this.state

    return (
      <div className={classes.root}>
        <label className={classes.smallHeading}>Front</label>
        <div className={classes.thumbWrapper}>
          <CircularProgress
            className={classes.loader}
            size={50}
            color="primary"
          />
          <img
            onClick={
              isFailedFront || frontDesignImageUrl === undefined
                ? null
                : this.handleFrontPreview
            }
            onError={this.handleMissingDesignFront}
            className={classes.designImg}
            src={
              isFailedFront || frontDesignImageUrl === undefined
                ? mailingPlaceholder
                : frontDesignImageUrl
            }
          />
          {isFailedFront || frontDesignImageUrl === undefined ? (
            mailing.status === 'deliveredInFirstCall' &&
            mailing.status === 'delivered' ? (
              <div className={classes.status}>
                <h4>We can't load the image</h4>
              </div>
            ) : (
              <div className={classes.status}>
                <h4>Mailing design is coming soon</h4>
              </div>
            )
          ) : null}
        </div>

        <label className={classes.smallHeading}>Back</label>
        <div className={classes.thumbWrapper}>
          <CircularProgress
            className={classes.loader}
            size={50}
            color="primary"
          />
          <img
            onClick={
              isFailedBack || backDesignImageUrl === undefined
                ? null
                : this.handleFrontPreview
            }
            onError={this.handleMissingDesignBack}
            className={classes.designImg}
            src={
              isFailedBack || backDesignImageUrl === undefined
                ? mailingPlaceholder
                : backDesignImageUrl
            }
          />
          {isFailedBack || backDesignImageUrl === undefined ? (
            mailing.status === 'delivered' &&
            mailing.status === 'deliveredInFirstCall' ? (
              <div className={classes.status}>
                <h4>We can't load the image</h4>
              </div>
            ) : (
              <div className={classes.status}>
                <h4>Mailing design is coming soon</h4>
              </div>
            )
          ) : null}
        </div>

        {this.state.showPreview ? (
          <MailingDesignPreview
            previewFace={this.state.previewFace}
            closePreview={this.handleClosePreview}
          />
        ) : null}
      </div>
    )
  }
}

export default withStyles(styles)(MailingDesign)
