import React, { useContext } from 'react'

import { HighlightedText, HighlightedPhoneNumber, HighlightContext } from 'components/Text'
import Call from 'models/Call'

interface CallerCellProps {
  call: Call
  classes: any
}

const CallerCell: React.FC<CallerCellProps> = ({ call, classes }) => {
  const highlightPattern = useContext(HighlightContext)

  const callerName = call.callerName ?? call.callerIdNameFmt

  if (typeof highlightPattern === 'undefined') {
    return (
      <div className={classes.colStackedText}>
        {callerName && <div>{callerName}</div>}
        {call.callerPhoneNumber && <div>{call.callerPhoneNumber}</div>}
      </div>
    )
  }

  const callerNameMatched = call.getCallerNameMatched(highlightPattern)

  return (
    <div className={classes.colStackedText}>
      {callerNameMatched && (
        <div>
          <HighlightedText>{callerNameMatched}</HighlightedText>
        </div>
      )}
      {call.callerPhoneNumberRaw && (
        <div>
          <HighlightedPhoneNumber>{call.callerPhoneNumberRaw}</HighlightedPhoneNumber>
        </div>
      )}
    </div>
  )
}

export default CallerCell
