import React from 'react'
import cx from 'classnames'
import { useTheme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import DetailsIcon from '@material-ui/icons/FiberManualRecord'
import { observer } from 'mobx-react'

import ConversionRate from 'components/Mailings/ConversionRate'

import Mailing from 'models/Mailing'
import { useAuditedMailingResultStyles } from './AuditedMailingResult.style'
import Star from '../../../assets/img/patientList/patientMarkers/star.svg'

const AuditedMailingResult: React.FC<{
  mailing: Mailing
}> = ({ mailing }) => {
  const classes = useAuditedMailingResultStyles()

  return (
    <div className={classes.root}>
      <div className={cx(classes.detailsPanel, classes.detailSeparator)}>
        <div
          className={
            mailing.matchedTotalProduction > 0
              ? classes.detail
              : classes.detailWithoutProduction
          }
        >
          <div className={classes.newPatientsStats}>
            <img src={Star} />
            <span className={classes.totalNewPatients}>
              {mailing.matchedNewPatients}
              {mailing.matchedTotalProduction <= 0 && (
                <span className={classes.labelWithoutProduction}>
                  New Patients
                </span>
              )}
            </span>
            <span className={classes.leadsCount}>
              {' '}
              /<label>{mailing.leadsCount}</label>
            </span>
            <span className={classes.leadLabel}>Leads</span>
          </div>
          <div>
            {mailing.matchedTotalProduction > 0 && (
              <div className={classes.label}>New Patients</div>
            )}
          </div>
        </div>
      </div>
      {mailing.matchedTotalProduction > 0 && (
        <div className={cx(classes.detailsPanel)}>
          <div className={classes.detail}>
            <div className={classes.totalProduction}>
              <span>
                $
                {Number(mailing.matchedTotalProduction).toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 0,
                  }
                )}
              </span>
            </div>
            <div>
              <div className={classes.label}>{'Total Production'}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(AuditedMailingResult)
