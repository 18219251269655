import { useContext } from 'react'
import { configure } from 'mobx'
import { MobXProviderContext } from 'mobx-react'

import appState, { AppState } from 'config/store/AppState'
// import Main from './Main'
// import UserStore from './UserStore'
import callsStore, { CallsStore } from 'config/store/CallsStore'
import configStore, { ConfigStore } from 'config/store/ConfigStore'
import mailingsStore, { MailingsStore } from 'config/store/MailingsStore'
import settingsStore, { SettingsStore } from 'config/store/SettingsStore'
import mapsStore, { MapsStore } from 'config/store/MapsStore'
import accountStore, { AccountStore } from 'config/store/AccountStore'

// Set to true to ensure that all state mutations
// are produced by mobx actions only
configure({ enforceActions: 'never' })

interface Stores {
  appState: AppState
  callsStore: CallsStore
  configStore: ConfigStore
  mailingsStore: MailingsStore
  settingsStore: SettingsStore
  mapsStore: MapsStore
  accountStore: AccountStore
}

export const useStores = (): Stores => {
  return useContext<Stores>(MobXProviderContext)
}

const stores: Stores = {
  appState,
  configStore,
  // mainStore: Main,
  // userStore: UserStore,
  callsStore,
  mailingsStore,
  settingsStore,
  mapsStore,
  accountStore
}

export default stores
