import React, {
  Fragment
} from 'react'
import { Observer } from 'mobx-react'
import _toArray from 'lodash/toArray'
import _size from 'lodash/size'
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  withStyles,
  withTheme,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core'
import {
  ArrowDropDown,
  ArrowDropUp,
} from '@material-ui/icons'

import accountStore from '../../../config/store/AccountStore'
import styles from './UserAccountList.styles'

const UserAccountList = (props) => {
  const {
    classes
  } = props

  const handleCheck = (accountId) => (e) => {
    e.preventDefault()
    const item = accountStore.accounts.find(account => account.id === accountId)
    if (item && item.subAccountIds.length) {
      const every = item.subAccountIds.every(
        subAccount => accountStore._selectedAccounts.includes(subAccount)
      )
      accountStore.toggleAccounts(item.subAccountIds, !every)
      return
    }
    accountStore.toggleAccount(accountId)
  }

  const handleToggleSection = (accountId) => () => {
    const newHideSections = accountStore.hideSections.slice()
    if (newHideSections.includes(accountId)) {
      newHideSections.splice(accountStore.hideSections.indexOf(accountId), 1)
    } else {
      newHideSections.push(accountId)
    }
    accountStore.hideSections = newHideSections
  }

  const renderUserSubAccountList = (account) => (
    <Collapse
      in={!accountStore.hideSections.includes(account.id)}
      timeout={500}
    >
      <List component="nav" disablePadding>
        {_toArray(account.subAccounts).map((childAccount, key) => (
          <ListItem
            key={`${childAccount.id}--${key}`}
            classes={{
              root: classes.listChild
            }}
          >
            <FormControlLabel
              classes={{
                root: classes.listText,
                label: classes.listTextPrimary
              }}
              control={
                <Checkbox
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checked
                  }}
                  color="primary"
                  onClick={handleCheck(childAccount.id)}
                  checked={
                    accountStore._selectedAccounts.includes(childAccount.id)
                  }
                />
              }
              label={childAccount.name.replace(`${account.name} - `, '')}
              labelPlacement="end"
            />
          </ListItem>
        ))}
      </List>
    </Collapse>
  )

  return (
    <Observer
      render={() => {
        const {
          accounts,
          _selectedAccounts,
          hideSections
        } = accountStore

        return (
          <Fragment>
            {
              accounts.map((account, key) => {
                const selectedCount = account.subAccountIds.reduce(
                  (acc, curr) => acc + (
                    _selectedAccounts.includes(curr) ? 1 : 0
                  ), 0
                )
                const totalCount = account.subAccountIds.length

                const all = (0 < totalCount && selectedCount === totalCount)
                const some = (0 < selectedCount && selectedCount < totalCount)

                const isLeafAndSelected = (
                  totalCount === 0 &&
                  _selectedAccounts.includes(account.id)
                )

                const countDescription = 0 < totalCount ? ` (${selectedCount}/${totalCount})` : ''

                return (
                  <Fragment key={`${account.id}--${key}`}>
                    <List classes={{
                      root: classes.listLayout
                    }}>
                      <ListItem classes={{
                        root: classes.listItem
                      }}>
                        <ListItemText classes={{
                          root: classes.listItemText
                        }}>
                          <FormControlLabel
                            classes={{
                              root: classes.listText,
                              label: classes.listTextPrimary
                            }}
                            control={
                              accountStore.accounts.length >= 1 &&
                              <Checkbox
                                classes={{
                                  root: classes.checkbox,
                                  checked: classes.checked
                                }}
                                color="primary"
                                checked={all || isLeafAndSelected}
                                indeterminate={some && !all}
                                onClick={handleCheck(account.id)}
                              />
                            }
                            label={account.name + countDescription}
                          />
                        </ListItemText>
                        {
                          account.subAccounts && _size(account.subAccounts) > 0 &&
                          <ListItemSecondaryAction
                            classes={{
                              root: classes.actionButton
                            }}
                          >
                            <IconButton
                              color="primary"
                              onClick={handleToggleSection(account.id)}
                              classes={{
                                root: classes.dropButton
                              }}
                            >
                              {!hideSections.includes(account.id) ? <ArrowDropUp /> : <ArrowDropDown />}
                            </IconButton>
                          </ListItemSecondaryAction>
                        }
                      </ListItem>
                    </List>
                    {
                      account.subAccounts && _size(account.subAccounts) > 0 &&
                      renderUserSubAccountList(account)
                    }
                  </Fragment>
                )
              })
            }
          </Fragment>
        )
      }}
    />
  )
}

export default withTheme(withStyles(styles)(UserAccountList))