import React, { useEffect } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { observer } from 'mobx-react'
import localforage from 'localforage'
import { create as createJss } from 'jss'
import { StylesProvider, jssPreset } from '@material-ui/styles'
import jssExtend from 'jss-plugin-extend'
import { Provider } from 'mobx-react'

import 'config/analytics'

// Styling
import '../src/assets/css/app.scss'

import { configureSentry } from 'config/sentry'
import stores from 'config/store'
import identify from 'services/analytics/identify'
import ThemeProvider from 'components/Theme/Provider'

import AppRouter from './AppRouter'

configureSentry()

localforage.config({
  driver: localforage.LOCALSTORAGE,
  name: 'mvpmailhouse.com',
})

const jss = createJss({
  plugins: [jssExtend(), ...jssPreset().plugins],
})

const App: React.FC = () => {
  const { appState } = stores

  useEffect(() => {
    appState.onAuthChange((isAuthenticated: any) => {
      if (isAuthenticated) {
        identify()
      }
    })
  }, [])

  if (appState.loading) {
    return <div style={{ padding: 20 }}>{appState.loading}</div>
  }

  return (
    <ErrorBoundary fallback={<div>An error has occurred</div>}>
      <StylesProvider jss={jss}>
        <ThemeProvider>
          <Provider {...stores}>
            <AppRouter appState={appState} />
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  )
}

export default observer(App)
