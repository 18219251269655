const styles = theme => ({
  root: {
    display: 'flex',
    margin: '15px 10px'
  },

  hide: {
    opacity: 0
  },

  profileName: {
    flex: '1 1 auto',
    fontSize: '16px',
    color: '#fff',
    marginRight: 16,
    textOverflow: 'ellipsis',
    overflowWrap: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: '400'
  },

  currentAccount: {
    color: '#D3D3D395',
    fontSize: '14px',
    fontWeight: '400'
  },

  actionButton: {
    right: 0
  },

  dropButton: {
    padding: 0
  },

  listLayout: {
    padding: 0
  },

  listItem: {
    padding: '14px 3px',
    userSelect: 'none',
    borderBottom: '1px solid rgba(227, 227, 227, 0.5)'
  },

  listChild: {
    padding: '14px 3px 14px 22px',
    userSelect: 'none',
    borderBottom: '1px solid rgba(227, 227, 227, 0.5)'
  },

  listItemText: {
    margin: 0
  },

  listText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    padding: 0,
    margin: 0
  },

  listTextPrimary: {
    color: 'black',
    fontSize: '16px',
    fontFamily: 'Roboto Condensed',
    fontWeight: '900',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    padding: 0,
    marginLeft: 16
  },

  listTextSecondary: {
    color: '#727272',
    fontSize: '12px'
  },

  checkbox: {
    height: 18,
    width: 18,
    color: theme.palette.primary.main,
    transition: 'none',
    '&$checkbox': {
      borderRadius: 3,
      padding: 0,
      '&.Mui-checked, &.MuiCheckbox-indeterminate, &.Mui-checked:hover, &.MuiCheckbox-indeterminate:hover': {
        backgroundColor: '#fff',
      },
    },
  },

  checked: {
    height: 18,
    width: 18,
  }
})

export default styles
