import { makeStyles, Theme } from '@material-ui/core'
import { secondaryFont } from 'assets/jss/mvp'

const progressSize = 65

export const useConversionRateStyles = makeStyles((theme: Theme) => ({
  conversion: {
    display: 'flex',
    width: '100%',
    padding: 14,
  },

  conversionWrapper: {
    display: 'flex',
    position: 'relative',
    width: progressSize,
    height: progressSize,
    justifyContent: 'center',
    alignItems: 'center',
  },

  progress: {
    position: 'absolute',
    right: 0,
  },

  rate: {
    ...secondaryFont,
    position: 'absolute',
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ED154E',
    textAlign: 'center',
    '& span': {
      fontWeight: '600',
      lineHeight: '20px',
      fontSize: '16px',
    },
    '& span + span': {
      fontSize: '10px',
      fontWeight: '500',
      lineHeight: '11.5px',
    },
  },
}))
