import { makeStyles, Theme } from '@material-ui/core'
import * as mvp from 'assets/jss/mvp'

export const useMailingsSummaryStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 68,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  dropdown: {
    ...mvp.defaultFont,
    fontSize: '18px',
    textTransform: 'none',
    color: theme.palette.primary.main,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 160,
    marginRight: 50,
  },
  dropdownMenuItem: {
    ...mvp.defaultFont,
    fontSize: '14px',
    minWidth: 160,
    height: '20px',
    '&$selected:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      cursor: 'default',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '& > $menuIcon': {
      color: theme.palette.primary.main,
      visibility: 'unset',
    },
  },
  menuText: {
    marginRight: '1em',
  },
  menuIcon: {
    visibility: 'hidden',
    minWidth: '1em',
    '& > svg': {
      width: 16,
      height: 16,
    },
  },
  summary: {
    minWidth: 50,
    marginRight: 15,
  },
  summaryFigure: {
    ...mvp.defaultFont,
    fontSize: '20px',
    fontWeight: 700,
    display: 'block',
    textAlign: 'center',
    color: '#232323',
    margin: 4,
  },
  summaryLabel: {
    ...mvp.defaultFont,
    color: 'rgba(35, 35, 35, .5)',
    fontSize: '12px',
    display: 'block',
    textAlign: 'center',
  },
  summaryFigureSuccess: {
    color: '#ED154E',
  },
  summaryFigurePrimary: {
    color: '#0D951B',
  },
}))
