import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(2.5)
  },

  button: {
    margin: theme.spacing(0.5),
    minWidth: 28,
    minHeight: 28,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    borderRadius: '0px',
    height: 28,
    width: 28,
    '& span': {
      lineHeight: 0,
      fontFamily: 'Roboto Condensed'
    }
  },

  activeButton: {
    margin: theme.spacing(0.5),
    minWidth: 28,
    minHeight: 28,
    height: 28,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '0px',
    '& span': {
      lineHeight: 0
    }
  },

  buttonDisabled: {
    borderColor: 'transparent',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&$activeButton': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    }
  },

  icon: {
    fontSize: 16,
    position: 'relative',
    top: '-2px'
  }
})

const propTypes = {
  classes: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  maxPagesShown: PropTypes.number,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
}

const defaultProps = {
  page: 0,
  count: 0,
  rowsPerPage: 15,
  maxPagesShown: 10
}

class PaginationActions extends React.Component {
  constructor(props) {
    super(props)

    this.handleFirstButtonClick = this.handleFirstButtonClick.bind(this)
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this)
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this)
    this.handleLastButtonClick = this.handleLastButtonClick.bind(this)
  }

  handleFirstButtonClick(event) {
    this.props.onChangePage(event, 0)
  }

  handleBackButtonClick(event) {
    this.props.onChangePage(event, this.props.page - 1)
  }

  handleNextButtonClick(event) {
    this.props.onChangePage(event, this.props.page + 1)
  }

  handleLastButtonClick(event) {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  handleSetPage(event, page) {
    this.props.onChangePage(event, page)
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props

    // calculate total pages
    const totalPages = Math.ceil(count / rowsPerPage)

    let startPage, endPage
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1
      endPage = totalPages
    } else {
      // more than 10 total pages so calculate start and end pages
      if (page <= 6) {
        startPage = 1
        endPage = 10
      } else if (page + 4 >= totalPages) {
        startPage = totalPages - 9
        endPage = totalPages
      } else {
        startPage = page - 5
        endPage = page + 4
      }
    }

    // calculate start and end item indexes
    const startIndex = (page - 1) * rowsPerPage
    const endIndex = Math.min(startIndex + rowsPerPage - 1, count - 1)

    // create an array of pages to ng-repeat in the pager control
    const pages = [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i)

    return (
      <div className={classes.root}>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled
          }}
          color="primary"
          variant="text"
          size="small"
          onClick={this.handleFirstButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === 'rtl' ? (
            <LastPageIcon className={classes.icon} />
          ) : (
            <FirstPageIcon className={classes.icon} />
          )}
        </Button>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled
          }}
          color="primary"
          variant="text"
          size="small"
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight className={classes.icon} />
          ) : (
            <KeyboardArrowLeft className={classes.icon} />
          )}
        </Button>
        <React.Fragment>
          {pages.map(aPage => (
            <Button
              key={aPage}
              classes={{
                root: aPage == (page+1) ? classes.activeButton : classes.button,
                disabled: classes.buttonDisabled
              }}
              variant={aPage == (page+1) ? 'contained' : 'text'}
              disabled={aPage == (page+1)}
              color="primary"
              size="small"
              aria-label={`Go to page ${aPage}`}
              onClick={event => { if (aPage !== (page+1)) this.handleSetPage(event, aPage - 1) }}
            >
              {aPage}
            </Button>
          ))}
        </React.Fragment>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled
          }}
          color="primary"
          variant="text"
          size="small"
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft className={classes.icon} />
          ) : (
            <KeyboardArrowRight className={classes.icon} />
          )}
        </Button>
        <Button
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled
          }}
          color="primary"
          variant="text"
          size="small"
          onClick={this.handleLastButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === 'rtl' ? (
            <FirstPageIcon className={classes.icon} />
          ) : (
            <LastPageIcon className={classes.icon} />
          )}
        </Button>
      </div>
    )
  }
}

PaginationActions.propTypes = propTypes
PaginationActions.defaultProps = defaultProps

const PaginationActionsWithStyle = withStyles(actionsStyles, { withTheme: true })(PaginationActions)

export default PaginationActionsWithStyle
