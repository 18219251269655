import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MuiMenu from '@material-ui/core/Menu'

import MenuItem from './MenuItem'

const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.object,
  ),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchor: PropTypes.any,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object,
}

const defaultProps = {
  items: [],
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
}


export default class Menu extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const {
      items,
      open,
      anchor,
      anchorOrigin,
      transformOrigin,
      onClose,
      ...restProps
    } = this.props
    return (
      <MuiMenu
        anchorEl={anchor}
        getContentAnchorEl={undefined}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={onClose}
        {...restProps}
      >
        {
          items.map(({ selected, ...restProps }, index) => {
            let itemSelected = selected
            if ('function' === typeof selected) {
              itemSelected = selected(restProps)
            }
            return (
              <MenuItem key={index} selected={itemSelected} {...restProps} />
            )
          })
        }
      </MuiMenu>
    )
  }

}

Menu.defaultProps = defaultProps
Menu.propTypes = propTypes
