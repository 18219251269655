import { makeStyles, Theme } from '@material-ui/core'

export const useMailingMatchedPatientsStyles = makeStyles((theme: Theme) => ({
  recentCallsWrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: '22px',
    '& h2': {
      fontFamily: 'Roboto Condensed',
      fontSize: '17px',
      color: '#282828',
      marginBottom: 14,
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      flexBasis: '100%',
      fontFamily: 'Roboto Condensed',
      fontSize: 14,
      marginBottom: 24,
      '& td': {
        padding: 9,
      },
      '& td:nth-child(2)': {
        width: 139,
      },
      '& td:last-child': {
        paddingRight: 39,
      },
      '& tr:nth-child(odd)': {
        backgroundColor: 'rgb(241,241,241)',
      },
    },
    '& a': {
      margin: '14px 0px',
      color: 'rgb(225,40,61)',
      fontFamily: 'Roboto Condensed',
      fontWeight: '400',
      fontSize: '14px',
      marginTop: '0',
    },
  },

  matchedPatientIconWrapper: {
    width: 49,
  },

  count: {
    position: 'relative',
    textAlign: 'center',
    width: '22px',
    height: '25px',
    margin: 'auto',
    '& span': {
      color: '#fff',
      fontFamily: 'Roboto Condensed',
      fontWeight: '600',
      fontSize: '10px',
      position: 'relative',
      top: '-30px',
    },
    '& img': {
      width: 22,
    },
  },

  media: {
    marginTop: -8,
  },

  callsLoader: {
    width: '100%',
    height: 215,
    background: '#f1f1f1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h1': {
      fontFamily: 'Roboto Condensed',
      fontSize: '18px',
      color: '#e9414d',
    },
  },

  patientProduction: {
    textAlign: 'right',
    marginRight: 18,
  },
}))
