import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  colStackedText: {
    lineHeight: '1.1rem'
  },
  colOpp: {
    height: '20px',
    width: '20px',
    display: 'block',
    margin: '0 auto'
  },
  colOppEnabled: {
    color: '#ED154E'
  },
  colOppDisabled: {
    color: '#D3D3D3'
  },
  colOutcomeIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
    verticalAlign: 'middle'
  },
  colOutcomeIconVoicemail: {
    color: '#0D951B'
  },
  colOutcomeIconNonVoicemail: {
    color: '#ED154E'
  },
  voicemailIcon: {
    width: 20,
    marginLeft: 8
  },
  highlightIcon: {
    verticalAlign: 'middle',
    color: '#8D8D8D',
    '&:hover': {
      color: '#ED154E'
    }
  },
  isFlex: {
    display: 'flex'
  },
  tooltipRoot: {
    padding: 15,
    backgroundColor: '#232323',
    '&:after': {
      borderTopColor: '#232323'
    }
  }
})
