import { defaultFont } from './index'

// Theming for navbars/menu items
export default theme => ({
  MUIDrawer: {
    root: {

    }
  },

  MuiListItem: {
    root: {
      ...defaultFont,
      fontSize: 14,
    },
    selected: {
      '&$root': {
        backgroundColor: 'transparent'
      }
    },
    button: {
      '&:hover': {
        backgroundColor: '#FADCE5',
        color: '#141414'
      }
    },
  },

  MuiMenuItem: {
    root: {
      ...defaultFont,
      fontSize: 14,
      fontWeight: '500',
      transition: 'none',
      color: '#282828',
      '&:hover': {
        color: '#141414',
        backgroundColor: '#FADCE5',
      }
    },
    selected: {
      backgroundColor: 'transparent',
    }
  },

  MuiListItemIcon: {
    root: {
      ...defaultFont,
      fontSize: 14,
      marginRight: 0
    }
  },

  MuiListItemText: {
    root: {
    },
    primary: {
      fontWeight: '500',
      ...defaultFont,
      fontSize: 14,
    }
  },
})
