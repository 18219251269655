import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'

import HeaderTop from './HeaderTop'
import HeaderBottom from './HeaderBottom'

const styles = theme => ({
  appBar: {
    backgroundColor: '#fff',
    flex: '0 0 auto',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.125)'
  }
})

const Header = props => {
  const { classes, title } = props
  return (
    <AppBar position="sticky" className={classes.appBar} elevation={0}>
      {
        props.topToolbar ? 
          <HeaderTop title={title}>{<props.topToolbar />}</HeaderTop> : 
          <HeaderTop title={title} />
      }
      {
        props.bottomToolbar ? 
          <HeaderBottom>{<props.bottomToolbar />}</HeaderBottom> : 
          null
      }
    </AppBar>
  )
}

export default withStyles(styles)(Header)
