const styles = theme => ({
  contentHeader: {
    margin: '0',
    fontSize: '20px',
    fontWeight: '600',
    borderBottom: 'solid 1px rgb(234,234,234)',
    paddingBottom: '12px',
    marginBottom: '24px',
    fontFamily: 'Roboto Condensed',
    color: '#141414'
  },
  inputRowContainer: {
    display: 'flex',
    marginBottom: '20px'
  },
  fieldContainer: {
    flexBasis: '82%',
    '& > div': {
      width: '100%'
    },
    '& input': {
      fontWeight: '400',
      fontFamily: 'Roboto Condensed'
    }
  },
  labelContainer: {
    flexBasis: '18%',
    display: 'flex',
    alignItems: 'center',
    '& label': {
      color: '#141414',
      fontFamily: 'Roboto Condensed'
    }
  },
  btnOutlined: {
    borderColor: '#ED154E',
    textTransform: 'capitalize',
    borderRadius: 0
  },
  noShadow: {
    boxShadow: 'none',
    borderRadius: 0
  },
  error: {
    color: theme.palette.primary.main,
    flexBasis: '57.1%',
    fontFamily: 'Roboto Condensed',
    fontSize: '13px',
    position: 'relative',
    left: '13px',
    lineHeight: '1.2',
    display: 'flex',
    alignItems: 'center'
  }
})

export default styles