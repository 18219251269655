import React, { Component, useContext } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { isValid } from 'date-fns'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import AddIcon from '@material-ui/icons/Add'
import AppointmentIcon from '@material-ui/icons/PlaylistAddCheck'
import ErrorIcon from '@material-ui/icons/Warning'
import Person from '@material-ui/icons/Person'

import AppState from 'src/config/store/AppState'
import { HighlightContext, HighlightedText } from 'components/Text'

import { defaultFont } from 'src/assets/jss/mvp'
import { repository as appointmentsRepo } from 'src/models/Appointment'
import AppointmentForm from './AppointmentForm'

const styles = theme => ({
  root: {
    ...defaultFont,
    transition: 'all 0.5s ease',
    transitionProperty: 'left, top, right, bottom',
    willChange: 'left, top, right, bottom, position'
  },
  header: {
    paddingLeft: 35,
    paddingRight: 35,
    minHeight: 40,
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    display: 'flex',
    alignItems: 'center'
  },
  headerActionsButton: {
    minHeight: 'initial',
    padding: '4px 6px'
  },
  headerTitle: {
    flex: 1,
    fontSize: '16px',
    color: '#232323',
    fontWeight: 500
  },
  tableRow: {
    height: 40,
    boxShadow: 'inset 0 1px rgba(0, 0, 0, 0.06)',
    '&:first-of-type': {
      boxShadow: 'none'
    },
    '&$tableRowLoading $tableCell': {
      color: '#CCC'
    }
  },
  tableRowLoading: {},
  tableCell: {
    height: 40,
    lineHeight: 'initial',
    paddingTop: 0,
    paddingBottom: 0,
    '&:first-of-type': {
      paddingLeft: 35
    }
  },
  icon: {
    fontSize: '16px',
    color: '#D3D3D3',
    '&:hover+div': {
      display: 'block'
    }
  },
  tooltipContainer: {
    position: 'relative'
  },
  tooltip: {
    display: 'none',
    position: 'absolute',
    top: '-35px',
    background: '#e9414d',
    fontFamily: 'Roboto Condensed',
    color: '#fff',
    padding: '5px 10px',
    fontSize: '13px',
    fontWeight: '300',
    right: '22px',
    '&:after': {
      content: '""',
      width: '0',
      height: '0',
      bottom: '-8px',
      right: '5px',
      position: 'absolute',
      borderStyle: 'solid',
      borderWidth: '1px 21px 13px 0',
      borderColor: 'transparent #e9414d transparent transparent'
    }
  }
})

const AppointmentNameCell = ({ appointment, classes }) => {
  const highlightPattern = useContext(HighlightContext)

  if (typeof highlightPattern === 'undefined') {
    return (
      <TableCell className={classes.tableCell} style={{ minWidth: 150 }}>
        {appointment.fullName}
      </TableCell>
    )
  }

  return (
    <TableCell className={classes.tableCell} style={{ minWidth: 150 }}>
      <HighlightedText>{appointment.fullName}</HighlightedText>
    </TableCell>
  )
}

@inject('callsStore')
@withStyles(styles)
@observer
class CallDetailsAppointments extends Component {
  state = {
    showForm: false
  }

  toggleForm = showForm => () => {
    this.setState({ showForm })
  }

  render() {
    const { classes, callsStore } = this.props
    const { showForm } = this.state
    const call = callsStore.drawer

    if (!call) {
      return <div />
    }

    return (
      <div className={cx(classes.root, showForm ? classes.expand : null)}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>Appointments set on this call</span>
          <span className={classes.headerActions}>
            <Button
              className={classes.headerActionsButton}
              disabled={showForm}
              color="primary"
              onClick={this.toggleForm(!showForm)}
            >
              <AddIcon color={showForm ? undefined : 'primary'} style={{ fontSize: '16px' }} />
              <span style={{ fontSize: '13px', textTransform: 'none' }}>Add Appointment</span>
            </Button>
          </span>
        </div>

        {showForm ? (
          <AppointmentForm onClose={this.toggleForm(false)} />
        ) : (
          <Table classes={{ root: classes.table }}>
            <TableBody>
              {call.appointments.length ? (
                call.appointments.slice().map((id, key) => {
                  const appointment = appointmentsRepo.get(id)
                  const tz = 'America/Denver'
                  const dateTime = utcToZonedTime(new Date(appointment.dateTime), tz)
                  const apptDateTime = formatDate(dateTime, 'M/d/yy @h:mm a', {
                    timeZone: tz
                  })
                  return (
                    <TableRow
                      key={key}
                      className={cx(classes.tableRow, appointment.isLoading ? classes.tableRowLoading : null)}
                      hover
                    >
                      <TableCell className={classes.tableCell}>{key + 1}</TableCell>
                      <AppointmentNameCell appointment={appointment} classes={classes} />
                      <TableCell className={classes.tableCell}>
                        {isValid(new Date(appointment.dateTime)) ? apptDateTime : '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {appointment.isLoading ? (
                          <CircularProgress size={16} />
                        ) : appointment.isError ? (
                          <ErrorIcon className={classes.icon} color="error" />
                        ) : appointment.contactId && appointment.contactId !== AppState.contactId ? (
                          <div className={classes.tooltipContainer}>
                            <Person style={{ color: 'rgb(13, 149, 27)', cursor: 'pointer' }} className={classes.icon} />
                            <div className={classes.tooltip}>
                              <strong>{appointment.contactName}</strong> added this appointment{' '}
                              {isValid(new Date(appointment.dateTime)) ? formatDate(dateTime, 'M/d/yy') : '-'}
                            </div>
                          </div>
                        ) : (
                          <AppointmentIcon style={{ color: 'rgb(13, 149, 27)' }} className={classes.icon} />
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell className={classes.tableCell} colSpan={4}>
                    <div style={{ textAlign: 'center', color: '#D2D2D2' }}>No appointments</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>
    )
  }
}

export default CallDetailsAppointments
