import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import medal from 'src/assets/img/medal.svg'

import { PermPhoneMsg as UnsuccessfulIcon } from '@material-ui/icons'

import { useStores } from 'config/store'
import Mailing from 'models/Mailing'
import MailingMatchedPatients from 'components/Mailings/MailingMatchedPatients'
import AuditedMailingResult from 'components/Mailings/AuditedMailingResult'
import { useMailingMatchbackResultStyles } from './MailingMatchbackResult.style'
import mailingsStore from 'config/store/MailingsStore'

const MailingMatchbackResult: React.FC<{
  mailing: Mailing
}> = ({ mailing }) => {
  const classes = useMailingMatchbackResultStyles()

  return (
    <div className={classes.root}>
      <div className={classes.matchedAuditedResult}>
        <AuditedMailingResult mailing={mailing} />
      </div>
      <MailingMatchedPatients mailing={mailing} />
    </div>
  )
}

export default observer(MailingMatchbackResult)
