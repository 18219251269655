import React from 'react'
import cx from 'classnames'

import Button from '@material-ui/core/Button'
import MailingStatusIcon from 'components/Mailings/MailingStatusIcon'

import Mailing from 'models/Mailing'
import { useMailingCardActionsStyles } from './MailingCardActions.style'
import { observer } from 'mobx-react'

const MailingCardActions: React.FC<{
  mailing: Mailing
  onAction: (action: string, mailing: Mailing) => void
}> = ({ mailing, onAction }) => {
  const classes = useMailingCardActionsStyles()
  const handleAction = (action: string, mailing: Mailing) => {
    onAction(action, mailing)
  }

  return (
    <div
      className={cx({
        [classes.root]: true,
        [classes.ordered]: mailing.status === 'ordered',
      })}
    >
      <div className={classes.pill}>
        <MailingStatusIcon
          className={classes.pillIcon}
          status={mailing.status}
        />
        <span className={classes.pillText}>
          {mailing.status &&
            (mailing.status === 'deliveredInFirstCall'
              ? 'delivered'
              : mailing.status.toLowerCase())}
        </span>
      </div>
      <Button
        className={classes.button}
        color="primary"
        onClick={() => handleAction('view', mailing)}
      >
        {mailing.status === 'ordered' ? 'Review and Approve' : 'View Details'}
      </Button>
    </div>
  )
}

export default observer(MailingCardActions)
