import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import withStyles from '@material-ui/core/styles/withStyles'
import Img from 'react-image'
import CircularProgress from '@material-ui/core/CircularProgress'

import {
	defaultFont
} from 'src/assets/jss/mvp'
import Tooltip from '../MVP/MVPTooltip'

import MailingStatusIcon from './MailingStatusIcon'

const styles = theme => ({
  root: {
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 15
	},
	smallHeading: {
		display: 'block',
		color: 'rgb(124,124,124)',
		fontFamily: 'Roboto Condensed',
		fontSize: 14
	},
	table: {
		width: '100%',
		fontFamily: 'Roboto Condensed',
		color: 'rgb(58,58,58)',
		borderCollapse: 'collapse',
    borderSpacing: '0',
		fontSize: 14,
		marginTop: 6,
		marginBottom: 24,
		'& thead tr': {
			backgroundColor: 'transparent !important'
		},
		'& thead tr th': {
			color: 'rgb(124,124,124) !important',
			fontFamily: 'Roboto Condensed',
			fontSize: 14,
			paddingBottom: 8,
			fontWeight: 'normal'
		},
		'& thead td:nth-child(2)': {
			textAlign: 'center'
		},
		'& td': {
			padding: '4px 8px',
			textAlign: 'center'
		},
		'& td:last-child': {
			textAlign: 'right'
		},
		'& td:nth-child(2)': {
			textAlign: 'left'
		},
		'& td:first-child': {
			color: 'rgb(188,188,188)',
			paddingLeft: 12,
			textAlign: 'left',
			width: 40
		},
		'& tr:nth-child(odd)': {
			backgroundColor: 'rgb(244,244,244)'
		},
		'& tr:nth-child(even)': {
			backgroundColor: 'rgb(250,250,250)'
		}
	},
	map: {
		marginTop: 8,
		backgroundColor: 'rgba(211, 211, 211, 0.4)',
		width: '100%',
		height: 262,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& img': {
			width: '100%',
			marginTop: '62px',
			marginBottom: '18px',
		}
	},
	errorLoad: {
		fontFamily: 'Roboto Condensed',
		color: '#b9b9b9'
	}
})

@inject('mailingsStore')
@withStyles(styles)
@observer
class MailingMap extends Component {
	state = {
		errorLoad: false
	}

	handleImageLoad = () => {
		this.setState({errorLoad: true})
	}

	render() {
		const { mailingsStore, classes } = this.props
		const mailing = mailingsStore.drawer

		return (
			<div className={classes.root}>
				<label className={classes.smallHeading}>{mailing.campaignType} Map 3 - Zones</label>
				<div className={classes.map}>
					{ this.state.errorLoad ?
						<div className={classes.errorLoad}>
							<p>Map currently unavailable</p>
						</div> : <Img onError={this.handleImageLoad} src={mailing.mapThumbnailUrl} loader={<CircularProgress className={classes.loader} size={50}/>} />
					}
				</div>
				{/*<table className={classes.table}>
					<thead>
						<tr>
							<th colSpan='2'>Target Delivery Week</th>
							<th>Target Zone</th>
							<th>Quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>1</td>
							<td>4/23/18</td>
							<td>Zone 1</td>
							<td>15,000 pcs</td>
						</tr>
						<tr>
							<td>2</td>
							<td>5/23/18</td>
							<td>Zone 2</td>
							<td>15,000 pcs</td>
						</tr>
						<tr>
							<td>3</td>
							<td>4/20/18</td>
							<td>Zone 3</td>
							<td>15,000 pcs</td>
						</tr>
					</tbody>
				</table>*/}
			</div>
		)
	}
}

export default withStyles(styles)(MailingMap)
