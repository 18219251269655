import { observable, computed, action } from 'mobx'

import Repository from './Repository'

export const repository = new Repository<Appointment>()

export default class Appointment {
  @observable isLoading = false
  @observable isError = false

  @observable id: string
  @observable code?: string
  @observable dateTime?: string
  @observable lastName?: string
  @observable firstName?: string
  @observable contactId?: string
  @observable contactName?: string
  @observable contactEmail?: string
  @observable outcome?: string
  @observable previousAppointmentId?: string

  constructor(appointment: any) {
    this.id = appointment.id
  }

  @computed
  get fullName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`
    }

    if (this.firstName) {
      return this.firstName
    }

    if (this.lastName) {
      return this.lastName
    }

    return '-'
  }

  @action.bound
  loading(isLoading = true) {
    this.isLoading = isLoading
  }
}
