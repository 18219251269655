import { makeStyles, Theme } from '@material-ui/core'

export const useMailingAuditNoticeStyle = makeStyles((theme: Theme) => ({
  tooltip: {
    background: 'rgba(40, 40, 40, 1)',
    '&:after': {
      borderWidth: '0px',
      marginLeft: '0px',
    },
  },
  arrow: {
    color: 'rgba(40, 40, 40, 1)',
  },
  contentWrapper: {
    padding: 2,
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: 4,
  },
}))
