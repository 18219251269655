import { observable, computed, action } from 'mobx'
import _ from 'lodash'

import getSettings from 'src/services/users/getSettings'
import updatePassword from 'src/services/users/updatePassword'
import updateSettings from 'src/services/users/updateSettings'

export class SettingsStore {
  @observable settings = {}
  @observable email = ''
  @observable phone = ''
  @observable receiveSmsEmail = false
  @observable receiveSmsMobile = false
  @observable missedCallsEmail = false
  @observable missedCallsMobile = false
  @observable missedCallAlertRecipients = []
  @observable activated = undefined
  @observable isLoading = true

  @observable newEmail = ''
  @observable newPhone = ''
  @observable newReceiveSmsEmail = false
  @observable newReceiveSmsMobile = false
  @observable newMissedCallsEmail = false
  @observable newMissedCallsMobile = false
  @observable newMissedCallAlertRecipients = []
  @observable isSaving = false

  constructor() {
    this.getUserSettings()
  }

  @computed get smsAlerts() {
    const arr = []
    this.newReceiveSmsEmail && arr.push('email')
    this.newReceiveSmsMobile && arr.push('push')
    return [arr.join(';')]
  }

  @computed get callAlerts() {
    const arr = []
    this.newMissedCallsEmail && arr.push('email')
    this.newMissedCallsMobile && arr.push('push')
    return [arr.join(';')]
  }

  @computed get isFormUpdated() {
    if ((this.email && !this.newEmail) || (this.phone && !this.newPhone)) {
      return false
    }
    return (
      this.email !== this.newEmail ||
      this.phone !== this.newPhone ||
      this.missedCallsEmail !== this.newMissedCallsEmail ||
      this.missedCallsMobile !== this.newMissedCallsMobile ||
      this.receiveSmsEmail !== this.newReceiveSmsEmail ||
      this.receiveSmsMobile !== this.newReceiveSmsMobile ||
      _.xor(this.missedCallAlertRecipients, this.newMissedCallAlertRecipients)
        .length > 0
    )
  }

  @action.bound
  updateSettings(userSettings) {
    this.settings = userSettings
  }

  @action.bound
  async updateUserSettings(userSettings) {
    await updateSettings(userSettings)
  }

  @action.bound
  async getUserSettings() {
    try {
      if (!this.email.length) {
        const data = await getSettings()
        this.email = this.newEmail = data.email || ''
        this.phone = this.newPhone = data.phone || ''
        this.activated = data.activated || ''

        this.missedCallsEmail = this.newMissedCallsEmail = _.get(
          data.settings,
          '0.missedCallAlerts',
          []
        ).includes('email')
        this.missedCallsMobile = this.newMissedCallsMobile = _.get(
          data.settings,
          '0.missedCallAlerts',
          []
        ).includes('push')
        this.receiveSmsEmail = this.newReceiveSmsEmail = _.get(
          data.settings,
          '0.receivedSmsAlerts',
          []
        ).includes('email')
        this.receiveSmsMobile = this.newReceiveSmsMobile = _.get(
          data.settings,
          '0.receivedSmsAlerts',
          []
        ).includes('push')

        this.missedCallAlertRecipients = this.newMissedCallAlertRecipients = _.get(
          data.settings,
          '0.missedCallAlertRecipients.0'
        )
          ? _.get(data.settings, '0.missedCallAlertRecipients.0').split(',')
          : []
      }
    } catch (error) {
      console.log(error)
      throw new Error('Failed to retrieve settings')
    }
  }

  @action.bound
  async updateUserPassword(userPasswordData) {
    try {
      await updatePassword(userPasswordData)
    } catch (error) {
      let errorMessage
      try {
        errorMessage = JSON.parse(error.message).error_description
      } catch (e) {
        errorMessage = error.message
      } finally {
        throw new Error(errorMessage)
      }
    }
  }

  @action
  cleanStore() {
    this.settings = {}
    this.email = this.newEmail = ''
    this.phone = this.newPhone = ''
    this.receiveSmsEmail = this.newReceiveSmsEmail = false
    this.receiveSmsMobile = this.newReceiveSmsMobile = false
    this.missedCallsEmail = this.newMissedCallsEmail = false
    this.missedCallsMobile = this.newMissedCallsMobile = false
    this.missedCallAlertRecipients = this.newMissedCallAlertRecipients = []
    this.isLoading = true
    this.isSaving = false
  }
}

const settingsStore = new SettingsStore()
export default settingsStore
