import React from 'react'
import Button from '@material-ui/core/Button'
import Add from '@material-ui/icons/Add'
import { withStyles, Theme } from '@material-ui/core'

const styles = (theme: Theme) => ({
  colCtaBtn: {
    paddingLeft: 5,
    paddingRight: 10,
    '& > span': {
      color: theme.palette.primary.main,
      textTransform: 'initial'
    }
  }
})

const AddAppointmentButton = ({ classes }: any) => (
  <Button className={classes.colCtaBtn}>
    <Add color="primary" />
    <span>Appointment</span>
  </Button>
)

export default withStyles(styles as any)(AddAppointmentButton)
