import React, { Component } from 'react'
import { observer } from 'mobx-react'
import _isEmpty from 'lodash/isEmpty'
import { withStyles } from '@material-ui/core/styles'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import { ExpandMore, Group } from '@material-ui/icons'

import PatientListItem from './PatientListItem'
import mapsStore from 'src/config/store/MapsStore'
import { repository as patientListRepo } from 'src/models/PatientList'

const styles = (theme) => ({
  panel: {
    borderTop: '1px solid rgba(0,0,0,0.1)',
    margin: 0,
    '&::before': {
      display: 'none',
    },
  },

  panelSummary: {
    minHeight: 64,
    height: 64,
    '&$panelSummaryExpanded': {
      margin: 0,
      boxShadow: `inset 4px 0 ${theme.palette.primary.main}`,
    },
  },

  panelSummaryContent: {},

  panelSummaryExpanded: {},
  panelSummaryExpandIcon: {
    position: 'absolute',
    right: 8,
    marginRight: 0,
  },

  panelCollapse: {
    color: 'rgb(229,8,62)',
  },

  panelExpanded: {
    margin: '0px !important',
  },

  panelHeading: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontFamily: 'Roboto Condensed',
    fontSize: '17px',
    '& svg': {
      width: 20,
      height: 20,
      marginRight: '16px',
      marginLeft: '-6px',
    },
  },

  panelDetails: {
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.125), #F9F9F9 1.5%)',
  },

  addPatient: {
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-8px',
    fontSize: 15,
    textTransform: 'none',
    background: '#fff',
    '& svg': {
      marginRight: 10,
    },
  },

  addPanelWrapper: {
    backgroundColor: '#fff',
    borderTop: 'solid 1px #eee',
  },
  emptyPatientList: {
    padding: '12px 20px',
    textAlign: 'center',
  },
})

@withStyles(styles)
@observer
export default class PatientListPanel extends Component {
  render() {
    const { classes } = this.props
    const { patientLists } = mapsStore

    return (
      <ExpansionPanel
        elevation={1}
        square={true}
        defaultExpanded={true}
        classes={{
          root: classes.panel,
          expanded: classes.panelExpanded,
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMore className={classes.panelCollapse} />}
          classes={{
            root: classes.panelSummary,
            content: classes.panelSummaryContent,
            expanded: classes.panelSummaryExpanded,
            expandIcon: classes.panelSummaryExpandIcon,
          }}
        >
          <Typography className={classes.panelHeading}>
            <Group /> Patient Lists
          </Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.panelDetails}>
          {!_isEmpty(patientLists) ? (
            patientLists.map((id, index) => {
              const patientList = patientListRepo.get(id)

              return (
                !patientList.archived && (
                  <PatientListItem id={id} key={`patient-item--${index}`} />
                )
              )
            })
          ) : (
            <div className={classes.emptyPatientList}>
              No uploaded patient lists
            </div>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}
