import { makeStyles, Theme } from '@material-ui/core'
import { defaultFont } from 'assets/jss/mvp'

export const useDeliveredMailingCardInfoStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',

    flexDirection: 'row',
  },

  leadsContainer: {
    marginRight: 12,
  },

  detailsPanel: {
    flex: '1 1 auto',

    display: 'flex',

    flexDirection: 'row',

    alignItems: 'center',
  },

  detail: {
    flex: '1 1 auto',

    display: 'flex',

    flexDirection: 'column',

    justifyContent: 'center',

    alignItems: 'center',
  },

  detailSeparator: {
    borderRight: 'solid 1px rgb(241, 241, 241)',
  },

  label: {
    ...defaultFont,

    flex: '0 0 auto',

    fontSize: '10px',

    fontWeight: 500,

    color: '#232323',

    height: 16,
  },

  icon: {
    color: '#D3D3D3',

    width: 8,

    height: 8,

    marginRight: 4,
  },

  stats: {
    flex: '1 1 auto',

    ...defaultFont,

    alignItems: 'center',

    fontSize: '24px',

    fontWeight: 700,

    color: '#929292',

    textAlign: 'center',
  },

  ratePanel: {
    display: 'flex',

    flex: '0 0 auto',

    flexDirection: 'row',

    justifyContent: 'flex-end',
  },
}))
