import cx from 'classnames'
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CheckIcon from '@material-ui/icons/Check'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Fragment, Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import CountUp from 'react-countup'

import CallSearch from './CallSearch'
import { useStores } from '../../config/store'

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 68,
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  popperClose: {
    pointerEvents: 'none',
  },
  popperManagerRoot: {
    zIndex: theme.zIndex.appBar - 1,
  },
  popperTarget: {},
  dropdown: {
    fontSize: '18px',
    textTransform: 'none',
    color: '#ED154E',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 160,
    marginRight: 50,
  },
  dropdownMenuItem: {
    fontSize: '14px',
    minWidth: 160,
    height: '20px',
    '&$selected:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      cursor: 'default',
    },
  },
  selected: {
    color: theme.palette.primary.main,
    '& > $menuIcon': {
      color: theme.palette.primary.main,
      visibility: 'unset',
    },
  },

  menuText: {
    marginRight: '1em',
  },

  menuIcon: {
    visibility: 'hidden',
    minWidth: '1em',
    '& > svg': {
      width: 16,
      height: 16,
    },
  },

  summary: {
    minWidth: 50,
    marginRight: 15,
  },
  summaryFigure: {
    fontSize: '20px',
    fontWeight: 700,
    display: 'block',
    textAlign: 'center',
    color: '#232323',
    lineHeight: '1em',
    margin: 4,
  },
  summaryLabel: {
    color: 'rgba(35, 35, 35, .5)',
    fontSize: '12px',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Roboto Condensed',
    lineHeight: '1em',
  },
  summaryFigureSuccess: {
    color: '#F50D0D',
  },
  summaryFigurePrimary: {
    color: '#0D951B',
  },
  tooltipContainer: {
    marginTop: '3px',
  },
  tooltip: {
    background: 'rgba(40, 40, 40, 1)',
    '&:after': {
      borderWidth: '0px',
      marginLeft: '0px',
    },
  },
  arrow: {
    color: 'rgba(40, 40, 40, 1)',
  },
  contentWrapper: {
    padding: 2,
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    marginTop: 4,
  },
})

const quickFilters = {
  'Recent Calls': null,
  'Last 7 Days': moment().subtract(7, 'day'),
  'Last 14 Days': moment().subtract(14, 'day'),
  'Last 30 Days': moment().subtract(1, 'month'),
  'Last 3 Months': moment().subtract(3, 'month'),
  'Last 6 Months': moment().subtract(6, 'month'),
  'Current Year': moment().set({ M: 0, D: 1 }),
}

const SummaryLabel = withTheme(
  withStyles(styles)(
    ({ classes, label, count, previousCount, primary, success }) => (
      <div className={classes.summary} style={{ margin: 10 }}>
        <CountUp
          className={cx(classes.summaryFigure, {
            [classes.summaryFigurePrimary]: primary,
            [classes.summaryFigureSuccess]: success,
          })}
          duration={1}
          start={previousCount}
          end={count}
        />
        <div className={classes.summaryLabel}>{label}</div>
      </div>
    )
  )
)

const AppointmentSummaryTooltip = ({ totalAppt, cancelledAppt }) => (
  <div>
    <p>All Appointments: {totalAppt}</p>
    <p>Active: {totalAppt - cancelledAppt}</p>
    <p>Cancelled: {cancelledAppt}</p>
  </div>
)

@inject('callsStore', 'configStore')
@withTheme
@withStyles(styles)
@observer
class CallsSummary extends Component {
  state = {
    open: false,
  }

  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
  }

  handleToggle = () => {
    this.setState({ open: !this.state.open })
  }

  handleClose = (event) => {
    if (
      this.buttonRef.current &&
      this.buttonRef.current.contains(event.target)
    ) {
      return
    }
    this.setState({ open: false })
  }

  handleSelectQuickFilter = (date) => (event) => {
    if (
      this.buttonRef.current &&
      this.buttonRef.current.contains(event.target)
    ) {
      return
    }

    const { callsStore } = this.props

    if (date) {
      callsStore.setFilter('callDateTime', {
        lt: moment().format(),
        gt: date.format(),
      })
    } else {
      callsStore.setFilter('callDateTime', undefined)
    }
    this.setState({ open: false })
  }

  selectedQuickFilter() {
    const {
      configStore: { dateFormat },
      callsStore: { filters },
    } = this.props

    const { lt, gt } = filters.get('callDateTime') || {}
    const from = moment(gt)
    const to = moment(lt)

    // No call date/time filters set
    if (!lt && !gt) {
      return 'Recent Calls'
    }

    // Check if only `to` filter is set
    if (!gt) {
      return `Up to ${to.format(dateFormat)}`
    }

    // Check if only `from` filter is set
    if (!lt) {
      return `From ${from.format(dateFormat)}`
    }

    // Check if any of the quick filters values match our `from` filter
    let match = _reduce(
      quickFilters,
      (prev, current, index) => {
        if (current && current.isSame(from, 'day')) {
          return index
        }
        return prev
      },
      null
    )

    if (match) {
      return match
    }

    // Default fall through
    return `${from.format(dateFormat)} - ${to.format(dateFormat)}`
  }

  render() {
    const {
      callsStore: { previousSummary, summary },
      classes,
    } = this.props
    const { open } = this.state

    return (
      <Grid container className={classes.root}>
        <Button
          ref={this.buttonRef}
          className={classes.dropdown}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          onClick={this.handleToggle}
        >
          <span style={{ marginRight: 10 }}>{this.selectedQuickFilter()}</span>
          <KeyboardArrowDownIcon color="primary" />
        </Button>
        <Popper
          open={open}
          placement="bottom-start"
          anchorEl={this.buttonRef.current}
          keepMounted
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              {/**
               * Need to add position: absolute in order to keep menu from
               * taking up space (https://github.com/mui-org/material-ui/pull/16203)
               */}
              <Paper id="menu-list-grow" style={{ position: 'absolute' }}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList role="menu">
                    {_map(quickFilters, (filter, key) => {
                      return (
                        <MenuItem
                          key={key}
                          className={`${classes.dropdownMenuItem} ${
                            this.selectedQuickFilter() === key
                              ? classes.selected
                              : null
                          }`}
                          onClick={this.handleSelectQuickFilter(filter)}
                        >
                          <ListItemText
                            className={classes.menuText}
                            primary={key}
                          />
                          <ListItemIcon className={classes.menuIcon}>
                            <CheckIcon />
                          </ListItemIcon>
                        </MenuItem>
                      )
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <SummaryLabel
          label="All Calls"
          count={summary.countAll}
          previousCount={previousSummary.countAll}
        />

        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={
            <AppointmentSummaryTooltip
              totalAppt={summary.countAppointments}
              cancelledAppt={summary.countCancelledAppointments}
            />
          }
          placement={'bottom'}
        >
          <div className={classes.tooltipContainer}>
            <SummaryLabel
              label="Appointments"
              primary
              count={summary.countAppointments}
              previousCount={previousSummary.countAppointments}
            />
          </div>
        </Tooltip>

        <SummaryLabel
          success
          label="Missed"
          count={summary.countMissedCalls}
          previousCount={previousSummary.countMissedCalls}
        />
        <CallSearch />
      </Grid>
    )
  }
}

export default CallsSummary
