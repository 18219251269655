import React, { useState, useRef, MouseEvent } from 'react'
import cx from 'classnames'
import _isArray from 'lodash/isArray'
import _reduce from 'lodash/reduce'
import _map from 'lodash/map'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Button from '@material-ui/core/Button'
import Grow, { GrowProps } from '@material-ui/core/Grow'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import CheckIcon from '@material-ui/icons/Check'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import moment, { Moment } from 'moment'
import { observer } from 'mobx-react'
import CountUp from 'react-countup'

import { useStores } from 'config/store'

import { useMailingsSummaryStyles } from './MailingsSummary.style'

interface StyleClassesInterface {
  summary: string
  summaryFigure: string
  summaryFigurePrimary: string
  summaryFigureSuccess: string
  summaryLabel: string
}

interface SummaryLabelInterface {
  classes: StyleClassesInterface
  label: string
  count: number
  previousCount: number
  primary?: boolean
  success?: boolean
  suffix?: string
}

const quickFilters = {
  'Recent Mailings': null,
  'Last 3 Months': moment().subtract(3, 'month'),
  'Last 6 Months': moment().subtract(6, 'month'),
  'Next 3 Months': moment().add(3, 'month'),
  'Next 6 Months': moment().add(6, 'month'),
  'Current Year': [
    moment().set({ M: 0, D: 1 }),
    moment().set({ M: 11, D: 31 }),
  ],
}

const SummaryLabel = ({
  classes,
  label,
  count,
  previousCount,
  primary,
  success,
  suffix,
}: SummaryLabelInterface) => (
  <div className={classes.summary} style={{ margin: 10 }}>
    <CountUp
      className={cx(classes.summaryFigure, {
        [classes.summaryFigurePrimary]: primary,
        [classes.summaryFigureSuccess]: success,
      })}
      duration={1}
      start={previousCount}
      end={count}
      suffix={suffix}
    />
    <div className={classes.summaryLabel}>{label}</div>
  </div>
)

const MailingsSummary: React.FC = () => {
  const { mailingsStore, configStore } = useStores()
  const classes = useMailingsSummaryStyles()
  const [open, setOpen] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { previousSummary, summary } = mailingsStore

  const handleToggle = (): void => {
    setOpen(!open)
  }

  const handleClose = (event: MouseEvent<Document>): void => {
    if (buttonRef?.current && event?.target) {
      buttonRef.current.contains(event.target as Node)
      return
    }

    setOpen(false)
  }

  const handleSelectQuickFilter = (date: Moment | Moment[] | null) => {
    if (date) {
      let start, end

      if (_isArray(date)) {
        start = date[0].format('YYYY-MM-DD')
        end = date[1].format('YYYY-MM-DD')
      } else {
        start = moment().format('YYYY-MM-DD')
        end = date.format('YYYY-MM-DD')
        if (start > end) {
          ;[start, end] = [end, start]
        }
      }

      mailingsStore.setFilter('deliveryWeek', {
        gte: start,
        lte: end,
      })
    } else {
      mailingsStore.setFilter('deliveryWeek', undefined)
    }

    setOpen(false)
  }

  const selectedQuickFilter = (): string => {
    const { dateFormat } = configStore
    const { filters } = mailingsStore

    const { lte, gte } = filters.get('deliveryWeek') || {}

    // No mailing date/time filters set
    if (!lte && !gte) {
      return 'Recent Mailings'
    }

    const from = moment(gte)
    const to = moment(lte)

    // Check if only `to` filter is set
    if (!gte) {
      return `Up to ${to.format(dateFormat)}`
    }

    // Check if only `from` filter is set
    if (!lte) {
      return `From ${from.format(dateFormat)}`
    }

    const match = _reduce(
      quickFilters,
      (prev, current, index) => {
        let start: Moment | Moment[] | null
        let end: Moment | Moment[] | null
        if (_isArray(current)) {
          start = current[0]
          end = current[1]
        } else {
          start = moment()
          end = current
          if (end !== null && start.isAfter(end, 'day')) {
            const startEnd = [end, start]
            start = startEnd[0]
            end = startEnd[1]
          }
        }

        if (current && start.isSame(from, 'day') && end?.isSame(to, 'day')) {
          return index
        }
        return prev
      },
      'Recent Mailings'
    )

    if (match) {
      return match
    }

    // Default fall through
    return `${from.format(dateFormat)} - ${to.format(dateFormat)}`
  }

  return (
    <Grid container className={classes.root}>
      <Button
        ref={buttonRef}
        className={classes.dropdown}
        aria-controls="menu-list-grow"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <span style={{ marginRight: 10 }}>{selectedQuickFilter()}</span>
        <KeyboardArrowDownIcon color="primary" />
      </Button>
      <Popper
        open={open}
        placement="bottom-start"
        anchorEl={buttonRef.current}
        keepMounted
        transition
        disablePortal
      >
        {({ TransitionProps, placement }: any) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            {/**
             * Need to add position: absolute in order to keep menu from
             * taking up space (https://github.com/mui-org/material-ui/pull/16203)
             */}
            <Paper id="menu-list-grow" style={{ position: 'absolute' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  {_map(quickFilters, (filter, key) => {
                    return (
                      <MenuItem
                        key={key}
                        className={`${classes.dropdownMenuItem} ${
                          selectedQuickFilter() === key
                            ? classes.selected
                            : null
                        }`}
                        onClick={() => {
                          handleSelectQuickFilter(filter)
                        }}
                      >
                        <ListItemText
                          className={classes.menuText}
                          primary={key}
                        />
                        <ListItemIcon className={classes.menuIcon}>
                          <CheckIcon />
                        </ListItemIcon>
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <SummaryLabel
        label="Leads"
        count={summary.leads}
        previousCount={previousSummary.leads}
        classes={classes}
      />
      <SummaryLabel
        label="Appointments"
        primary
        count={summary.appointments}
        previousCount={previousSummary.appointments}
        classes={classes}
      />
      <SummaryLabel
        success
        label="Conversion Rate"
        count={summary.conversionRate}
        previousCount={previousSummary.conversionRate}
        suffix="%"
        classes={classes}
      />
    </Grid>
  )
}

export default observer(MailingsSummary)
