import React, { Component } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import tableStyle from 'src/assets/jss/material-dashboard-react/tableStyle'

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderBy: PropTypes.string,
  dir: PropTypes.string,
  onColumnSort: PropTypes.func,
}

const defaultProps = {}

@withStyles(tableStyle)
class Head extends Component {
  createSortHandler = column => event => {
    this.props.onColumnSort && this.props.onColumnSort(column)
  }

  renderSortableColumn(column) {
    const { classes, orderBy, dir } = this.props
    return (
      (
        <TableCell
          key={column.id}
          numeric={column.numeric}
          padding={column.disablePadding ? 'none' : 'default'}
          sortDirection={orderBy === column.id ? (dir || false) : false}
        >
          <TableSortLabel
            active={!!(orderBy === column.id && dir)}
            direction={dir || undefined}
            onClick={this.createSortHandler(column.id)}
          >
            {column.label}
          </TableSortLabel>
        </TableCell>
      )
    )
  }

  renderUnsortableColumn(column) {
    return (
      (
        <TableCell
          key={column.id}
          numeric={column.numeric}
          padding={column.disablePadding ? 'none' : 'default'}
        >
          {column.label}
        </TableCell>
      )
    )
  }

  render() {

    const { columns, orderBy, dir } = this.props

    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            return column.sortable ?
              this.renderSortableColumn(column) :
              this.renderUnsortableColumn(column)
          }, this)}
        </TableRow>
      </TableHead>
    )
  }
}

Head.propTypes = propTypes
Head.defaultProps = defaultProps

export default Head
