import React, { useContext } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Smartphone as CallerNumberIcon,
  DateRange as CallDateTimeIcon,
  RecordVoiceOver as CallHandlerIcon
} from '@material-ui/icons'

import { defaultFont } from 'src/assets/jss/mvp'
import { HighlightContext, HighlightedText, HighlightedPhoneNumber } from 'src/components/Text'

const styles = theme => ({
  root: {
    ...defaultFont,
    padding: '20px 35px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main
  },
  group: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  callerName: {
    ...defaultFont,
    flex: '1 0 auto',
    fontSize: '1.3125rem',
    fontWeight: 600,
    marginRight: 5,
    marginBottom: 5
  },
  detail: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: '16px',
    marginRight: 5,
    flex: '0 0 auto'
  },
  text: {
    ...defaultFont,
    fontSize: '14px',
    fontWeight: 500,
    flex: '1 0 auto'
  },
  highlighted: {
    color: '#232323'
  }
}) 

const CallDetailsHeader = ({ classes, callsStore, ...props }) => {
  const call = callsStore.drawer
  if (!call) {
    return <div />
  }

  const dateTime = moment(call.callDateTime)
  const highlightPattern = useContext(HighlightContext)
  const callerName = call.callerName ?? call.callerIdNameFmt

  if (typeof highlightPattern === 'undefined') {
    return (
      <div className={classes.root}>
        <div className={classes.group}>
          <span className={classes.callerName}>{callerName || '-'}</span>
          <span className={classes.detail}>
            <CallerNumberIcon className={classes.icon} />
            <span className={classes.text}>{call.callerPhoneNumber}</span>
          </span>
        </div>
        <div className={classes.group}>
          <span className={classes.detail}>
            <CallDateTimeIcon className={classes.icon} />
            <span className={classes.text}>{dateTime.isValid() ? dateTime.format('M/D/YY @h:mm:a') : ''}</span>
          </span>
          <span className={classes.detail}>
            <CallHandlerIcon className={classes.icon} />
            <span className={classes.text}>{call.answeredBy}</span>
          </span>
        </div>
      </div>
    )
  }

  const callerNameMatched = call.getCallerNameMatched(callsStore.searchQueryPattern)

  return (
    <div className={classes.root}>
      <div className={classes.group}>
        <span className={classes.callerName}>
          {callerNameMatched ? (
            <HighlightedText highlightedClassName={classes.highlighted}>{callerNameMatched}</HighlightedText>
          ) : (
            '-'
          )}
        </span>
        <span className={classes.detail}>
          <CallerNumberIcon className={classes.icon} />
          <span className={classes.text}>
            <HighlightedPhoneNumber highlightedClassName={classes.highlighted}>
              {call.callerPhoneNumberRaw}
            </HighlightedPhoneNumber>
          </span>
        </span>
      </div>
      <div className={classes.group}>
        <span className={classes.detail}>
          <CallDateTimeIcon className={classes.icon} />
          <span className={classes.text}>{dateTime.isValid() ? dateTime.format('M/D/YY @h:mm:a') : ''}</span>
        </span>
        <span className={classes.detail}>
          <CallHandlerIcon className={classes.icon} />
          <span className={classes.text}>{call.answeredBy}</span>
        </span>
      </div>
    </div>
  )
}

export default inject('callsStore')(withStyles(styles)(observer(CallDetailsHeader)))
