import React, { Component } from 'react'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import ResetIcon from '@material-ui/icons/SettingsBackupRestore'

import { Scrollbars } from 'react-custom-scrollbars'

import Select from '../MVP/MVPSelect'
import DateRangePicker from '../MVP/Pickers/MVPDateRangePicker'

// import { DateRangePicker } from 'react-dates'

const PATIENT_TYPES = [
  { label: 'New', value: 'New' },
  { label: 'Existing', value: 'Existing' },
  { label: 'Other', value: 'Other' }
]

const OUTCOMES = [
  { label: 'Missed', value: 'Missed Call' },
  { label: 'Appointment', value: 'Appointment' }
]
const YES_NO = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' }
]

const EMPTY = []
//const HANDLERS = [{ label: 'Staff', value: 'staff' }, { label: 'Voicemail', value: 'voicemail' }]

import {
  defaultFont
} from 'src/assets/jss/mvp'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap : 'nowrap',
    padding: 0,
  },
  header: {
    display: 'flex',
    padding: '20px 16px',
    flex: '0 0 auto',
    width: '100%',

  },
  title: {
    ...defaultFont,
    fontSize: '18px',
    fontWeight: 600,
    padding: 0,
    margin: 0
  },
  content: {
    display: 'flex',
    width: '100%',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    flex: '1 1 auto'
  },

  contentWrapper: {
    padding: '20px 0',
    flex: '1 1 auto',
    '&>div>div:first-child': {
      position: 'inherit !important'
    }
  },
  control: {
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 18,
    '& div': {
      fontWeight: '400',
      fontFamily: 'Roboto Condensed'
    },
    '& div[role*="listbox"]': {
      position: 'relative',
      zIndex: '999'
    }
  },
  hidden: {
    display: 'none'
  },
  footer: {
    width: '100%',
    padding: '20px 16px',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-between'
  },
  dropdownStyle: {
    border: '1px solid black !important',
    borderRadius: '5%',
    backgroundColor: 'lightgrey !important',
  }
})

@inject('callsStore')
@withStyles(styles)
@observer
export default class CallsAdvancedFilters extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    const {
      callsStore: {
        filters
      }
    } = this.props
    const callsStoreFilters = filters.toJSON()
    const {
      gt,
      lt
    } = callsStoreFilters.callDateTime || {}
    const from = gt ? moment(gt).toDate() : undefined
    const to = gt ? moment(lt).toDate() : undefined
    const mailing = callsStoreFilters.mailing || ''
    const caller = callsStoreFilters.caller || ''
    const patientType = callsStoreFilters.patientType || ''
    const outcome = callsStoreFilters.outcome || ''
    const opportunity = callsStoreFilters.opportunity !== undefined ?
      callsStoreFilters.opportunity ? 'true' : 'false' : ''
    const answeredBy = callsStoreFilters.answeredBy || ''

    return {
      callDateTime: {
        from,
        to
      },
      mailing,
      caller,
      patientType,
      opportunity,
      outcome,
      answeredBy
    }
  }

  handleReset = () => {
    const { callsStore, onClose } = this.props
    callsStore.setQuickFilter('opportunities')
    this.setState(this.getInitialState())

    onClose()
  }

  handleApply = () => {
    const { callsStore, onClose } = this.props
    const {
      callDateTime: {
        from,
        to
      },
      mailing,
      caller,
      patientType,
      opportunity,
      outcome,
      answeredBy
    } = this.state

    let hasFilters = false
    const filters = {}

    if (to || from) {
      hasFilters = true
      filters.callDateTime = {
        gt: from && moment(from).set({h:0, m: 0, s: 0}).format(),
        lt: to && moment(to).set({h:23, m: 59, s: 59}).format()
      }
    }
    if (mailing) {
      hasFilters = true
      filters.mailing = mailing
    }
    if (caller) {
      hasFilters = true
      filters.caller = caller
    }
    if (patientType) {
      hasFilters = true
      filters.patientType = patientType
    }

    if (opportunity === 'true' || opportunity === 'false') {
      hasFilters = true
      filters.opportunity = opportunity === 'true'
    }
    if (outcome) {
      hasFilters = true
      filters.outcome = outcome
    }
    if (answeredBy) {
      hasFilters = true
      filters.answeredBy = answeredBy
    }

    callsStore.setFilters(filters)
    if (hasFilters) {
      callsStore.setQuickFilter('advanced')
    } else {
      callsStore.setQuickFilter('all')
    }

    onClose()
  }

  handleDateRangeChange = range => {
    this.setState({
      callDateTime: range
    })
  }

  handleSelectChange = id => value => {
    this.setState({
      [id]: value && value.value || ''
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className={classes.title}>Advanced Filters</h1>
        </div>
        <div className={classes.content}>
          <div className={classes.contentWrapper}>
          <Scrollbars>

          <div className={classes.control}>
            <DateRangePicker
              id="dateTime"
              label="Date"
              value={this.state.callDateTime}
              onChange={this.handleDateRangeChange}
            />
          </div>

          <div className={cx(classes.control, classes.hidden)}>
            <Select
              menuPlacement="auto"
              label="Mailing"
              id="mailing"
              placeholder="All Mailings"
              options={EMPTY}
              value={this.state.mailing}
              isClearable
              isDisabled
              onChange={this.handleSelectChange('mailing')}
              isFixedPosition={true}
            />
          </div>

          <div className={cx(classes.control, classes.hidden)}>
            <Select
              menuPlacement="auto"
              label="Caller"
              placeholder="All Callers"
              options={EMPTY}
              value={this.state.caller}
              isClearable
              isDisabled
              onChange={this.handleSelectChange('caller')}
              isFixedPosition={true}
            />
          </div>

          <div className={classes.control}>
            <Select
              menuPlacement="auto"
              label="Patient Type"
              placeholder="All Types"
              options={PATIENT_TYPES}
              value={this.state.patientType}
              isClearable
              onChange={this.handleSelectChange('patientType')}
              isFixedPosition={true}
            />
          </div>

          <div className={classes.control}>
            <Select
              menuPlacement="auto"
              label="Opportunity"
              options={YES_NO}
              value={this.state.opportunity}
              placeholder="All"
              isClearable
              onChange={this.handleSelectChange('opportunity')}
              isFixedPosition={true}
            />
          </div>

          <div className={classes.control}>
            <Select
              menuPlacement="auto"
              label="Outcome"
              options={OUTCOMES}
              placeholder="All Outcomes"
              isClearable
              value={this.state.outcome}
              onChange={this.handleSelectChange('outcome')}
              isFixedPosition={true}
            />
          </div>

          <div className={cx(classes.control, classes.hidden)}>
            <Select
              menuPlacement="auto"
              label="Answered By"
              options={EMPTY}
              placeholder="All"
              value={this.state.answeredBy}
              isClearable
              isDisabled
              onChange={this.handleSelectChange('answeredBy')}
              isFixedPosition={true}
            />
          </div>

          </Scrollbars>
          </div>
        </div>
        <div className={classes.footer}>
          <Button onClick={this.handleReset} color="primary">
            <ResetIcon />
            Reset
          </Button>
          <Button onClick={this.handleApply} color="primary" variant="contained" style={{ borderRadius: '0px', boxShadow: 'none' }}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}
