import { makeStyles, Theme } from '@material-ui/core'
import { defaultFont } from 'assets/jss/mvp'

export const useMailingCardInfoStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  tooltip: {
    background: 'rgba(40, 40, 40, 1)',
    marginTop: 2,
    marginLeft: 16,
    '&:after': {
      borderWidth: 0,
      marginLeft: 0,
    },
  },
  arrow: {
    color: 'rgba(40, 40, 40, 1)',
  },

  mailingsInfo: {
    ...defaultFont,
    display: 'flex',
    flexDirection: 'row',
  },

  account: {
    marginLeft: 5,
    '& label': {
      ...defaultFont,
      position: 'relative',
      paddingLeft: '16px',
      '&:before': {
        content: '""',
        position: 'absolute',
        height: 5,
        width: 5,
        borderRadius: '100%',
        left: 3,
        top: 5,
      },
    },
  },

  grey: {
    '& label:before': {
      background: 'rgb(201,201,201)',
    },
  },

  icon: {
    flex: '0 0 auto',
    color: '#D3D3D3',
    alignItems: 'center',
    marginRight: 11,
  },

  aduitNoticeWrapper: {
    color: '#D3D3D3',
    alignItems: 'center',
    marginRight: 5,
  },

  detail: {
    flex: '1 0 auto',
    alignItems: 'center',
    height: 22,
  },

  text: {
    ...defaultFont,
    display: 'block',
    width: '100%',
  },

  primaryText: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#141414',
    marginBottom: 4,
    width: 196,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  secondaryText: {
    ...defaultFont,
    fontSize: '12px',
    color: '#727272',
  },
}))
