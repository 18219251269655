import { defaultFont } from './index'

// Theming for buttons
export default theme => ({
  MuiInput: {
    root: {
      ...defaultFont,
      fontSize: 13,
      fontWeight: 'bold',
      alignItems: 'center',
      position: 'relative',
      
    },
    input: {
      transition: '0.3s ease border-color',
      border: '1px solid rgba(0, 0, 0, 0.05)',
      backgroundColor: 'rgba(211, 211, 211, 0.15)',
      borderRadius: 0,
      padding: 9,
      '&:hover:not(:focus)': {
        border: '1px solid rgba(0, 0, 0, 0.15)',
      },
      '&:focus': {
        borderColor: '#ED154E'
      }
    },
    error: {
      boxShadow: 'inset 0 0 0 1px #f44336',
    },
    formControl: {
      'label + &': {
        marginTop: '1.2rem'
      }
    }
  },

  MuiInputAdornment: {
    root: {
    },
    positionEnd: {
      position: 'absolute',
      right: 10
    }
  },

  MuiInputLabel: {
    root: {
      ...defaultFont,
      fontSize: '13px',
      color: '#7F7F7F',
      marginLeft: 2,
      marginBottom: 0
    },
    shrink: {
      transform: 'translate(0, 0) scale(1)'
    }
  }

})