import { makeStyles, Theme } from '@material-ui/core'

export const useDeliveredMailingCardActionsStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      borderTop: '1px solid rgba(0, 0, 0, 0.06)',
      padding: '9px 9px 9px',
      backgroundColor: '#F8F8F8',
      alignItems: 'center',
    },

    button: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '16px',
      fontWeight: 400,
      textTransform: 'none',
      paddingLeft: 12,
      paddingRight: 12,
    },

    buttonIcon: {
      width: 15,
      height: 15,
      transform: 'scaleX(-1)',
      marginLeft: 4,
    },
  })
)
