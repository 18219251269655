import _get from 'lodash/get'
import Call, { repository as callsRepo } from 'models/Call'
import Appointment, { repository as appointmentsRepo } from 'models/Appointment'

const processCallData = (result: any) => {
  const call = new Call({
    id: result.Id,
  })

  call.callDateTime = result.callDateTime.byTimeZone
  call.callAudio = result.Call_Audio__c
  call.callDuration = result.Duration__c
  call.callerPhoneNumber =
    result.phoneNumberPretty || result.Caller_Phone_Number__c
  call.callerPhoneNumberRaw = result.Caller_Phone_Number__c
  call.appointmentsCounter = result.Appointments_Counter__c

  // Campaign info
  call.campaignId = _get(result, 'Tracking_Number__r.MVP_Campaign__c')
  call.campaignName = _get(result, 'Tracking_Number__r.MVP_Campaign__r.Name')
  call.campaignTargetDeliveryWeek = _get(
    result,
    'Tracking_Number__r.MVP_Campaign__r.Target_Delivery_Week__c'
  )
  call.campaignType = _get(
    result,
    'Tracking_Number__r.MVP_Campaign__r.Campaign_Type__c'
  )

  call.accountId = _get(result, 'Tracking_Number__r.MVP_Campaign__r.Account__c')

  // Appointments
  /**
   * @todo Normalize this later if possible
   */
  const appointments = _get(result, 'Appointments__r.records', []).map(
    (row: any) => {
      const appointment = new Appointment({
        id: row.Id,
      })

      appointment.code = row.Name
      appointment.dateTime = row.Date_Time__c
      appointment.firstName = row.Patient_First_Name__c
      appointment.lastName = row.Patient_Last_Name__c
      appointment.outcome = row.Outcome__c
      appointment.previousAppointmentId = _get(
        row,
        'Previous_Appointment_Reference__r.Id'
      )
      appointment.contactId = _get(row, 'Contact__r.Id')
      appointment.contactName = _get(row, 'Contact__r.Name')
      appointment.contactEmail = _get(row, 'Contact__r.Email')

      return appointmentsRepo.upsert(appointment)
    }
  )

  call.appointments.replace(appointments)

  // Latest Score
  call.scoreId = _get(result, 'Scores__r.records.0.Id')
  call.opportunity = _get(
    result,
    'Scores__r.records.0.Appointment_Opportunity__c'
  )
  call.patientType = _get(result, 'Scores__r.records.0.Patient_Type__c')
  call.callAnsweredBy = _get(result, 'Scores__r.records.0.Call_Answered_By__c')
  call.handlerName = _get(
    result,
    'Scores__r.records.0.Call_Handler__r.MVP_Name__c'
  )
  call.callerName = _get(result, 'Scores__r.records.0.Caller_Name__c')
  call.callerIdName = result.Caller_ID_Name__c
  call.voicemailLeftMessage = _get(
    result,
    'Scores__r.records.0.Voicemail_Left_Message__c'
  )
  call.voicemailWantsAppointment = _get(
    result,
    'Scores__r.records.0.Voicemail_Wants_Appointment__c'
  )
  call.highlights = _get(result, 'Scores__r.records.0.Highlights__c')

  // Related calls if available
  const related = result.related
  if (related) {
    // Only update relatedCalls property if we have actual related call records
    call.relatedCalls.replace(
      related.map((record: any) => {
        const call = processCallData(record)
        return callsRepo.upsert(call, { skipMergeOnProps: ['relatedCalls'] })
      })
    )
  }

  return call
}

export default processCallData
