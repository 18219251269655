import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'
import momentDuration from 'moment-duration-format'
import queryString from 'query-string'

import { usePreviousLocation } from 'routes/history'
import { useStores } from 'config/store'
import Call, { repository as callsRepo } from 'models/Call'
import { HighlightContext } from 'components/Text'
import CallDetailsDrawer from 'components/Calls/CallDetailsDrawer'

import CallsDataGrid from './CallsDataGrid'
import { useStyles } from './Calls.styles'

momentDuration(moment as any)

interface CallsState {
  drawerOpen: boolean
  stopAudio: boolean
}

const Calls: React.FC = () => {
  const classes = useStyles()
  const { callsStore } = useStores()
  const history = useHistory()
  const location = useLocation()
  const previousLocation = usePreviousLocation()
  const params = useParams<{ callId: string }>()

  const [state, setStateBase] = useState<CallsState>({
    drawerOpen: false,
    stopAudio: false
  })

  const setState = (newState: Partial<CallsState>) => {
    setStateBase({ ...state, ...newState })
  }

  const { drawerOpen, stopAudio } = state

  useEffect(() => {
    if (params.callId) {
      let call = callsRepo.get(params.callId)
      if (!call) {
        const newCall = new Call({ id: params.callId })
        const newId = callsRepo.upsert(newCall)
        call = callsRepo.get(newId)
      }
      selectCall(call)
    }

    const qString = queryString.parse(location.search)
    if (qString.mailing && typeof qString.mailing === 'string') {
      callsStore.setMailingFilter(qString.mailing)
    }
  }, [location])

  const handleCloseDrawer = () => {
    setState({
      drawerOpen: false,
      stopAudio: false
    })

    if (previousLocation === null) {
      history.push('/calls')
    } else {
      history.goBack()
    }
  }

  const handleSelectCall = (call: Call) => {
    history.push({
      pathname: `/calls/${call.id}`,
      search: location.search
    })
  }

  const selectCall = (call: Call) => {
    call.update()
    setState({
      drawerOpen: true,
      stopAudio: true
    })
    callsStore.setDrawer(call)
  }

  return (
    <HighlightContext.Provider value={toJS(callsStore.searchQueryPattern)}>
      <CallsDataGrid
        classes={classes}
        stopAudio={stopAudio}
        handleSelectCall={handleSelectCall}
      />
      <CallDetailsDrawer open={drawerOpen} onClose={handleCloseDrawer} />
    </HighlightContext.Provider>
  )
}

export default observer(Calls)
