import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router'
import Link from '@material-ui/core/Link'

import Call from 'models/Call'

const useStyles = makeStyles({
  tooltipBody: {
    maxWidth: 200,
    overflow: 'hidden'
  }
})

interface HighlightsTooltipProps {
  row: Call
}

const HighlightsTooltip: React.FC<HighlightsTooltipProps> = ({ row }) => {
  const history = useHistory()
  const location = useLocation()

  const max = 150
  const classes = useStyles()

  const handleSelectCall = (call: Call) => {
    history.push({
      pathname: `/calls/${call.id}`,
      search: location.search
    })
  }

  return (
    <div className={classes.tooltipBody}>
      <span>{capitalize(limitWords(row?.highlights ?? '', 30))}</span>
      {(row?.highlights?.length ?? 0) > max && (
        <span>
          {'...\u00A0'}
          <Link href="#" onClick={() => handleSelectCall(row)}>
            show more
          </Link>
        </span>
      )}
    </div>
  )
}

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

const limitWords = (s: string, m: number) => {
  return s
    .split(' ')
    .splice(0, m)
    .join(' ')
    .replace(/[^A-Za-z0-9]+$/, '')
}

export default HighlightsTooltip
