import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'mobx-react'

import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'

import CallDetails from './CallDetails'

const styles = theme => ({
  container: {
    display: 'flex'
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  }
})

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

@withStyles(styles)
export default class CallDetailsDrawer extends Component {

  detailsRef

  constructor() {
    super()
    this.detailsRef = React.createRef()
  }

  handleClick = (event) => {
    const { onClose } = this.props
    if (onClose && !event.target.contains(this.detailsRef.current)) {
      onClose(event)
    }
  }

  render() {
    const { classes, open, onClose } = this.props
    return (
      <div className={classes.container}>
        <Drawer
          classes={{
            paper: classes.paper
          }}
          anchor="right"
          open={open}
          onClose={onClose}
        >
          <div
            tabIndex={0}
            role="button"
            ref={this.detailsRef}
          >
            <CallDetails onClose={onClose} />
          </div>
        </Drawer>
      </div>
    )
  }
}

CallDetailsDrawer.propTypes = propTypes
