import API, { IDENTITY_API } from 'src/helpers/api'

const updateSettings = async (settings) => {
  await API.put({
    api: IDENTITY_API,
    url: 'user/settings',
    data: {
      email: settings.email,
      phoneNumber: settings.phoneNumber,
      missedCallAlerts: settings.missedCallAlerts,
      receivedSmsAlerts: settings.receivedSmsAlerts,
      missedCallAlertRecipients: settings.missedCallAlertRecipients
    }
  })

  return true
}

export default updateSettings
