const styles = () => ({
  row: {
    display: 'flex',
    '& h4': {
      fontSize: 15,
      color: '#141414',
      margin: 0,
      fontWeight: 400,
      fontFamily: 'Roboto Condensed'
    },
    '& div:first-child': {
      flexBasis: '25% !important',
      justifyContent: 'left !important',
      textAlign: 'left !important'
    }
  },
  emails: {
    display: 'flex',
    marginTop: 65,
    marginBottom: 80
  },
  heading: {
    color: '#141414',
    margin: '0',
    fontSize: '20px !important',
    fontWeight: '600 !important',
    fontFamily: 'Roboto Condensed'
  },
  col: {
    flex: '52px 0 0',
    position: 'relative',
    padding: 5,
    textAlign: 'center',
    '& label': {
      color: '#141414',
      fontFamily: 'Roboto Condensed',
      fontSize: 15
    }
  },
  separator: {
    border: 'none',
    borderBottom: 'solid 1px rgb(234,234,234)'
  },
  checkbox: {
    position: 'relative',
  },
  checkboxInactive: {
    top: 12,
    width: 18,
    height: 18,
    left: 12,
    position: 'absolute',
    background: '#D3D3D3',
  },
  emailsLabel: {
    flexBasis: '20%',
    color: '#141414',
    fontFamily: 'Roboto Condensed',
    fontSize: 15
  },
  tooltip: {
    left: 24,
    top: 17
  },
  checkboxWrapper: {
    position: 'relative',
    '&:hover label': {
      display: 'block'
    }
  },
  cbDisabled: {
    '& svg': {
      fill: 'transparent'
    }
  },
  note: {
    marginLeft: 70,
    fontWeight: 400,
    fontFamily: 'Roboto Condensed'
  }
})

export default styles