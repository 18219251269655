import React, { CSSProperties } from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import { useConversionRateStyles } from './ConversionRate.style'

interface ConversionRateInterface {
  rate: number
  size: number
  customClass: CSSProperties
  extraText: string
}

const ConversionRate: React.FC<ConversionRateInterface> = ({
  rate,
  size,
  customClass,
  extraText,
}) => {
  const classes = useConversionRateStyles()
  const progressThickness = 4

  return (
    <div className={classes.conversion}>
      <div className={`${classes.conversionWrapper} ${customClass}`}>
        <CircularProgress
          className={classes.progress}
          size={size}
          thickness={progressThickness}
          variant="static"
          value={100}
          style={{ color: '#d3d3d3' }}
        />
        <CircularProgress
          className={classes.progress}
          size={size}
          thickness={progressThickness}
          variant="static"
          value={rate > 100 ? 100 : rate}
          color="primary"
        />
        <div className={classes.rate} style={{ width: size }}>
          <span>{rate}%</span>
          <span>
            Conversion
            <br />
            {extraText}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ConversionRate
