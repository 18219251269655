import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'

import Star from 'src/assets/img/patientList/patientMarkers/star.svg'
import { CircularProgress } from '@material-ui/core'

import isEmpty from 'lodash/isEmpty'
import has from 'lodash/has'
import moment from 'moment'
import momentDuration from 'moment-duration-format'

import Mailing from 'models/Mailing'
import { useStores } from 'config/store'
import { useMailingMatchedPatientsStyles } from './MailingMatchedPatients.style'

interface MatchedPatientInterface {
  id: number
  Name: string
  FirstVisitDate: string
  Production: string
}

const MailingMatchedPatients: React.FC<{
  mailing: Mailing
}> = ({ mailing }) => {
  const { mailingsStore } = useStores()
  const classes = useMailingMatchedPatientsStyles()

  const totalMatchedPatients = mailing.matchedNewPatients
  const topMatchedPatients =
    mailing.matchedTopPatients !== ''
      ? JSON.parse(mailing.matchedTopPatients).topPatients
      : []

  const hasValidFirstVisitDate = (dateTime: string): boolean => {
    if (typeof dateTime != 'undefined' && dateTime) {
      return true
    }

    return false
  }

  return (
    <div className={classes.recentCallsWrapper}>
      <h2>Matched Patients</h2>
      <a href={`/maps`}>View All ({totalMatchedPatients})</a>
      <table>
        <tbody>
          {!isEmpty(topMatchedPatients) ? (
            topMatchedPatients.map((patient: MatchedPatientInterface) => {
              const dateTime = moment(patient.FirstVisitDate)

              return (
                <tr key={patient.id}>
                  <td className={classes.matchedPatientIconWrapper}>
                    <span className={classes.count}>
                      <img src={Star} />
                    </span>
                  </td>
                  <td>{`${patient.Name || '- -'}`}</td>
                  <td>
                    {hasValidFirstVisitDate(patient.FirstVisitDate)
                      ? dateTime.format('M/D/YYYY')
                      : ''}
                  </td>
                  <td className={classes.patientProduction}>
                    {`$${Number(patient.Production).toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}`}
                  </td>
                </tr>
              )
            })
          ) : (
            <tr className={classes.callsLoader}>
              <td>
                <h1>
                  <span>No matched patients</span>
                </h1>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default observer(MailingMatchedPatients)
