import { createStyles } from '@material-ui/core'
import { SignalCellularNoSimOutlined } from '@material-ui/icons'

const styles = createStyles({
  body: {
    height: '100vh',
    width: '100vw',
  },
  logo: {
    width: 130,
    marginBottom: '2.5rem',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '52vw 1fr',
    background: 'rgb(20, 20, 20)',
    height: '100vh',
    ['@media (max-width:1024px)']: { // eslint-disable-line no-useless-computed-key
      gridTemplateColumns: '1fr',
    }
  },
  formWrapper: {
    width: '26rem',
    maxWidth: '30rem',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 70,
    marginTop: 'auto',
    marginBottom: 'auto',
    '& h1': {
      color: '#fff',
      fontSize: 30,
      fontWeight: 600,
      marginTop: 40,
      marginBottom: 40,
      textAlign: 'center',
    },
  },
  inputField: {
    background: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: 17,
    borderBottom: 'solid 1px #ED154E !important',
    fontWeight: 'normal',
    padding: 8,
    '&:hover:not(:focus)': {
      border: 'none',
    },
  },
  inputFieldFocused: {
    '&:focus': {
      borderColor: 'transparent',
    },
  },
  label: {
    fontSize: 16,
  },
  focusedLabel: {
    fontSize: 12,
    color: '#ED154E !important',
  },
  formTopIcon: {
    height: 65,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  passwordField: {
    marginTop: 20,
    height: '100%',
  },
  submit: {
    background: '#ED154E',
    color: '#fff',
    textTransform: 'capitalize',
    fontWeight: 600,
    marginTop: 35,
    marginBottom: 35,
    fontSize: '1.1rem',
    padding: 10,
    '&:hover': {
      background: '#d21345', 
    },
  },
  alternate: {
    color: '#7F7F7F',
    fontSize: 15,
    textAlign: 'center',
    cursor: 'pointer',
  },
  footer: {
    marginTop: 'auto',
    textAlign: 'center',
  },
  hideField: {
    height: 0,
    overflow: 'hidden',
    marginTop: 0,
  },
  clear: {
    color: '#7F7F7F',
    padding: 4,
    position: 'relative',
    left: 6,
    top: -5,
    '& svg': {
      height: 16,
      width: 16,
    },
  },
  toggle: {
    color: '#7F7F7F',
    padding: 4,
    position: 'relative',
    right: -11,
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  loader: {
    marginLeft: 12,
    height: '25px !important',
    width: '25px !important',
    color: '#efa8b0',
    position: 'absolute',
    right: 25,
  },
  btnDisabled: {
    color: '#efa8b0 !important',
    position: 'relative',
  },
  overflow: {
    minWidth: 479,
    marginLeft: -86,
    marginBottom: '0px !important',
  },
  sub: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 17,
    marginBottom: 40,
  },
  footerLinks: {
    display: 'flex',
    color: '#7F7F7F',
    justifyContent: 'space-between',
    marginTop: '10px',
    fontSize: '15px',
    '& a': {
      cursor: 'pointer',
    },
  },
  loaderResend: {
    color: '#7F7F7F',
    width: '16px !important',
    height: '16px !important',
    position: 'relative',
    top: '3px',
    right: '4px',
  },
})


export default styles
