import React from 'react'
import { observer, inject } from 'mobx-react'

import medal from 'src/assets/img/medal.svg'

import { PermPhoneMsg as UnsuccessfulIcon } from '@material-ui/icons'

import Mailing from 'models/Mailing'
import ConversionRate from 'components/Mailings/ConversionRate'
import MailingRecentCalls from 'components/Mailings/MailingRecentCalls'

import { useMailingPerformanceStyles } from './MailingPerformance.style'

const MailingPerformance: React.FC<{
  mailing: Mailing
}> = ({ mailing }) => {
  const classes = useMailingPerformanceStyles()

  return (
    <div className={classes.root}>
      <div className={classes.crStyle}>
        <ConversionRate
          customClass={classes.progressStyle}
          rate={mailing.conversionRate}
          size={145}
          extraText="rate"
        />
      </div>
      <div className={classes.statsWraper}>
        <div className={`${classes.stats} ${classes.red}`}>
          <label>Appointments</label>
          <span>{mailing.appointmentsCount}</span>
        </div>
        <div className={`${classes.stats} ${classes.grey}`}>
          <label>Leads</label>
          <span>{mailing.leadsCount}</span>
        </div>
      </div>
      <div className={classes.icons}>
        <img src={medal} />
        <UnsuccessfulIcon />
      </div>
      <MailingRecentCalls />
    </div>
  )
}

export default observer(MailingPerformance)
