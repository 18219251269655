import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import cx from 'classnames'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import { Scrollbars } from 'react-custom-scrollbars'

import { Close as CloseIcon } from '@material-ui/icons'

import { defaultFont } from 'src/assets/jss/mvp'

import MailingDetailsInfo from './MailingDetailsInfo'
import MailingStatusSteps from './MailingStatusSteps'
import MailingDesign from './MailingDesign'
import MailingMap from './MailingMap'
import MailingPerformance from './MailingPerformance'
import MailingMatchbackResult from './MailingMatchbackResult'

import { repository as mailingsRepo } from '../../models/Mailing'

const propTypes = {
  onClose: PropTypes.func,
}

const defaultProps = {}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100vh',
  },
  contentWrapper: {
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '500px',
  },
  closeWrapper: {
    padding: '10px 5px',
  },
  header: {
    flex: '0 0 auto',
    backgroundColor: '#ED154E',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 22px',
    color: '#fff',
    '& h1': {
      ...defaultFont,
      flex: '0 0 auto',
      fontSize: '20px',
      margin: 0,
      marginRight: 5,
    },
    '& h1 + span': {
      ...defaultFont,
      flex: '0 0 auto',
      fontWeight: '600',
      fontSize: '20px',
      margin: 0,
    },
  },
  body: {
    display: 'flex',
    flex: '1 1 auto',
  },
  bodyWrapper: {
    flex: '1 1 auto',
  },
  footer: {
    flex: '0 0 auto',
  },
  close: {},
  closeIcon: {
    color: '#fff',
  },
  loading: {
    filter: 'blur(2px)',
  },
  loader: {
    transition: '0.3s ease opacity',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  loaderIcon: {},
  loaderCaption: {
    ...defaultFont,
    marginTop: 15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666',
  },

  title: {
    ...defaultFont,
    color: '#282828',
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 20,
    minHeight: 40,
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    display: 'flex',
    alignItems: 'center',
  },
  approveBtn: {
    width: '100%',
    textTransform: 'capitalize',
    fontSize: '22px',
    borderRadius: '0px',
    padding: '15px',
  },
  modalOverlay: {
    position: 'absolute',
    top: '0px',
    background: 'rgba(255, 255, 255, .94)',
    height: '100%',
    width: '100%',
    zIndex: '999',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& h3': {
      fontFamily: 'Roboto Condensed',
      fontWeight: '600',
      fontSize: '20px',
    },
    '& p': {
      fontFamily: 'Roboto Condensed',
      width: '220px',
      fontSize: '16px',
      margin: '0 auto',
      lineHeight: '1.3',
      marginBottom: '40px',
      marginTop: '10px',
    },
    '& button': {
      width: '220px',
      margin: '0 auto',
      borderRadius: '0px',
      marginBottom: '14px',
      textTransform: 'capitalize',
    },
  },
})

@inject('mailingsStore')
@withStyles(styles)
@observer
class MailingDetails extends Component {
  state = {
    showModal: false,
    isApproving: false,
  }

  handleClose = (e) => {
    const { onClose } = this.props
    if (onClose) {
      onClose(e)
    }
  }
  handleApprove = (id) => {
    let { mailingsStore } = this.props

    this.setState({ isApproving: true })
    mailingsStore.approveMail(id).then((isApproved) => {
      if (isApproved) {
        const mailing = mailingsRepo.get(id)
        if (mailing && mailing.status === 'ordered') {
          mailing.status = 'approved'
          this.setState({ isApproving: false })
          this.setState({ showModal: false })
        }
      }
    })
  }

  handleModal = () => {
    this.setState({ showModal: true })
  }

  handleCancel = () => {
    this.setState({ showModal: false })
  }

  renderLoading() {
    const { mailingsStore, classes } = this.props

    const call = mailingsStore.drawer
    if (!call) {
      return <div />
    }

    return (
      // <Fade in={call.isLoading}>
      call.isLoading ? (
        <div className={classes.loader}>
          <div className={classes.loaderContent}>
            <div className={classes.loaderIcon}>
              <CircularProgress size={60} color="primary" />
            </div>
            <div className={classes.loaderCaption}>Loading details...</div>
          </div>
        </div>
      ) : (
        ''
      )
      // </Fade>
    )
  }

  render() {
    const { mailingsStore, classes } = this.props
    const mailing = mailingsStore.drawer
    const mailingDate =
      mailing.deliveryWeek !== null
        ? moment(mailing.deliveryWeek).format('MMM D')
        : ''

    return (
      <div className={classes.container}>
        <Paper elevation={16} className={classes.contentWrapper}>
          <div
            className={cx(
              classes.header,
              mailing.isLoading ? classes.loading : null
            )}
          >
            <h1>Mailing</h1>
            <span>
              {mailingDate} - {mailing.campaignType}
            </span>
          </div>
          <div
            className={cx(
              classes.body,
              mailing.isLoading ? classes.loading : null
            )}
          >
            <div className={classes.bodyWrapper}>
              <Scrollbars>
                {mailing.matchedNewPatients > 0 ? (
                  <div>
                    {' '}
                    <div className={classes.title}>Matchback Result</div>
                    <MailingMatchbackResult mailing={mailing} />{' '}
                  </div>
                ) : null}
                {mailing.status === 'delivered' ||
                mailing.status === 'deliveredInFirstCall' ? (
                  <div>
                    {' '}
                    <div className={classes.title}>Performance</div>
                    <MailingPerformance mailing={mailing} />{' '}
                  </div>
                ) : null}

                <div className={classes.title}>Details</div>
                <MailingDetailsInfo />

                <div className={classes.title}>Mailing Status</div>
                <MailingStatusSteps mailing={mailing} active={mailing.status} />

                <div className={classes.title}>Design</div>
                <MailingDesign />

                <div className={classes.title}>Map</div>
                <MailingMap />
              </Scrollbars>

              {this.state.showModal ? (
                <div className={classes.modalOverlay}>
                  <h3>Confirm Approval?</h3>
                  <p>
                    Approving your mailing design and map will confirm that your
                    mailings are now ready for printing.
                  </p>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.isApproving}
                    onClick={() => this.handleApprove(mailing.id)}
                  >
                    Confirm{' '}
                    {this.state.isApproving ? (
                      <CircularProgress
                        size={14}
                        style={{ marginLeft: 8, color: '#333', opacity: 0.9 }}
                      />
                    ) : null}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={cx(
              classes.footer,
              mailing.isLoading ? classes.loading : null
            )}
          >
            {mailing.status === 'ordered' ? (
              <Button
                className={classes.approveBtn}
                variant="contained"
                color="primary"
                onClick={this.handleModal}
              >
                Approve
              </Button>
            ) : null}
          </div>
          {this.renderLoading()}
        </Paper>
        <div className={classes.closeWrapper} onClick={this.handleClose}>
          <IconButton className={classes.close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
    )
  }
}

MailingDetails.propTypes = propTypes
MailingDetails.defaultProps = defaultProps

export default MailingDetails
