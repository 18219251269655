import _merge from 'lodash/merge'

// List of overrides settings go here
import button from './button'
import nav from './nav'
import input from './input'
import table from './table'
import typography from './typography'
import utils from './utils'

export const defaultFont = {
  fontFamily: '"Roboto Condensed", sans-serif',
}

export default theme => _merge(
  button(theme),
  input(theme),
  nav(theme),
  table(theme),
  typography(theme),
  utils(theme)
)
