import sortImage from 'src/assets/img/sort.png'

// Theming for tables
export default theme => ({
  MuiTable: {
    root: {

    }
  },

  MuiTableBody: {
    root: {
      backgroundColor: '#fff',
      color: '#232323',
      width: '100%'
    }
  },

  MuiTableRow: {
    root: {
      // '&:nth-child(even) td': {
      //   backgroundColor: '#f8f8f8'
      // }
    },
    hover: {
      '&:hover td': {
        backgroundColor: '#fde7ed',
        color: '#ED154E'
      }
    }
  },

  MuiTableCell: {
    root: {
      fontFamily: '\'Roboto Condensed\', sans-serif',
      fontWeight: 400,
      lineHeight: '52px',
      verticalAlign: 'middle',
      paddingLeft: 15,
      paddingRight: 15,
      whiteSpace: 'nowrap',
      userSelect: 'none'
    },

    head: {
      paddingLeft: 16,
      paddingRight: 12,
      paddingBottom: 2,
      fontWeight: 500,
      verticalAlign: 'bottom',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      color: '#8D8D8D',
      lineHeight: '56px',
      borderBottomWidth: 0,
    },

    body: {
      fontSize: '14px',
      color: 'inherit',
      borderBottomWidth: 0
    }
  },

  MuiTableSortLabel: {
    root: {
      '&:hover': {
        color: theme.palette.primary.main,
        '& $icon': {
          opacity: 1
        }
      },
      // position: 'relative',
      // '&:after': {
      //   position: 'absolute',
      //   content: '""',
      //   top: 'calc(50% - 9px)',
      //   right: 0,
      //   display: 'block',
      //   width: 10,
      //   height: 18,
      //   backgroundColor: 'red'
      // }
    },
    active: {
      color: theme.palette.primary.main + ' !important',
      '& $iconDirectionAsc': {
        backgroundPosition: '-9px 0',
      },
      '& $iconDirectionDesc': {
        backgroundPosition: '-20px 0',
      },
    },
    icon: {
      opacity: 0.8,
      marginLeft: 10,
      width: 10,
      height: 18,
      backgroundImage: `url(${sortImage})`,
      backgroundRepeat: 'no-repeat',
      '& path': {
        display: 'none'
      }
    },
    iconDirectionAsc: {
      transform: 'none'
    },
    iconDirectionDesc: {
      transform: 'none'
    },
  },

  MuiTablePagination: {
    caption: {
      fontSize: '14px'
    },
    spacer: {
      display: 'none',
    },
    selectRoot: {
      backgroundColor: 'rgba(211, 211, 211, 0.3)',
      '&:hover': {
        backgroundColor: 'rgba(211, 211, 211, 0.6)',
      }
    },
    select: {
      paddingLeft: 8,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 24,
      fontSize: '15px',
      lineHeight: '28px'
    },
    selectIcon: {
      top: 'calc(50% - 12px)',
      lineHeight: '32px'
    }
  }
})
