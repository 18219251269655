import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

import mailingPlaceholder from 'src/assets/img/mailing-placeholder.png'

const styles = (theme) => ({
  root: {},
  postcardWrapper: {
    width: '100%',
    backgroundColor: '#eee',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '146px',
  },
  loader: {
    position: 'absolute',
    zIndex: 90,
  },
  media: {
    padding: '8px 8px 0px',
    '& img': {
      width: '100%',
      minHeight: '146px',
      position: 'relative',
      zIndex: 100,
      cursor: 'pointer',
    },
  },
  status: {
    position: 'absolute',
    zIndex: '999',
    marginTop: '22px',
    color: '#cacaca',
  },
})

@withStyles(styles)
export default class MailingThumbnail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: this.props.mailing.frontDesignThumbnailUrl,
      isFailed: false,
    }
  }

  handleAction = (action, mailing) => (event) => {
    const { onAction } = this.props
    onAction(action, mailing)
  }

  handleMissingDesign = () => {
    this.setState({
      imageUrl: mailingPlaceholder,
      isFailed: true,
    })
  }

  render() {
    const { classes, mailing, ...props } = this.props
    return (
      <div className={classes.media}>
        <div className={classes.postcardWrapper}>
          <CircularProgress
            className={classes.loader}
            size={50}
            color="primary"
          />
          <img
            onError={this.handleMissingDesign}
            onClick={this.handleAction('view', mailing)}
            src={this.state.imageUrl}
          />
          {this.state.isFailed ? (
            mailing.status === 'delivered' ||
            mailing.status === 'deliveredInFirstCall' ? (
              <div className={classes.status}>
                <h4>We can't load the image</h4>
              </div>
            ) : (
              <div className={classes.status}>
                <h4>Mailing design is coming soon</h4>
              </div>
            )
          ) : null}
        </div>
      </div>
    )
  }
}
