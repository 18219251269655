import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'

import appState from '../../config/store/AppState'

import MailingDetails from './MailingDetails'

const styles = (theme) => ({
  container: {
    display: 'flex',
  },
  paper: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  toBack: {
    zIndex: '0 !important',
  },
})

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

@withStyles(styles)
export default class MailingDetailsDrawer extends Component {
  detailsRef

  constructor() {
    super()
    this.detailsRef = React.createRef()
  }

  handleClick = (event) => {
    const { onClose } = this.props
    if (onClose && !event.target.contains(this.detailsRef.current)) {
      onClose(event)
    }
  }

  render() {
    const { classes, open, onClose } = this.props
    return (
      <div className={classes.container}>
        <Drawer
          classes={{
            paper: classes.paper,
            root: appState.isRestricted() && classes.toBack,
          }}
          anchor="right"
          open={open}
          onClose={onClose}
        >
          <div tabIndex={0} role="button" ref={this.detailsRef}>
            <MailingDetails onClose={onClose} />
          </div>
        </Drawer>
      </div>
    )
  }
}

MailingDetailsDrawer.propTypes = propTypes
