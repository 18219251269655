import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import cx from 'classnames'
import queryString from 'query-string'

import withStyles from '@material-ui/core/styles/withStyles'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Scrollbars } from 'react-custom-scrollbars'

import CloseIcon from '@material-ui/icons/Close'

import { defaultFont } from 'src/assets/jss/mvp'
import { HighlightContext } from 'src/components/Text'

import CallDetailsHeader from './CallDetailsHeader'
import CallDetailsInfo from './CallDetailsInfo'
import CallDetailsAppointments from './CallDetailsAppointments'
import CallDetailsHighlights from './CallDetailsHighlights'
import CallDetailsOtherCalls from './CallDetailsOtherCalls'
import CallDetailsAudio from './CallDetailsAudio'
import Media from '../AudioPlayer/Media'
import { Player } from 'react-media-player'

const propTypes = {
  onClose: PropTypes.func
}

const defaultProps = {
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100vh'
  },
  contentWrapper: {
    position: 'relative',
    height: '100vh',
    minWidth: 500,
    display: 'flex',
    flexDirection: 'column'
  },
  closeWrapper: {
    padding: '10px 5px'
  },
  header: {
    flex: '0 0 auto'
  },
  body: {
    display: 'flex',
    flex: '1 1 auto'
  },
  bodyWrapper: {
    flex: '1 1 auto'
  },
  footer: {
    flex: '0 0 auto'
  },
  close: {
  },
  closeIcon: {
    color: '#fff'
  },
  loading: {
    filter: 'blur(2px)'
  },
  loader: {
    transition: '0.3s ease opacity',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  loaderIcon: {
  },
  loaderCaption: {
    ...defaultFont,
    marginTop: 15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666'
  }
})

@withRouter
@inject('callsStore')
@withStyles(styles)
@observer
class CallDetails extends Component {

  handleClose = e => {
    const { onClose } = this.props
    if (onClose) {
      onClose(e)
    }
  }

  renderLoading() {
    const { callsStore, classes } = this.props

    const call = callsStore.drawer
    if (!call) {
      return <div />
    }

    return (
      // <Fade in={call.isLoading}>
      call.isLoading ?
        <div className={classes.loader}>
          <div className={classes.loaderContent}>
            <div className={classes.loaderIcon}>
              <CircularProgress size={60} color="primary" />
            </div>
            <div className={classes.loaderCaption}>Loading details...</div>
          </div>
        </div> :
        ''
      // </Fade>
    )
  }

  render() {
    const { callsStore, classes, location } = this.props
    const parsedQuery = queryString.parse(location.search)
    const autoPlay = parsedQuery.autoplay == 1
    const call = callsStore.drawer
    if (!call) {
      return <div></div>
    }

    return (
      <HighlightContext.Provider value={callsStore.searchQueryPattern}>
      <div className={classes.container}>
        <Paper elevation={16} className={classes.contentWrapper}>
          <div className={ cx(classes.header, call.isLoading ? classes.loading : null) }>
            <CallDetailsHeader />
          </div>
          <div className={ cx(classes.body, call.isLoading ? classes.loading : null) }>
            <div className={classes.bodyWrapper}>
              <Scrollbars>
                <CallDetailsInfo />
                <CallDetailsAppointments />
                <CallDetailsHighlights />
                <CallDetailsOtherCalls />
              </Scrollbars>
            </div>
          </div>
          <div className={ cx(classes.footer, call.isLoading ? classes.loading : null) }>
            <Media>
                <div className="media">
                  <div className="media-player">
                    <Player src={call.callAudio} vendor={'audio'} autoPlay={autoPlay}/>
                  </div>
                  <div className="media-controls">
                    <CallDetailsAudio callDuration={call.callDuration} play={Boolean(parsedQuery.play)}/>
                  </div>
                </div>
            </Media>
          </div>
          {this.renderLoading()}
        </Paper>
        <div className={classes.closeWrapper} onClick={this.handleClose}>
          <IconButton className={classes.close}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
      </HighlightContext.Provider>
    )
  }

}

CallDetails.propTypes = propTypes
CallDetails.defaultProps = defaultProps

export default CallDetails
