import React, { Fragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import _get from 'lodash/get'
import { IconButton, Button, Zoom as Transition } from '@material-ui/core'
import { ViewAgenda } from '@material-ui/icons'
import Merge from 'mdi-react/CheckboxBlankIcon'

import { getDesigns } from 'services/designs/getDesigns'
import {
  selectDesignConcept,
  SelectDesignConceptArgs,
} from 'services/designs/selectDesignConcept'
import appState from 'config/store/AppState'

import {
  Header,
  ImageSlider,
  CardFace,
  ActionsOverlayContainer,
  useEscape,
  DesignConceptModalSelector,
  useDesignConceptModalSelector,
  DesignConcepts,
  DesignConceptPreview,
} from '@mvp/mvp-react'

interface DesignConceptSelectionProps {
  designConcepts: DesignConcepts
}

const DesignConceptSelection: React.FC<DesignConceptSelectionProps> = ({
  designConcepts,
}) => {
  const history = useHistory()

  const queryParams = new URLSearchParams(useLocation().search)
  const campaignIdsParam = queryParams.get('campaignIds')
  const hasCampaignIdsParam =
    campaignIdsParam !== null && campaignIdsParam.length > 0

  const {
    modalArgs,
    closeModal,
    openConfirmSelectionModal,
    openHelpModal,
    openVideoWalkthroughModal,
  } = useDesignConceptModalSelector()
  const [openImageViewer, setOpenImageViewer] = useState<CardFace | undefined>(
    undefined
  )
  const closeImageViewer = () => {
    setOpenImageViewer(undefined)
  }
  useEscape(closeImageViewer)

  const [split, setSplit] = useState(false)
  const [boxWidth, setBoxWidth] = useState(Number)
  const [selectedOption, setSelectedOption] = useState<{
    front: { id: string }
    back: { id: string }
  }>({
    front: {
      id: Object.keys(designConcepts)[0],
    },
    back: {
      id: Object.keys(designConcepts)[0],
    },
  })

  const handleConfirmSelection = () => {
    openConfirmSelectionModal({
      open: true,
      selectedOption: { split, ...selectedOption },
      handleClickConfirm: async () => {
        const { front, back } = selectedOption
        const unselectedOptions = Object.keys(designConcepts).filter(
          (design) => {
            return design !== front.id && design !== back.id
          }
        )

        try {
          if (split && front.id !== back.id) {
            const designs = await getDesigns([front.id, back.id])
            const frontDesign =
              designs[0].id !== front.id ? designs[1] : designs[0]
            const backDesign =
              designs[1].id !== back.id ? designs[0] : designs[1]
            let options: SelectDesignConceptArgs = {
              name: frontDesign.name,
              accountId: designConcepts[front.id].accountId,
              mediaType: frontDesign.mediaType,
              startingPoint: front.id,
              secondaryStartingPoint: back.id,
              frontOrientation: frontDesign.frontOrientation,
              backOrientation: backDesign.backOrientation,
              archiveDesignIds: unselectedOptions,
            }
            if (hasCampaignIdsParam) {
              const campaignIds = (campaignIdsParam as string).split(',')
              options.campaignIds = campaignIds
            }

            const selectedDesignConcept = await selectDesignConcept(options)
            const { accountId, id } = selectedDesignConcept
            await history.push({
              pathname: `/accounts/${accountId}/designs/${id}`,
              state: { fromDesignConceptSelection: true },
            })
          } else {
            const opts: SelectDesignConceptArgs = { designId: front.id }

            if (campaignIdsParam !== null && campaignIdsParam.length > 0) {
              opts.campaignIds = campaignIdsParam.split(',')
            }

            if (unselectedOptions.length > 0) {
              opts.archiveDesignIds = unselectedOptions
            }

            await selectDesignConcept(opts)
            await history.push({
              pathname: `/accounts/${
                designConcepts[front.id].accountId
              }/designs/${front.id}`,
              state: { fromDesignConceptSelection: true },
            })
          }
        } catch (e) {
          console.log(e)

          appState.errorMessage(
            'There was a problem processing the designs. Please try again later.'
          )
        }
      },
      handleClickBack: closeModal,
      options: designConcepts,
    })
  }

  return (
    <Fragment>
      <Transition
        in={typeof openImageViewer !== 'undefined'}
        mountOnEnter
        unmountOnExit
      >
        <div>
          <DesignConceptPreview
            initialCardFace={openImageViewer ?? CardFace.Front}
            isSplit={split}
            handleConfirmSelection={handleConfirmSelection}
            closePreview={closeImageViewer}
            previewFront={designConcepts[selectedOption.front.id].front.src}
            previewBack={designConcepts[selectedOption.back.id].back.src}
            openHelpModal={openHelpModal}
            openVideoWalkthroughModal={openVideoWalkthroughModal}
          />
          <DesignConceptModalSelector
            modalArgs={modalArgs}
            closeModal={closeModal}
          />
        </div>
      </Transition>
      {!openImageViewer && (
        <Fragment>
          <Header
            title="Design Concepts"
            buttons={
              <Fragment>
                <ActionsOverlayContainer
                  openHelpModal={openHelpModal}
                  openVideoWalkthroughModal={openVideoWalkthroughModal}
                  menuButtonStyles={{ color: 'rgba(0, 0, 0, 0.54)' }}
                />
              </Fragment>
            }
          />
          <div
            style={{
              width: `${boxWidth}px`,
              margin: '0 auto',
              marginTop: '28px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h4 style={{ margin: 0, fontSize: 22 }}>
              {split
                ? 'Choose a design combination.'
                : 'Choose a design concept.'}
            </h4>
            {/*<div>
              <IconButton
                style={!split ? { color: '#ed154e' } : {}}
                size="small"
                onClick={() => {
                  setSplit(false)
                }}
              >
                <Merge />
              </IconButton>
              <IconButton
                style={split ? { color: '#ed154e' } : {}}
                size="small"
                onClick={() => {
                  setSplit(true)
                }}
              >
                <ViewAgenda />
              </IconButton>
              </div>*/}
          </div>
          <div style={{ marginTop: '12px' }}>
            <ImageSlider
              images={designConcepts}
              initialState={{ split, ...selectedOption }}
              split={split}
              onSelect={(selected: {
                split: boolean
                front: { id: string }
                back: { id: string }
              }) => {
                if (
                  JSON.stringify(selectedOption) !== JSON.stringify(selected)
                ) {
                  setSelectedOption(selected)
                }
              }}
              onClickImage={setOpenImageViewer}
              setBoxWidth={setBoxWidth}
            />
          </div>
          <div
            style={{
              width: `${boxWidth}px`,
              margin: '0 auto',
              marginTop: '28px',
            }}
          >
            <Button
              style={{ width: `${boxWidth}px`, background: '#ED154E' }}
              variant="contained"
              color="primary"
              onClick={handleConfirmSelection}
            >
              {split ? 'Choose this combination' : 'Choose this concept'}
            </Button>
          </div>
          <DesignConceptModalSelector
            modalArgs={modalArgs}
            closeModal={closeModal}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default DesignConceptSelection
