import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
import { createStyles, Theme } from '@material-ui/core'
import { Observer } from 'mobx-react'

import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'

import Header from 'components/MVP/Header/Header'
import MVPFlashMessages from 'components/MVP/MVPFlashMessages'
import NavigationBar from 'components/MVP/Navigation/NavigationBar'
import BlockAccessOverlay from 'views/Grants/BlockAccessOverlay'
import routes from 'routes'
import appState from 'config/store/AppState'

import PageComponent from './Page'

const appStyle = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      flexGrow: 1,
      zIndex: 1,
      overflow: 'visible',
      position: 'relative',
      backgroundColor: '#f3f3f3',
    },

    mainPanel: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },

    blurContent: {
      '& *': {
        filter: 'blur(3px)',
      },
    },

    content: {
      display: 'flex',
      flex: '1 1 auto',
    },
  })

type AppProps = RouteComponentProps & WithStyles<typeof appStyle>

class App extends Component<AppProps, { mobileOpen: boolean }> {
  constructor(props: AppProps) {
    super(props)
    this.state = {
      mobileOpen: false,
    }
  }

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }

  getRoute() {
    return this.props.location.pathname !== '/maps'
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <Observer
          render={() => {
            if (appState.expiredSession) {
              return <Redirect to="/logout" />
            } else {
              return (
                <React.Fragment>
                  <MVPFlashMessages />
                  <div className={classes.root}>
                    <Switch>
                      {routes.map((route: any, key: any) => {
                        if (route.redirect) {
                          return (
                            <Redirect
                              key={key}
                              exact={Boolean(route.exact)}
                              from={route.path}
                              to={route.to}
                            />
                          )
                        }

                        return (
                          <Route
                            key={key}
                            path={route.path}
                            exact={Boolean(route.exact)}
                            render={(props) => (
                              <React.Fragment>
                                <NavigationBar />
                                <div className={classes.mainPanel}>
                                  {[
                                    'Maps',
                                    'Design Concept Selection',
                                    'Design Review',
                                    'Revision Review',
                                    'Campaign Media Review',
                                  ].includes(route.title) ? null : (
                                    <Header
                                      title={route.title}
                                      topToolbar={
                                        route.toolbars && route.toolbars.top
                                      }
                                      bottomToolbar={
                                        route.toolbars && route.toolbars.bottom
                                      }
                                    />
                                  )}
                                  <main className={classes.content}>
                                    {appState.isRestricted() && (
                                      <BlockAccessOverlay />
                                    )}
                                    <PageComponent
                                      track={route.track}
                                      title={route.title}
                                      style={
                                        [
                                          'Design Concept Selection',
                                          'Design Review',
                                          'Revision Review',
                                          'Campaign Media Review',
                                        ].includes(route.title)
                                          ? {
                                              paddingTop: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              paddingRight: 0,
                                            }
                                          : {}
                                      }
                                    >
                                      <route.page {...props} />
                                    </PageComponent>
                                  </main>
                                </div>
                              </React.Fragment>
                            )}
                          />
                        )
                      })}
                    </Switch>
                  </div>
                </React.Fragment>
              )
            }
          }}
        />
      </React.Fragment>
    )
  }
}

export default withStyles(appStyle)(App)
