import React, { useState, useEffect } from 'react'
import cx from 'classnames'

import {
  FormControl,
  Input,
  InputLabel,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import { Close } from '@material-ui/icons/'

import getPrettyPhoneNumber, { isPhoneValid } from 'helpers/phoneNumber'
import { Theme } from '../LoginForm'

import { makeStyles } from '@material-ui/core'

const styles = makeStyles({
  red: {
    color: '#f8003d !important',
  },

  black: {
    color: 'rgb(20,20,20) !important',
  },

  focusedLabel: {
    fontSize: 12,
    color: '#ED154E !important',
  },
  label: {
    fontSize: 16,
  },
  inputField: {
    background: 'transparent',
    border: 'none',
    color: '#fff',
    fontSize: 17,
    borderBottom: 'solid 1px #ED154E !important',
    fontWeight: 'normal',
    padding: 8,
    '&:hover:not(:focus)': {
      border: 'none',
    },
  },
  inputFieldFocused: {
    '&:focus': {
      borderColor: 'transparent',
    },
  },
  clear: {
    color: '#7F7F7F',
    padding: 4,
    position: 'relative',
    left: 6,
    '& svg': {
      height: 16,
      width: 16,
    },
  },
})

interface EmailOrPhoneFieldProps {
  onChange: Function
  passwordFieldVisible: boolean
  theme: Theme
  defaultValue: string
}

const EmailOrPhoneField: React.FC<EmailOrPhoneFieldProps> = (props) => {
  const { onChange, passwordFieldVisible, theme, defaultValue } = props
  const classes = styles()
  const [emailOrPhone, setEmailOrPhone] = useState(defaultValue || '')

  useEffect(() => {
    onChange(emailOrPhone)
  }, [emailOrPhone])

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmailOrPhone(event.currentTarget.value)
  }

  const handleBlur = () => {
    const isPhone = isPhoneValid(emailOrPhone)
    if (isPhone) {
      if (!!getPrettyPhoneNumber(emailOrPhone)) {
        setEmailOrPhone(getPrettyPhoneNumber(emailOrPhone))
      }
    }
  }

  return (
    <FormControl>
      {!defaultValue.length && (
        <InputLabel
          classes={{
            shrink: classes.focusedLabel,
          }}
          className={classes.label}
          htmlFor="email-or-phone"
        >
          {passwordFieldVisible
            ? 'Enter your email'
            : 'Enter your email or mobile number'}
        </InputLabel>
      )}

      <Input
        classes={{
          input: cx(classes.inputField, theme === Theme.Light && classes.black),
          focused: classes.inputFieldFocused,
        }}
        disabled={!!defaultValue.length}
        id="email-or-phone"
        endAdornment={
          !defaultValue.length && (
            <InputAdornment position="end">
              {!!emailOrPhone.length && (
                <IconButton
                  onClick={() => setEmailOrPhone('')}
                  className={classes.clear}
                >
                  <Close />
                </IconButton>
              )}
            </InputAdornment>
          )
        }
        value={emailOrPhone}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FormControl>
  )
}

export default EmailOrPhoneField
