import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import cx from 'classnames'

import { Tooltip } from '@material-ui/core'

import MailingIcon from '@material-ui/icons/PermMedia'

import Mailing from 'models/Mailing'
import { useStores } from 'config/store'
import { useMailingCardInfoStyles } from './MailingCardInfo.style'
import MailingAuditNotice from 'components/Mailings/MailingAuditNotice'

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { grey } from '@material-ui/core/colors'

const MailingCardInfo: React.FC<{
  mailing: Mailing
  index: number
}> = ({ mailing, index }) => {
  const { appState } = useStores()
  const { accounts } = appState
  const classes = useMailingCardInfoStyles()
  const [hoveredStatus, setHoveredStatus] = useState<boolean[]>([false])
  const [accountName, setAccountName] = useState<string | undefined>()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const matchedLastAuditDate: any = new Date(mailing.matchedLastAudit)
  const currentDate: any = new Date()
  const timeDifference = Math.abs(currentDate - matchedLastAuditDate)
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))

  const noAuditCampaignTitle = 'No Audit Campaign'
  const noAuditCampaingDescription =
    'This mailing has not been audited yet. Send us an updated new patient list to see the most accurate metrics.'

  const auditedCampaignTitle = 'Audited Campaign'
  const auditedCampaignDescription =
    "This mailing was audited for new patient matchback. Send us an updated new patient list to see if you've gotten any results since your last audit."

  const outdatedAuditCampaignTitle = 'Outdated Audit Campaign'
  const outdatedAuditCampaignDescription =
    'This mailing was last audited 4 weeks after the first call which means you will be missing upto 30% of the results. Send us an updated new patient list to see the most accurate metrics.'

  const mailingDate = mailing.deliveryWeek
    ? moment(mailing.deliveryWeek).format('MMM D')
    : ''

  const handleMouseHover = (key: number): void => {
    const updatedStatus = []
    updatedStatus[key] = true

    setHoveredStatus([...updatedStatus])
  }

  useEffect(() => {
    mailingAccount(mailing, accounts)
  }, [accounts])

  const truncateAccountName = (name: string | undefined): string => {
    if (name && name.length > 20) {
      return name.substring(0, 20) + '...'
    }
    return name || ''
  }

  const mailingAccount = (mailing: Mailing, accounts: Account[]): void => {
    const account = accounts.find((account) => account.id == mailing.accountId)
    const name = account?.name ?? ''

    setAccountName(name)
  }

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <MailingIcon width={24} height={24} />
      </div>
      <div className={classes.detail}>
        <Tooltip
          classes={{
            tooltip: classes.tooltip,
            arrow: classes.arrow,
          }}
          title={accountName || ''}
          open={showTooltip}
          placement="bottom"
        >
          <div>
            <div className={cx(classes.text, classes.primaryText)}>
              <span>
                {mailingDate} - {mailing.campaignType}
              </span>
            </div>
            <div className={cx(classes.mailingsInfo, classes.secondaryText)}>
              {Number(mailing.quantity).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
              {' pcs'}

              <div
                className={cx(classes.account, classes.grey)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <label>{truncateAccountName(accountName)}</label>
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
      <div
        className={classes.aduitNoticeWrapper}
        onMouseEnter={() => handleMouseHover(index)}
        onMouseLeave={() => setHoveredStatus([])}
      >
        <MailingAuditNotice
          index={index}
          showNoticed={hoveredStatus[index] ?? false}
          title={
            mailing.matchedLastAudit === ''
              ? noAuditCampaignTitle
              : daysDifference <= 31
              ? auditedCampaignTitle
              : outdatedAuditCampaignTitle
          }
          description={
            mailing.matchedLastAudit === ''
              ? noAuditCampaingDescription
              : daysDifference <= 31
              ? auditedCampaignDescription
              : outdatedAuditCampaignDescription
          }
          children={
            mailing.matchedLastAudit === '' ? (
              <InfoOutlinedIcon
                width={22}
                height={22}
                style={{ color: grey[500] }}
              />
            ) : daysDifference <= 31 ? (
              <CheckCircleOutlinedIcon
                width={22}
                height={22}
                style={{ color: grey[500] }}
              />
            ) : (
              <QueryBuilderOutlinedIcon
                width={22}
                height={22}
                style={{ color: grey[500] }}
              />
            )
          }
        />
      </div>
    </div>
  )
}

export default observer(MailingCardInfo)
