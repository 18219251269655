import DashboardPage from 'src/views/Dashboard/Dashboard'
import CallsPage from 'src/views/Calls/Calls'
import MailingsPage from 'src/views/Mailings/Mailings'
import SettingsPage from 'src/views/Settings/SettingsPage'
import MapsPage from 'src/views/Maps/Maps'
import DesignConceptSelectionPage from 'src/views/DesignConceptSelection'
import DesignReviewPage from 'src/views/DesignReview'
import CampaignMediaReviewPage from 'src/views/DesignReview/CampaignMediaReview'

import CallsToolbars from 'src/views/Calls/toolbars'
import MailingsToolbars from 'src/views/Mailings/toolbars'
import SettingsToolbars from 'src/views/Settings/toolbars'

import {
  Call as CallIcon,
  Dashboard as DashboardIcon,
  Mail as MailIcon,
  Settings as SettingsIcon,
  Map as MapsIcon,
} from '@material-ui/icons'

const routes = [
  {
    path: '/',
    redirect: true,
    exact: true,
    to: '/calls',
    track: {
      category: 'Calls',
      name: 'Call List',
    },
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: DashboardIcon,
    page: DashboardPage,
    track: {
      category: 'Dashboard',
      name: 'Dashboard Page',
    },
  },
  {
    path: '/design-concept-selection',
    title: 'Design Concept Selection',
    icon: DashboardIcon,
    page: DesignConceptSelectionPage,
  },
  {
    path: '/accounts/:accountId/designs/:designId',
    title: 'Design Review',
    icon: DashboardIcon,
    page: DesignReviewPage,
  },
  {
    path: '/campaigns/:campaignId/design',
    title: 'Campaign Media Review',
    icon: DashboardIcon,
    page: CampaignMediaReviewPage,
  },
  {
    path: '/calls',
    title: 'Calls',
    exact: true,
    icon: CallIcon,
    page: CallsPage,
    toolbars: CallsToolbars,
    track: {
      category: 'Calls',
      name: 'Calls List',
    },
  },
  {
    path: '/calls/search',
    title: 'Calls',
    exact: true,
    icon: CallIcon,
    page: CallsPage,
    toolbars: CallsToolbars,
    track: {
      category: 'Calls',
      name: 'Calls List',
    },
  },
  {
    path: '/calls/:callId',
    title: 'Calls',
    exact: true,
    icon: CallIcon,
    page: CallsPage,
    toolbars: CallsToolbars,
    track: {
      category: 'Calls',
      name: 'Call Details',
    },
  },
  {
    path: '/mailings',
    title: 'Mailings',
    exact: true,
    icon: MailIcon,
    page: MailingsPage,
    toolbars: MailingsToolbars,
  },
  {
    path: '/maps',
    title: 'Maps',
    exact: true,
    icon: MapsIcon,
    page: MapsPage,
  },
  {
    path: '/mailings/:mailingId',
    title: 'Mailings',
    exact: true,
    icon: MailIcon,
    page: MailingsPage,
    toolbars: MailingsToolbars,
  },
  {
    path: '/notifications',
    title: 'Notifications',
    icon: DashboardIcon,
    page: DashboardPage,
  },
  {
    path: '/settings',
    title: 'Settings',
    icon: SettingsIcon,
    page: SettingsPage,
    toolbars: SettingsToolbars,
    track: {
      category: 'Settings',
      name: 'Settings',
    },
  },
]

export default routes
