import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  container: {
    padding: '10px 30px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    minHeight: 50
  },
  title: {
    color: '#141414',
    fontSize: '1.4em',
    fontWeight: 700,
    flex: '1 0 auto'
  },
  toolbar: {
    flex: '0 0 auto'
  },
})

const HeaderTop = props => {
  const { classes, title, children } = props
  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h6">{title}</Typography>
      <div className={classes.toolbar}>
        {children}
      </div>
    </div>
  )
}

export default withStyles(styles)(HeaderTop)
