import axios from 'axios'
import _flatten from 'lodash/flatten'
import { flow } from 'mobx'
import { observable, action, computed, reaction, IReactionDisposer } from 'mobx'

import getPatientList from 'services/patientList/getPatientList'
import getMails from 'services/mailings/getMails'
import getMailingById from 'services/mailings/getMailingById'
import getSummary from 'services/mailings/getSummary'
import approveMails from 'services/mailings/approveMails'
import Mailing, { repository as mailingsRepo } from 'models/Mailing'
import accountStore from 'config/store/AccountStore'

interface SummaryInterface {
  leads: number
  appointments: number
  conversionRate: number
}

interface MailingsPerPageInterface {
  mailing?: Mailing
  isAudited: boolean
  totalProduction: number
  totalNewPatients: number
}
export class MailingsStore {
  @observable reloadData: boolean = false
  @observable delivered = observable.array<string>([])
  @observable isLoading: boolean = false
  @observable isLoadingDeliveredMailings: boolean = false
  @observable isDeliveredMailingsPaginated: boolean = false
  @observable isLoadingGetPatientList: boolean = false

  @observable isDirty: boolean = false
  @observable itemPerPageIdsDelivered = observable.array<string>([])
  @observable totalItemsPerPage: number = 0
  @observable itemIds = observable.array<string>([])
  @observable totalItems: number = 0
  @observable totalCountDelivered: number = 0

  @observable pageSize: number = 15
  @observable currentPage: number = 1
  @observable orderBy: string | null = 'deliveryWeek'
  @observable dir: string | null = 'desc'
  @observable filters = observable.map(
    new Map<string, any>([['oportunity', true]])
  )
  @observable error?: string | null = null

  @observable activeQuickFilter: string = ''

  // States for Mailing details drawer
  @observable drawer: Mailing | null = null

  // States for Quick summary component
  @observable previousSummary: SummaryInterface = {
    leads: 0,
    appointments: 0,
    conversionRate: 0,
  }

  @observable summary: SummaryInterface = {
    leads: 0,
    appointments: 0,
    conversionRate: 0,
  }

  @observable mailingsPerPage: MailingsPerPageInterface = {
    mailing: undefined,
    isAudited: false,
    totalProduction: 0,
    totalNewPatients: 0,
  }

  @observable campaignItemsPerPage: any | undefined = undefined

  cancelToken: any | undefined = undefined
  cancelFetchDelivered: any | undefined = undefined
  cancelFetchSummary: any | undefined = undefined
  queriesHandler: IReactionDisposer
  dirtyHandler: IReactionDisposer
  quickFiltersHandler: IReactionDisposer
  itemsPerPage = observable.array<string>([])

  constructor() {
    this.queriesHandler = this.monitorQueries()
    this.dirtyHandler = this.monitorDirty()
    this.quickFiltersHandler = this.monitorQuickFilters()
    this.monitorSelectedAccounts()

    // Start loading
    this.activeQuickFilter = 'all'
    this.isDirty = true
  }

  monitorSelectedAccounts() {
    return reaction(
      () => accountStore.selectedAccounts.slice(),
      () => {
        this.dirty(true)
      }
    )
  }

  monitorQueries() {
    return reaction(
      () => ({
        page: this.currentPage,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        dir: this.dir,
        filters: this.filters.toJSON(),
      }),
      () => {
        this.dirty(true)
      }
    )
  }

  monitorDirty() {
    return reaction(
      () => this.isDirty,
      (isDirty) => {
        if (isDirty) {
          this.getDeliveredMailings()
          this.getSummary()
          this.isDirty = false
          this.isDeliveredMailingsPaginated
            ? (this.isLoading = false)
            : (this.isLoading = true)
        }
      }
    )
  }

  monitorQuickFilters() {
    return reaction(
      () => this.activeQuickFilter,
      (activeQuickFilter) => {
        switch (activeQuickFilter) {
          case 'advanced':
            // Do nothing because filter is already set
            break

          case 'new_mover':
            this.setFilters({
              campaignType: 'New Mover Mail',
              deliveryWeek: {},
              status: 'deliveredInFirstCall',
            })
            break

          case 'mvp_mail':
            this.setFilters({
              campaignType: 'MVP Mail',
              deliveryWeek: {},
              status: 'deliveredInFirstCall',
            })
            break

          case 'all':
          default:
            this.clearFilters()
            this.setFilters({
              status: 'deliveredInFirstCall',
            })
            break
        }
      }
    )
  }

  @action
  dirty(isDirty = true) {
    this.isDirty = isDirty
  }

  @action.bound
  setQuickFilter(activeQuickFilter: string) {
    this.activeQuickFilter = activeQuickFilter
    this.currentPage = 1
  }

  @action.bound
  setDrawer(mailing = null) {
    this.drawer = mailing
  }

  @action.bound
  setCurrentPage(page: number) {
    this.currentPage = page
    this.isDeliveredMailingsPaginated = true
  }

  @action.bound
  setPageSize(pageSize: number) {
    this.pageSize = pageSize
    this.isDeliveredMailingsPaginated = true
    this.currentPage = 1
  }

  @action.bound
  toggleSorting(key: string) {
    if (this.orderBy === key) {
      if (this.dir === 'asc') {
        this.dir = 'desc'
      } else if (this.dir === 'desc') {
        this.orderBy = null
        this.dir = null
      } else {
        this.dir = 'asc'
      }
    } else {
      this.orderBy = key
      this.dir = 'asc'
    }
    this.currentPage = 1
  }

  @action.bound
  setSorting(key: string, dir: string) {
    this.orderBy = key
    this.dir = dir
    this.currentPage = 1
  }

  @action.bound
  setFilters(filters: { [k: string]: any }) {
    this.filters.replace(filters)
    this.currentPage = 1
  }

  @action.bound
  setFilter(key: string, value: any) {
    this.filters.set(key, value)
    this.currentPage = 1
  }

  @action.bound
  clearFilters() {
    this.filters.clear()
    this.currentPage = 1
  }

  @action
  getDeliveredMailings() {
    if (this.cancelFetchDelivered) {
      this.cancelFetchDelivered.cancel()
    }
    const cancelToken = axios.CancelToken
    this.cancelFetchDelivered = cancelToken.source()

    this.isLoadingDeliveredMailings = true
    const options = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sort: this.orderBy,
      dir: this.dir,
      filters: {
        ...this.filters.toJSON(),
        status: 'deliveredInFirstCall',
      },
      cancelToken: this.cancelFetchDelivered,
    }

    getMails(options)
      .then((data: any) => {
        const { meta, records } = data
        this.itemPerPageIdsDelivered.replace(records)
        this.totalCountDelivered = meta.totalCount
      })
      .catch((error: Error) => {
        if (axios.isCancel(error)) {
          console.info('[getDeliveredMailings] cancelled')
          return
        }
        console.error('[getDeliveredMailings] error', error)
        this.fail(error)
      })
      .then(() => {
        this.isLoadingDeliveredMailings = false
        this.isDeliveredMailingsPaginated = false
      })
  }

  @computed
  get itemsPerPageDelivered() {
    return this.itemPerPageIdsDelivered.map((id) => mailingsRepo.get(id))
  }

  @action
  getSummary() {
    if (this.cancelFetchSummary) {
      this.cancelFetchSummary.cancel()
    }
    const cancelToken = axios.CancelToken
    this.cancelFetchSummary = cancelToken.source()

    this.isLoading = true
    const options = {
      filters: this.filters,
      cancelToken: this.cancelFetchSummary,
    }

    getSummary(options)
      .then(({ leads, appointments, conversionRate }: any) => {
        this.previousSummary.leads = this.summary.leads
        this.previousSummary.appointments = this.summary.appointments
        this.previousSummary.conversionRate = this.summary.conversionRate

        this.summary.leads = leads
        this.summary.appointments = appointments
        this.summary.conversionRate = conversionRate
      })
      .catch((error: Error) => {
        if (axios.isCancel(error)) {
          console.info('[MailingStore:getSummary] cancelled')
          return
        }

        console.error('[MailingStore:getSummary] error', error)
        this.fail(error)
      })
      .then(() => {
        this.isLoading = false
      })
  }

  @action
  async approveMail(id: string) {
    return await approveMails(id)
  }

  @action
  async fetchRecentCalls(id: string) {
    let response = await getMailingById(id, {
      recentCalls: true,
    })
    return response.recentCalls
  }

  @computed
  get items() {
    return this.itemIds.map((id) => mailingsRepo.get(id))
  }

  @action
  fail(error: Error) {
    if (error instanceof Error) {
      this.error = error.message
    } else {
      this.error = null
    }
  }

  @action
  cleanStore() {
    this.error = null
    this.itemsPerPage.clear()
    // this.items.clear()
    this.reloadData = false

    this.delivered.clear()
    this.isLoading = false
    this.isLoadingDeliveredMailings = false
    this.isDeliveredMailingsPaginated = false

    this.isDirty = false
    this.itemPerPageIdsDelivered.clear()
    this.totalItemsPerPage = 0
    this.itemIds.clear()
    this.totalItems = 0
    this.totalCountDelivered = 0

    this.pageSize = 15
    this.currentPage = 1
    this.orderBy = 'deliveryWeek'
    this.dir = 'desc'
    this.filters.clear()
    this.error = null

    this.activeQuickFilter = 'all'

    // States for Mailing details drawer
    this.drawer = null

    // States for Quick summary component
    this.previousSummary.leads = 0
    this.previousSummary.appointments = 0
    this.previousSummary.conversionRate = 0

    this.summary.leads = 0
    this.summary.appointments = 0
    this.summary.conversionRate = 0
  }
}

const mailingsStore = new MailingsStore()

export default mailingsStore
