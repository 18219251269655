import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  root: {
    padding: '4px 15px 0px',
  }
})

const HeaderBottom = ({ classes, children }) => {
  return (
    <div className={classes.root}>
      {children}
    </div>
  )
}

export default withStyles(styles)(HeaderBottom)
