import React from 'react'
import { Player } from 'react-media-player'

import Media from 'components/AudioPlayer/Media'
import PlayPause from 'components/AudioPlayer/PlayPause'

interface PlayAudioButtonProps {
  audioDuration: number
  stopAudio: boolean
  audioFile?: string
}

const PlayAudioButton = ({
  audioFile,
  audioDuration,
  stopAudio
}: PlayAudioButtonProps) => (
  <Media>
    <div className="media">
      <div className="media-player">
        <Player src={audioFile} vendor="audio" />
      </div>
      <div className="media-controls">
        <PlayPause callDuration={audioDuration} stopAudio={stopAudio} />
      </div>
    </div>
  </Media>
)

export default PlayAudioButton
