import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  CallReceived as CallReceivedIcon,
  KeyboardArrowRight as CallActionIcon
} from '@material-ui/icons'

import {
  defaultFont
} from 'src/assets/jss/mvp'

import CallsBadge from 'src/components/Calls/CallsBadge'
import { repository as callsRepo } from 'src/models/Call'

const styles = theme => ({
  root: {
    ...defaultFont
  },
  header: {
    paddingLeft: 35,
    paddingRight: 35,
    minHeight: 40,
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    display: 'flex',
    alignItems: 'center',
    color: '#232323',
    fontSize: '16px',
    fontWeight: '400'
  },
  list: {
    padding: 0
  },
  listItem: {
    paddingLeft: 35,
    paddingRight: 35,
    '&:hover $icon': {
      color: theme.palette.primary.main
    }
  },
  listItemButton: {
  },
  icon: {
    color: '#727272',
    marginRight: 0
  },
  textPrimary: {
    ...defaultFont,
    color: '#232323',
    fontSize: '13px',
    fontWeight: '400'
  },
  textSecondary: {
    fontSize: '12px',
    color: '#727272',
    marginTop: 4,
  },
  textIcon: {
    width: 12,
    height: 12
  },
  emptyText: {
    lineHeight: '40px',
    textAlign: 'center',
    color: '#D2D2D2',
    boxShadow: 'inset 0 -1px rgba(0, 0, 0, 0.06)',
    fontSize: '14px'
  }
})

@inject('callsStore')
@withStyles(styles)
@observer
export default class CallDetailsOtherCalls extends Component {

  handleClick = call => () => {
    const { callsStore } = this.props
    callsStore.setDrawer(call)
    call.update()
  }

  render() {
    const { classes, callsStore } = this.props

    const call = callsStore.drawer
    if (!call) {
      return <div />
    }

    const otherCalls = call.relatedCalls.map(id => callsRepo.get(id))

    const callerName = call.getCallerNameMatched(callsStore.searchQueryPattern)

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <span className={classes.headerTitle}>
            Other calls {callerName ? `from ${callerName}` : ''}
          </span>
        </div>
        <List component="nav" className={classes.list}>
          {
            otherCalls.length > 0 ?

              otherCalls.map((call, key) => {
                const dateTime = moment(call.callDateTime)
                return (
                  <ListItem
                    key={key}
                    divider
                    classes={{
                      root: classes.listItem,
                      button: classes.listItemButton
                    }}
                    button
                    onClick={this.handleClick(call)}
                    aria-haspopup="true"
                    aria-controls="view-call"
                    aria-label="View call"
                  >
                    <ListItemIcon>
                      <CallsBadge className={classes.icon} content={call.appointmentsCounter} />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.textPrimary,
                        secondary: classes.textSecondary,
                      }}
                      primary={dateTime.isValid() ? dateTime.format('M/D/YY @h:mm:a') : 'No call date/time'}
                      secondary={
                        <span>
                          <CallReceivedIcon className={classes.textIcon} />
                          {call.answeredBy}
                        </span>
                      }
                    />
                    <CallActionIcon />
                  </ListItem>
                )
              }) :

              <div className={classes.emptyText}>
                No other calls
              </div>
          }
        </List>
      </div>
    )
  }
}
