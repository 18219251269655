import React from 'react'
import cx from 'classnames'

import MailingStatusIcon from './MailingStatusIcon'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '../MVP/MVPTooltip'

import moment from 'moment'

import { defaultFont } from 'src/assets/jss/mvp'

const styles = (theme) => ({
  root: {
    margin: '0 20px 38px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      top: 'calc(50% - 6px)',
      borderBottom: '2px solid #D3D3D3',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '&:hover label': {
      display: 'block',
    },
  },
  tooltip: { opacity: 1 },
  active: {
    '& $iconWrapper': {
      backgroundColor: '#ED154E',
      boxShadow: 'none',
    },

    '& $icon': {
      color: '#fff',
    },

    '& $label': {
      color: '#F22151',
    },
  },

  done: {
    '& $iconWrapper': {
      backgroundColor: '#ED154E',
      boxShadow: 'none',
    },

    '& $icon': {
      color: '#fff',
    },

    '& $label': {
      color: '#3f575e',
    },
  },

  iconWrapper: {
    width: 33,
    height: 33,
    backgroundColor: '#fff',
    borderRadius: '100%',
    boxShadow: 'inset 0 0 0 2px #D3D3D3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    cursor: 'pointer',
  },

  icon: {
    width: 14,
    height: 14,
    color: '#D3D3D3',
  },

  label: {
    ...defaultFont,
    marginTop: 4,
    fontSize: '10px',
    textTransform: 'capitalize',
    color: '#D3D3D3',
  },
  tooltipLeft: {
    left: -18,
    '&:before': {
      left: 35,
    },
  },
  tooltipRight: {
    right: -18,
    '&:before': {
      right: 35,
      transform: 'rotate(180deg)',
    },
  },
  tooltipCenter: {
    right: 'auto !important',
    left: 'auto !important',
    '&:before': {
      left: '50%',
    },
  },
})

const statuses = ['ordered', 'approved', 'printed', 'shipped', 'delivered']

let formatDate = (theDate) => {
  const week = moment(theDate)

  return week.isValid() ? week.format('MM/DD/YYYY') : '-'
}

const MailingStatusSteps = ({ classes, active, ...props }) => {
  let activeIndex = statuses.indexOf(
    active === 'delivered' || active === 'deliveredInFirstCall'
      ? 'delivered'
      : active
  )
  const { mailing } = props

  return (
    <div className={classes.root}>
      {statuses.map((status, index) => (
        <div
          key={status}
          className={cx({
            [classes.wrapper]: true,
            [classes.done]: index < activeIndex,
            [classes.active]: index === activeIndex,
          })}
        >
          <span className={classes.iconWrapper}>
            {index <= activeIndex ? (
              <Tooltip
                className={cx({
                  [classes.tooltipLeft]:
                    status === 'ordered' || status === 'approved',
                  [classes.tooltipRight]:
                    status === 'shipped' || status === 'delivered',
                  [classes.tooltipCenter]: status === 'printed',
                })}
                customStyle={{ top: '-45px', fontSize: 13 }}
                caption={
                  mailing.approvalDate && status === 'approved' ? (
                    <span>
                      The mailing was approved by{' '}
                      <strong>{mailing.approvedBy}</strong> on{' '}
                      <strong>{formatDate(mailing.approvalDate)}</strong>
                    </span>
                  ) : mailing.orderDate && status === 'ordered' ? (
                    <span>
                      The mailing was ordered on{' '}
                      <strong>{formatDate(mailing.orderDate)}</strong>
                    </span>
                  ) : mailing.printDate && status === 'printed' ? (
                    <span>
                      The mailing was printed on{' '}
                      <strong>{formatDate(mailing.printDate)}</strong>
                    </span>
                  ) : mailing.shipmentDate && status === 'shipped' ? (
                    <span>
                      The mailing was shipped on{' '}
                      <strong>{formatDate(mailing.shipmentDate)}</strong>
                    </span>
                  ) : mailing.deliveryDate && status === 'delivered' ? (
                    <span>
                      The mailing was delivered on{' '}
                      <strong>{formatDate(mailing.deliveryDate)}</strong>
                    </span>
                  ) : null
                }
              />
            ) : null}
            <MailingStatusIcon className={classes.icon} status={status} />
          </span>
          <span className={classes.label}>{status}</span>
        </div>
      ))}
    </div>
  )
}

export default withStyles(styles)(MailingStatusSteps)
