import React from 'react'
import cx from 'classnames'
import { useTheme } from '@material-ui/core/styles'
import DetailsIcon from '@material-ui/icons/FiberManualRecord'
import { observer } from 'mobx-react'
import { Typography } from '@material-ui/core'
import ConversionRate from 'components/Mailings/ConversionRate'

import Mailing from 'models/Mailing'
import { useDeliveredMailingCardInfoStyles } from './DeliveredMailingCardInfo.style'

const DeliveredMailingCardInfo: React.FC<{ mailing: Mailing }> = ({
  mailing,
}) => {
  const classes = useDeliveredMailingCardInfoStyles()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <div className={classes.ratePanel}>
        <ConversionRate rate={mailing.conversionRate} size={65} />
      </div>

      <div className={classes.detailsPanel}>
        <div className={`${classes.detail}`}>
          <div className={classes.label}>
            <DetailsIcon
              className={classes.icon}
              style={{ color: theme.palette.primary.main }}
            />
            Apppointments
          </div>

          <div
            className={classes.stats}
            style={{ color: theme.palette.primary.main }}
          >
            {mailing.appointmentsCount}
          </div>
        </div>

        <div className={classes.detail}>
          <span className={classes.leadsContainer}>
            <div className={classes.label}>
              <DetailsIcon className={classes.icon} />
              Leads
            </div>

            <div className={classes.stats}>{mailing.leadsCount}</div>
          </span>
        </div>
      </div>
    </div>
  )
}

export default observer(DeliveredMailingCardInfo)
