import React, {
  Fragment,
  useState,
} from 'react'
import {
  Grid,
  TextField
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Observer } from 'mobx-react'

import styles from './ProfileSettings.styles'
import PasswordSettings from './PasswordSettings'
import { useStores } from '../../config/store'

const ProfileSettings = (props) => {
  const { classes } = props
  const { settingsStore, appState } = useStores()
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')

  const alertOnClick = (message) => () => {
    appState.infoMessage(
      <div>
        <div key="1">{message}</div>
      </div>
    )
  }

  const handleChangeEmail = event => {
    settingsStore.newEmail = event.target.value
    setEmailError(
      settingsStore.email && !settingsStore.newEmail && 'Email is required' || ''
    )
  }

  const handleChangePhone = event => {
    settingsStore.newPhone = event.target.value
    setPhoneError(
      settingsStore.phone && !settingsStore.newPhone && 'Phone number is required' || ''
    )
  }

  return (
    <Observer render={() => (
      <Fragment>
        <h3 className={classes.contentHeader}>Login Settings</h3>
        <Grid item xs={11}>
          <div className={classes.inputRowContainer}>
            <div className={classes.labelContainer}>
              <label>Email Address</label>
            </div>
            <div className={classes.fieldContainer}>
              <TextField
                type='email'
                id='email'
                required
                value={settingsStore.newEmail}
                onChange={handleChangeEmail}
                onClick={alertOnClick('Updating Email will change what email to use for login')}
              />
            </div>
            <div className={classes.error}>{emailError}</div>
          </div>

          <div className={classes.inputRowContainer}>
            <div className={classes.labelContainer}>
              <label>Phone Number</label>
            </div>
            <div className={classes.fieldContainer}>
              <TextField
                type='phone'
                id='phone'
                value={settingsStore.newPhone}
                onChange={handleChangePhone}
                onClick={alertOnClick('Updating Phone number will change what phone number to use for passwordless login')}
              />
            </div>
            <div className={classes.error}>{phoneError}</div>
          </div>
          <PasswordSettings />
        </ Grid>
      </Fragment>
    )} />
  )
}

export default withStyles(styles)(ProfileSettings)
