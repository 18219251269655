import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Fade from '@material-ui/core/Fade'
import MuiMenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import OpenSubmenuIcon from '@material-ui/icons/ChevronRight'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Menu from './Menu'

const propTypes = {
  icon: PropTypes.element,
  menuProps: PropTypes.object,
  checkWhenSelected: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
}


const defaultProps = {
  selected: false,
}

const styles = (theme) => ({
  icon: {
    width: '1em',
    minWidth: '1em'
  },

  label: {
    padding: '0 16px'
  },

  filterItemSelected: {
    color: theme.palette.primary.main
  },

  filterItemIcon: {
    width: 16,
    height: 16,
  },
})

export default
@withStyles(styles)
class MenuItem extends Component {

  constructor(props) {
    super(props)
    this.buttonRef = React.createRef()
    this.state = {
      openSubmenu: false
    }
  }

  handleSelect = callback => () => {
    if (callback) {
      this.setState({
        openSubmenu: false
      })
      callback()
    } else {
      this.setState({
        openSubmenu: true
      })
    }
  }

  handleClose = () => {
    this.setState({
      openSubmenu: false
    })
  }

  renderMenu() {
    const {
      menuProps: {
        ...restMenuProps
      },
    } = this.props

    if (!this.props.menuProps) {
      return null
    }

    const {
      openSubmenu
    } = this.state

    return (
      <Menu
        {...restMenuProps}
        getContentAnchorEl={null}
        anchorEl={this.buttonRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        TransitionComponent={Fade}
        transitionDuration={10}
        open={openSubmenu}
        onClose={this.handleClose}
        style={{
          marginTop: -8,
          marginLeft: 4
        }}
      />
    )
  }

  render() {

    const {
      label,
      menuProps,
      icon,
      selected,
      onClick,
      classes,
      ...restProps
    } = this.props

    return (
      <React.Fragment>
        <MuiMenuItem
          onClick={this.handleSelect(onClick)}
          selected={selected}
          {...restProps}
          button
          buttonRef={this.buttonRef}
        >
          {
            icon ?
              <React.Fragment>
                <ListItemIcon classes={{ root: classes.icon }}>{icon}</ListItemIcon>
                <ListItemText classes={{ root: classes.label }} primary={label} />
              </React.Fragment> : label
          }
          {
            menuProps ?
              <OpenSubmenuIcon style={{ fontSize: 16, color: '#727272' }} /> :
              null
          }
        </MuiMenuItem>
        {this.renderMenu()}
      </React.Fragment>
    )
  }

}

MenuItem.defaultProps = defaultProps
MenuItem.propTypes = propTypes
