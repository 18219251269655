import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import withTheme from '@material-ui/core/styles/withTheme'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'

import {
  FilterList as FilterIcon,
  SortByAlpha as SortIcon,
  Check as CheckIcon
} from '@material-ui/icons'

import Menu from 'src/components/MVP/Menus/Menu'
import MailingsAdvancedFilters from './MailingsAdvancedFilters'

const QUICK_FILTER_ITEMS = [
  {
    key: 'all',
    label: 'All Mailings',
  },
  {
    key: 'new_mover',
    label: 'New Mover Mailings',
  },
  {
    key: 'mvp_mail',
    label: 'MVP Mailings',
  }
]

const SORT_OPTIONS = [
  {
    key: 'deliveryWeek',
    label: 'Mailing Date',
    asc: 'Oldest',
    desc: 'Newest'
  },
  {
    key: 'leadsCount',
    label: 'Leads',
    asc: 'Lowest',
    desc: 'Highest'
  },
  {
    key: 'appointmentsCount',
    label: 'Appointments',
    asc: 'Lowest',
    desc: 'Highest'
  },
  {
    key: 'conversionRate',
    label: 'Conversion Rate',
    asc: 'Lowest',
    desc: 'Highest'
  }
]

const styles = theme => ({
  root: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    color: '#D3D3D3',
    fontSize: '14px',
    margin: 0
  },
  label: {
    marginRight: 25,
    fontSize: '14px'
  },
  filterItem: {
    textTransform: 'none',
    padding: '8px 16px'
  },
  filterItemDisabled: {
    opacity: 0.25
  },
  filterItemLabel: {
    color: '#141414',
  },
  filterItemIcon: {
    width: 16,
    height: 16,
  },
  filterItemSelected: {
    color: theme.palette.primary.main
  },
  filterItemText: {
  },
  advanced: {
    marginLeft: 10,
    borderColor: 'rgb(220,220,220)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0px',
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: '#fff',
    color: '#141414',
    '&$filterItemSelected': {
      borderColor: 'rgb(242,242,242)',
      color: theme.palette.primary.main,
      backgroundColor: 'rgb(242,242,242)'
    },
    '&$filterItemSelected $icon': {
      color: theme.palette.primary.main,
    },
    '&$filterItemSelected span': {
      color: theme.palette.primary.main,
    },
    '& span': {
      color: '#141414',
    },
    '&:hover': {
    }
  },
  icon: {
    marginRight: 6,
    width: '0.7em',
    height: '0.7em'
  }
})

@inject('mailingsStore')
@withTheme
@withStyles(styles)
@observer
class MailingsQuickFilters extends Component {

  constructor(props) {
    super(props)
    this.refAdvancedFilters = React.createRef()
    this.refQuickFilters = React.createRef()
    this.refSort = React.createRef()
    this.refSortSubMenus = {}

    const sortSubmenus = {}
    SORT_OPTIONS.forEach(option => {
      this.refSortSubMenus[option.key] = React.createRef()
      sortSubmenus[option.key] = false
    })

    this.state = {
      showAdvancedFilter: false,
      sortMenu: false,
      quickFiltersMenu: false,
    }
  }

  setActiveFilter = activeFilter => () => {
    const { setQuickFilter } = this.props.mailingsStore
    setQuickFilter(activeFilter)
  }

  toggleAdvancedFilter = showAdvancedFilter => () => {
    this.setState({
      showAdvancedFilter
    })
  }

  handleOpenMenu = menu => () => {
    this.setState({ [`${menu}Menu`]: true })
  }

  handleCloseMenu = menu => () => {
    this.setState({ [`${menu}Menu`]: false })
  }

  handleSelectQuickFilter = key => () => {
    const { mailingsStore } = this.props
    mailingsStore.setQuickFilter(key)
    this.setState({ quickFiltersMenu: false })
  }

  handleSelectSort = (key, dir) => () => {
    const { mailingsStore } = this.props
    mailingsStore.setSorting(key, dir)
    this.setState({ sortMenu: false })
  }

  sortingLabel(orderBy, dir) {
    const item = SORT_OPTIONS.reduce((prev, curr) => curr.key === orderBy ? curr : prev, null)
    let label = (item.label ? `${item.label}` : '')
    if (dir === 'desc') {
      label += ` - ${item.desc} to ${item.asc}`
    } else if (dir === 'asc') {
      label += ` - ${item.asc} to ${item.desc}`
    }
    return label
  }

  renderSortMenu() {
    const {
      classes,
      mailingsStore: {
        orderBy: currentOrderBy,
        dir: currentDir
      },
    } = this.props

    const { sortMenu } = this.state

    return (
      <Menu
        anchor={this.refSort.current}
        open={sortMenu}
        onClose={this.handleCloseMenu('sort')}
        items={SORT_OPTIONS.map(item => {
          let sortDir = currentDir
          if (currentOrderBy !== item.key) {
            sortDir = null
          }
          const isSelected = currentOrderBy === item.key &&
            sortDir === currentDir
          const label = this.sortingLabel(item.key, sortDir)
          return {
            selected: isSelected,
            icon: <CheckIcon
              style={{
                visibility: isSelected ? null : 'hidden'
              }}
            />,
            label: <span className={isSelected ? classes.filterItemSelected : null}>{label}</span>,
            menuProps: {
              items: ['desc', 'asc'].map(itemDir => {
                const itemLabel = this.sortingLabel(item.key, itemDir)
                const itemSelected = currentOrderBy === item.key && currentDir === itemDir
                return  {
                  onClick: this.handleSelectSort(item.key, itemDir),
                  selected: isSelected,
                  label: <span className={itemSelected ? classes.filterItemSelected : null}>
                    {itemLabel}
                  </span>,
                }
              })
            }
          }
        })}
      />
    )
  }

  renderQuickFiltersMenu() {
    const {
      classes,
      mailingsStore: { activeQuickFilter },
    } = this.props

    const { quickFiltersMenu } = this.state

    return (
      <Menu
        anchor={this.refQuickFilters.current}
        open={quickFiltersMenu}
        onClose={this.handleCloseMenu('quickFilters')}
        items={QUICK_FILTER_ITEMS.map(item => {
          const isSelected = activeQuickFilter === item.key
          return {
            ...item,
            onClick: this.handleSelectQuickFilter(item.key),
            selected: isSelected,
            icon: <CheckIcon
              className={cx(
                classes.filterItemSelected,
                classes.filterItemIcon
              )}
              style={{
                visibility: isSelected ? null : 'hidden'
              }}
            />,
            label: <span
              className={isSelected ? classes.filterItemSelected : null}
            >
              {item.label}
            </span>,
          }
        })}
      />
    )
  }

  render() {
    const {
      classes,
      mailingsStore: {
        activeQuickFilter
      },
      theme
    } = this.props

    const {
      showAdvancedFilter,
      sortMenu,
      quickFiltersMenu
    } = this.state

    return (
      <div className={classes.root}>
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: sortMenu
            }),
          }}
          onClick={this.handleOpenMenu('sort')}
          buttonRef={this.refSort}
        >
          <SortIcon className={classes.icon} />
          Sort
        </Button>
        {this.renderSortMenu()}
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: quickFiltersMenu
            }),
          }}
          onClick={this.handleOpenMenu('quickFilters')}
          buttonRef={this.refQuickFilters}
        >
          <FilterIcon className={classes.icon} />
          Quick Filters
        </Button>
        {this.renderQuickFiltersMenu()}
        <Button
          classes={{
            root: cx(
              classes.filterItem,
              classes.advanced,
              {
                [classes.filterItemSelected]: activeQuickFilter === 'advanced'
              }
            ),
            label: classes.filterItemLabel
          }}

          color={activeQuickFilter === 'advanced' ? 'primary' : undefined}
          onClick={this.toggleAdvancedFilter(true)}
          buttonRef={this.refAdvancedFilters}
        >
          <FilterIcon className={classes.icon} />
          Advanced Filters
        </Button>
        <Popover
          open={showAdvancedFilter}
          onClose={this.toggleAdvancedFilter(false)}
          anchorEl={this.refAdvancedFilters.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              overflowY: 'hidden',
              width: 290
            }
          }}
        >
          <MailingsAdvancedFilters onClose={this.toggleAdvancedFilter(false)} />
        </Popover>
      </div>
    )
  }

}

export default MailingsQuickFilters
