const styles = (theme) => ({
  inputRowContainer: {
    display: 'flex',
    marginBottom: '20px',
  },

  fieldContainer: {
    flexBasis: '82%',
    '& > div': {
      width: '100%',
    },
    '& input': {
      backgroundColor: 'transparent',
      fontWeight: '400',
      borderColor: '#d3d3d3',
      fontFamily: 'Roboto Condensed',
      color: '#141414',
    },
  },

  labelContainer: {
    flexBasis: '18%',
    display: 'flex',
    alignItems: 'center',
    '& label': {
      color: '#141414',
      fontFamily: 'Roboto Condensed',
    },
  },

  error: {
    color: '#F50D0D',
    fontStyle: 'italic',
    flexBasis: '57.1%',
    fontFamily: 'Roboto Condensed',
    fontSize: '13px',
    position: 'relative',
    left: '13px',
    lineHeight: '1.2',
    display: 'flex',
    alignItems: 'center',
  },

  errorPassStrength: {
    position: 'relative',
    flexBasis: '57.1%',
    '& > div': {
      display: 'block',
      position: 'absolute',
    },
    '& ul': {
      marginTop: 0,
    },
  },

  errorPartialPass: {
    color: '#4caf50',
  },

  confirmSection: {
    textAlign: 'right',
    paddingRight: '36.5%',
    '& > button:first-child': {
      marginRight: '8px',
      borderRadius: 0,
    },
  },

  spinner: {
    marginRight: 8,
    color: '#333',
    opacity: 0.9,
  },
})

export default styles
