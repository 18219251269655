import { makeStyles, Theme } from '@material-ui/core'

export const useMailingCardStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    userSelect: 'none',
    flex: '0 0 20%',
    minWidth: 283,
    maxWidth: 300,
    '@media (min-width: 1600px)': {
      width: '330px',
    },
  },

  card: {
    width: '100%',
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16)',
    paddingTop: 16,
    marginRight: 20,
    marginBottom: 20,
    '@media (min-width: 1600px)': {
      marginRight: 24,
      marginBottom: 24,
    },
  },

  details: {
    paddingTop: 0,
  },

  header: {
    padding: 9,
    paddingTop: 0,
  },

  actions: {},
}))
