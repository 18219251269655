import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

const styles = (theme) => ({})

const ConfirmDialog = ({
  title,
  children,
  onCancel,
  onConfirm,
  hideActions,
  ...props
}) => (
  <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" {...props}>
    {title ? (
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    ) : null}
    <DialogContent>{children}</DialogContent>
    {!hideActions && (
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    )}
  </Dialog>
)

export default withStyles(styles)(ConfirmDialog)
