import _get from 'lodash/get'
import _uniq from 'lodash/uniq'
import API, { MAPS_API } from 'src/helpers/api'
import Account, { repository as accountRepo } from 'src/models/Account'

/**
 *
 */
const getAccount = async (id) => {
  const result = await API.get({
    api: MAPS_API,
    url: `/accounts/${id}`,
  })

  if (!result) {
    return undefined
  }

  const account = new Account()
  account.id = result.id
  account.name = result.name
  account.color = result.color
  account.locationLatitude = result.locationLatitude
  account.locationLongitude = result.locationLongitude
  account.address = result.address

  return accountRepo.upsert(account)
}

export default getAccount
