import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import _range from 'lodash/range'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import DatePicker from 'src/components/MVP/Pickers/MVPDatePicker'
import Select from 'src/components/MVP/MVPSelect'
import Appointment from 'src/models/Appointment'

const propTypes = {

}

const defaultProps = {

}

const styles = theme => ({
  gridContainer: {
    padding: '20px 35px'
  },
  gridItem: {
    display: 'flex'
  },
  textField: {

  },
  button: {
    textTransform: 'none',
    width: 120,
    marginRight: 8,
    '&:last-of-type': {
      marginRight: 0
    }
  }
})

@inject('callsStore', 'configStore')
@withStyles(styles)
@observer
export default class AppointmentForm extends Component {

  state = {
    error: {},
    confirm: false,
    firstName: '',
    lastName: '',
    appointmentDate: '',
    appointmentTime: '700' // 7:00 AM is the
  }

  handleChange = id => event  => {
    this.setState({ [id]: event.target.value })
  }

  handleDateChange = id => date  => {
    this.setState({ [id]: date })
  }

  handleSelectChange = id => value => {
    this.setState({
      [id]: value && value.value || ''
    })
  }

  handleCancel = () => {
    const { onClose } = this.props
    onClose() // Close the form
  }

  validate() {
    const { firstName, lastName, appointmentDate, appointmentTime } = this.state
    const error = {}

    if (!firstName) {
      error.firstName = 'Patient first name is required'
    }

    if (!lastName) {
      error.lastName = 'Patient last name is required'
    }

    if (!appointmentDate) {
      error.appointmentDate = 'Appointment date is required'
    }

    if (!appointmentTime) {
      error.appointmentTime = 'Appointment time is required'
    }

    if (Object.keys(error).length) {
      this.setState({ error })
      throw new Error('Validation error')
    }

    return true
  }

  handleSave = () => {
    const { callsStore, onClose } = this.props
    const call = callsStore.drawer

    // Sanity check
    if (!call) {
      throw new Error('No call selected in Call Details Drawer')
    }

    try {
      this.validate()
    } catch (e) {
      return
    }

    const {
      firstName,
      lastName,
      appointmentDate,
      appointmentTime
    } = this.state

    const appointment = new Appointment({
      id: `${Date.now()}${Math.floor(Math.random() * 1000)}`
    })
    // Assign a temporary ID
    appointment.firstName = firstName
    appointment.lastName = lastName

    const h = appointmentTime / 100
    const m = appointmentTime % 100
    const dt = moment(appointmentDate).set({ h, m, s:0})

    appointment.dateTime = dt.format()

    call.createAppointment(appointment)
    onClose() // Close the form
  }

  render() {
    const { classes, configStore } = this.props
    const { error } = this.state
    return (
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={6} className={classes.gridItem}>
          <TextField
            id="firstName"
            label="First Name"
            error={!!error.firstName}
            helperText={error.firstName}
            className={classes.textField}
            value={this.state.firstName}
            onChange={this.handleChange('firstName')}
            fullWidth
            margin="none"
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <TextField
            id="lastName"
            label="Last Name"
            className={classes.textField}
            error={!!error.lastName}
            helperText={error.lastName}
            value={this.state.lastName}
            onChange={this.handleChange('lastName')}
            fullWidth
            margin="none"
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <DatePicker
            id="appointmentDate"
            label="Date"
            value={this.state.appointmentDate}
            onChange={this.handleDateChange('appointmentDate')}
            inputProps={{
              error: !!error.appointmentDate,
              helperText: error.appointmentDate
            }}
            pickerProps={{
            }}
          />
        </Grid>
        <Grid item xs={6} className={classes.gridItem}>
          <Select
            label="Time"
            placeholder="Select time slot"
            value={this.state.appointmentTime}
            menuPlacement="auto"
            maxMenuHeight={200}
            fullWidth
            options={_range(12 * 4 + 1).map(i => {
              const momentVal = moment().startOf('day').add(i * 15 + 7*60, 'minute')
              const label = momentVal.format(configStore.timeFormat)
              const value = momentVal.format('Hmm')
              return {
                label,
                value
              }
            })}
            onChange={this.handleSelectChange('appointmentTime')}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridItem} style={{ marginTop: 12, justifyContent: 'flex-end' }}>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={this.handleCancel}
          >
            Cancel
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={this.handleSave}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    )
  }

}

AppointmentForm.propTypes = propTypes
AppointmentForm.defaultProps = defaultProps

