import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'

import {
  FilterList as FilterListIcon
} from '@material-ui/icons'

import CallsAdvancedFilters from 'src/components/Calls/CallsAdvancedFilters'

import Select from '../MVP/MVPSelect'

const styles = theme => ({
  root: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    color: '#D3D3D3',
    fontSize: '14px',
    margin: 0
  },
  label: {
    marginRight: 25,
    fontSize: '14px'
  },
  filterItem: {
    textTransform: 'none',
    minWidth: 0,
    marginRight: 5,
    borderRadius: 0,
    '&:last-of-type': {
      marginRight: 0
    },
    '&:hover': {
      backgroundColor: '#fde7ed'
    },
    padding: '8px 16px'
  },
  filterItemDisabled: {
    opacity: 0.25
  },
  filterItemLabel: {
    color: '#141414',
  },
  filterItemSelected: {
    color: '#F22151'
  },
  advanced: {
    marginLeft: 10,
    borderColor: 'rgb(220,220,220)',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0px',
    paddingLeft: 10,
    paddingRight: 15,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: '#fff',
    color: '#141414',
    '&$filterItemSelected': {
      borderColor: 'rgb(242,242,242)',
      color: theme.palette.primary.main,
      backgroundColor: 'rgb(242,242,242)'
    },
    '&$filterItemSelected $advancedIcon': {
      color: theme.palette.primary.main,
    },
    '&$filterItemSelected span': {
      color: theme.palette.primary.main,
    },
    '& span': {
      color: '#141414',
    },
    '&:hover': {
    }
  },
  advancedIcon: {
    marginRight: 6,
    width: '0.7em',
    height: '0.7em'
  }
})

@inject('callsStore', 'appState', 'accountStore')
@withStyles(styles)
@observer
class CallsQuickFilters extends Component {

  constructor(props) {
    super(props)
    this.refAdvancedFilters = React.createRef()
    this.state = {
      showAdvancedFilter: false
    }
  }

  setActiveFilter = activeFilter => () => {
    const { setQuickFilter } = this.props.callsStore
    setQuickFilter(activeFilter)
  }

  toggleAdvancedFilter = showAdvancedFilter => () => {
    this.setState({
      showAdvancedFilter
    })
  }

  render() {
    const {
      classes,
      callsStore: {
        activeQuickFilter
      },
      appState,
      accountStore
    } = this.props

    const {
      showAdvancedFilter
    } = this.state

    const accounts = appState.accounts.map(({id: value, name: label}) => ({label, value}))
    const customerOptions = [
      { label: 'No Accounts Selected', value: null },
      ...accounts,
      {
        label: accounts.map(({label}) => label).join(' + ') + ' Accounts',
        value: accounts.map(({value}) => value).join(',')
      }
    ]

    return (
      <div className={classes.root}>
        <span className={classes.label}>Quick Filters:</span>
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: activeQuickFilter === 'opportunities'
            }),
            disabled: classes.filterItemDisabled
          }}
          onClick={this.setActiveFilter('opportunities')}>
          Opportunities
        </Button>
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: activeQuickFilter === 'appointments'
            }),
          }}
          onClick={this.setActiveFilter('appointments')}>
          Appointments
        </Button>
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: activeQuickFilter === 'missed'
            }),
          }}
          onClick={this.setActiveFilter('missed')}>
          Missed
        </Button>
        <Button
          classes={{
            root: classes.filterItem,
            label: cx(classes.filterItemLabel, {
              [classes.filterItemSelected]: activeQuickFilter === 'all'
            }),
          }}
          onClick={this.setActiveFilter('all')}>
          All
        </Button>
        <Button
          classes={{
            root: cx(
              classes.filterItem,
              classes.advanced,
              {
                [classes.filterItemSelected]: activeQuickFilter === 'advanced'
              }
            ),
            label: classes.filterItemLabel
          }}

          color={activeQuickFilter === 'advanced' ? 'primary' : undefined}

          onClick={this.toggleAdvancedFilter(true)}
          buttonRef={this.refAdvancedFilters}
        >
          <FilterListIcon className={classes.advancedIcon} />
          Advanced Filters
        </Button>
        <Popover
          open={showAdvancedFilter}
          onClose={this.toggleAdvancedFilter(false)}
          anchorEl={this.refAdvancedFilters.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              overflowY: 'hidden',
              width: 290
            }
          }}
        >
          <CallsAdvancedFilters onClose={this.toggleAdvancedFilter(false)} />
        </Popover>
      </div>
    )
  }

}

export default CallsQuickFilters
