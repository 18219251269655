import React, {
  Fragment
} from 'react'
import {
  Grid,
  Checkbox
} from '@material-ui/core'
import { Observer } from 'mobx-react'
import validator from 'validator'
import withStyles from '@material-ui/core/styles/withStyles'

import CustomChipInput from 'src/components/Chips/CustomChipInput'
import Tooltip from '../MVP/MVPTooltip'
import styles from './AlertSettings.styles'
import settingsStore from '../../config/store/SettingsStore'

const AlertSettings = (props) => {
  const { classes } = props

  const validateEmail = (email) => validator.isEmail(email)

  const addEmail = (email) => {
    settingsStore.newMissedCallAlertRecipients = email
  }

  const handleReceiveSmsEmailCheckbox = event => {
    settingsStore.newReceiveSmsEmail = event.target.checked
  }

  const handleReceiveSmsMobileCheckbox = event => {
    settingsStore.newReceiveSmsMobile = event.target.checked
  }

  const handleMissedCallsEmailCheckbox = event => {
    settingsStore.newMissedCallsEmail = event.target.checked
  }

  const handleMissedCallsMobileCheckbox = event => {
    settingsStore.newMissedCallsMobile = event.target.checked
  }

  let newMissedCallAlertRecipients = []
  const tooltipPosition = {
    top: '-35px',
    left: '24px'
  }

  if(settingsStore.isLoading === false) {
    settingsStore.newMissedCallAlertRecipients.toJS().map((email) => {
      newMissedCallAlertRecipients.push(email)
    })
  }

  return (
    <Observer render={() => (
      <Fragment>
        <Grid item xs={7} style={{marginTop: '65px'}}>
          <div className={classes.row}>
            <div className={classes.col}>
              <h4 className={classes.heading}>Alert Settings</h4>
            </div>
            <div className={classes.col}>
              <h4>Email</h4>
            </div>
            <div className={classes.col}>
              <h4>Mobile</h4>
            </div>
          </div>
        </Grid>
        <hr className={classes.separator}/>
        <Grid container>
          <Grid item xs={7}>
            <div className={classes.row}>
              <div className={classes.col}>
                <label>Received SMS</label>
              </div>
              <div className={classes.col}>
                <div className={classes.checkboxWrapper}>
                  { ! settingsStore.newReceiveSmsEmail ?
                    <span className={classes.checkboxInactive} /> : null }
                  <Checkbox
                    className={`${classes.checkbox} ${ ! settingsStore.newReceiveSmsEmail ? classes.cbDisabled : null}`}
                    color="primary"
                    checked={settingsStore.newReceiveSmsEmail}
                    onChange={handleReceiveSmsEmailCheckbox}
                  />
                  <Tooltip
                    customStyle={tooltipPosition}
                    caption={<span>Check to receive <strong>alerts on email</strong> anytime and <strong>SMS is sent</strong> to one of your tracking numbers</span>}/>
                </div>
              </div>
              <div className={classes.col}>
                <div className={classes.checkboxWrapper}>
                  { ! settingsStore.newReceiveSmsMobile ?
                    <span className={classes.checkboxInactive} /> : null }
                  <Checkbox
                    className={`${classes.checkbox} ${ ! settingsStore.newReceiveSmsMobile ? classes.cbDisabled : null}`}
                    color="primary"
                    checked={settingsStore.newReceiveSmsMobile}
                    onChange={handleReceiveSmsMobileCheckbox}
                  />
                  <Tooltip
                    customStyle={tooltipPosition}
                    caption={<span>Check to receive <strong>alerts on mobile</strong> anytime on <strong>SMS is sent</strong> to one of your tracking numbers</span>}/>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.col}>
                <label>Missed Calls</label>
              </div>
              <div className={classes.col}>
                <div className={classes.checkboxWrapper}>
                  { ! settingsStore.newMissedCallsEmail ?
                    <span className={classes.checkboxInactive} /> : null }
                  <Checkbox
                    className={`${classes.checkbox} ${ ! settingsStore.newMissedCallsEmail ? classes.cbDisabled : null}`}
                    color="primary"
                    checked={settingsStore.newMissedCallsEmail}
                    onChange={handleMissedCallsEmailCheckbox}
                  />
                  <Tooltip
                    customStyle={tooltipPosition}
                    caption={<span>Check to receive <strong>alerts on email</strong> anytime a <strong>call is placed</strong> to one of your tracking numbers, but it <strong>isn't answered</strong> by your staff</span>}/>
                </div>
              </div>
              <div className={classes.col}>
                <div className={classes.checkboxWrapper}>
                  { ! settingsStore.newMissedCallsMobile ?
                    <span className={classes.checkboxInactive} /> : null }
                  <Checkbox
                    className={`${classes.checkbox} ${ ! settingsStore.newMissedCallsMobile ? classes.cbDisabled : null}`}
                    color="primary"
                    checked={settingsStore.newMissedCallsMobile}
                    onChange={handleMissedCallsMobileCheckbox}
                  />
                  <Tooltip
                    customStyle={tooltipPosition}
                    caption={<span>Check to receive <strong>alerts on mobile</strong> anytime a <strong>call is placed</strong> to one of your tracking numbers, but it <strong>isn't answered</strong> by your staff</span>}/>
                </div>
              </div>
            </div>

            <div className={classes.emails}>
              <label className={classes.emailsLabel}>Missed Call<br/>Alert Recipients</label>
              <CustomChipInput
                key={settingsStore.newMissedCallAlertRecipients.toJS()}
                defaultValue={settingsStore.newMissedCallAlertRecipients.toJS()}
                onChange={(chip) => {addEmail(chip)}}
                className={classes.chipContainer}
                newChipKeyCodes={[9, 188, 13, 32]}
                onBeforeAdd={(chip) => validateEmail(chip)}
                placeholder={'Enter new email'}
              />
            </div>
          </Grid>
          <Grid item xs={5}>
            <p className={classes.note}><strong>Note</strong>: Check the boxes to receive email alerts or mobile push notifications on your smartphone or tablet.</p>
          </Grid>
        </Grid>
      </Fragment>
    )} />
  )
}

export default withStyles(styles)(AlertSettings)