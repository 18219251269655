import ApiHelper, { IDENTITY_API } from 'src/helpers/api'

/**
 * Returns the list of related accounts for this user
 *
 */
const getRelatedAccounts = async (options) => {

  const response = await ApiHelper.get({
    api: IDENTITY_API,
    url: '/user/relatedAccounts',
    ...(options || {}),
    skipAuthentication: true
  })
  return response
}

export default getRelatedAccounts
