import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { withStyles, Theme } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import _throttle from 'lodash/throttle'
import queryString from 'query-string'

import { useStores } from 'config/store'

const styles = (_theme: Theme) => ({
  callSearchBar: {
    paddingRight: 15,
    marginLeft: 'auto',
    '& .MuiInputAdornment-root': {
      color: '#8D8D8D',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: -32,
    },
    '& .MuiInputAdornment-positionEnd:hover': {
      cursor: 'pointer',
    },
    '& .MuiInput-input': {
      padding: '9px 36px',
    },
  },
})

const CallSearch: React.FC = ({ classes }: any) => {
  const history = useHistory()
  const { callsStore } = useStores()
  const [searchQuery, setSearchQuery] = useState(callsStore.searchQuery)
  const delayedSearch = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (history.location.pathname === '/calls') {
      setSearchQuery('')
      callsStore.clearSearch()
      return
    }

    if (history.location.pathname === '/calls/search') {
      const { query } = queryString.parse(history.location.search)

      if (typeof query === 'string' && callsStore.searchQuery !== query) {
        setSearchQuery(query)
        callsStore.searchCalls(query)
      } else {
        setSearchQuery(callsStore.searchQuery)
      }

      return
    }
  }, [history.location.pathname, history.location.search])

  const runSearch = (query: string) => () => {
    delayedSearch.current = null

    if (!query) {
      callsStore.clearSearch()
      history.push(`/calls`)
    }

    if (!/[\w\d]/g.test(query)) {
      return
    }

    callsStore.searchCalls(query)
    history.push(`/calls/search?query=${encodeURIComponent(query)}`)
  }

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (delayedSearch.current !== null) {
      clearTimeout(delayedSearch.current)
      delayedSearch.current = null
    }

    delayedSearch.current = (setTimeout(
      runSearch(e.target.value),
      1200
    ) as unknown) as NodeJS.Timeout

    setSearchQuery(e.target.value)
  }

  const handleClearSearchClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    callsStore.clearSearch()
    history.push('/calls')
  }

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 27 && history.location.pathname === '/calls/search') {
      callsStore.clearSearch()
      history.push('/calls')
    }
  }

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    runSearch(searchQuery)()
  }

  return (
    <div className={classes.callSearchBar}>
      <form onSubmit={handleFormSubmit}>
        <TextField
          autoFocus={history.location.pathname === '/calls/search'}
          placeholder="search name or number"
          onChange={handleSearchQueryChange}
          onKeyDown={handleKeydown}
          value={searchQuery}
          type="text"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: searchQuery && (
              <InputAdornment position="end" onClick={handleClearSearchClick}>
                <CloseIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  )
}

export default observer(withStyles(styles as any)(CallSearch))
