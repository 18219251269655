import {
  observable,
  action,
  computed,
  reaction,
  toJS,
  IReactionDisposer,
} from 'mobx'

import getCalls from 'services/calls/getCalls'
import Call, { repository as callsRepo } from 'models/Call'
import accountStore from 'config/store/AccountStore'
import appState from 'config/store/AppState'
import { getOutcomeStatus, outcomeReducer } from '../../helpers/callOutcome'

export class CallsStore {
  @observable isOnCallsPages = false
  @observable isLoading = false
  @observable error?: string | null = null

  @observable isDirty = false

  itemPerPageIds = observable.array<string>([])

  @observable totalItemsPerPage = 0
  itemIds = observable.array<string>([])
  @observable totalItems = 0

  @observable searchQuery: string
  @observable pageSize = 15
  @observable currentPage = 1
  @observable orderBy: string | null = 'callDateTime'
  @observable dir: string | null = 'desc'
  @observable sort: string | null = null

  filters = observable.map(
    new Map<string, any>([['opportunity', true]])
  )

  @observable activeQuickFilter = 'opportunities'
  // @observable advanceFilterVisible = false

  // States for Call details drawer
  @observable drawer: Call | null = null

  // States for Quick summary component
  @observable previousSummary = {
    countAll: 0,
    countMissedCalls: 0,
    countAppointments: 0,
    countCancelledAppointments: 0,
  }

  @observable summary = {
    countAll: 0,
    countMissedCalls: 0,
    countAppointments: 0,
    countCancelledAppointments: 0,
  }

  queriesHandler: IReactionDisposer
  dirtyHandler: IReactionDisposer
  quickFiltersHandler: IReactionDisposer

  constructor() {
    const url = new URL(window.location.href)
    const searchQuery = url.searchParams.get('query')

    this.searchQuery = searchQuery ?? ''
    this.queriesHandler = this.monitorQueries()
    this.dirtyHandler = this.monitorDirty()
    this.quickFiltersHandler = this.monitorQuickFilters()
    this.monitorSelectedAccounts()

    // Start loading
    this.fetchAll()
    this.refreshPage()
  }

  monitorSelectedAccounts() {
    return reaction(
      () => accountStore.selectedAccounts.slice(),
      () => {
        this.dirty(true)
      }
    )
  }

  monitorQueries() {
    return reaction(
      () => ({
        page: this.currentPage,
        pageSize: this.pageSize,
        orderBy: this.orderBy,
        dir: this.dir,
        filters: toJS(this.filters),
      }),
      () => {
        this.dirty(true)
      }
    )
  }

  monitorDirty() {
    return reaction(
      () => this.isDirty,
      (isDirty) => {
        if (isDirty) {
          this.fetchAll()
          this.refreshPage()
          this.isDirty = false
        }
      }
    )
  }

  monitorQuickFilters() {
    return reaction(
      () => this.activeQuickFilter,
      (activeQuickFilter) => {
        switch (activeQuickFilter) {
          case 'advanced':
            // Do nothing because filter is already set
            break

          case 'opportunities':
            this.setFilters({
              opportunity: true,
            })
            break

          case 'appointments':
            this.setFilters({
              outcome: 'Appointment',
            })
            break

          case 'missed':
            this.setFilters({
              outcome: 'Missed Call',
              opportunity: true,
            })
            break

          case 'all':
          default:
            this.clearFilters()
            break
        }
      }
    )
  }

  @action
  dirty(isDirty: boolean) {
    this.isDirty = isDirty
  }

  @action
  setMailingFilter(mailingId: string) {
    this.setFilters({
      mailing: mailingId,
    })
    this.currentPage = 1
  }

  @action.bound
  setQuickFilter(activeQuickFilter: string) {
    this.activeQuickFilter = activeQuickFilter
    this.currentPage = 1
  }

  @action.bound
  setDrawer(call: Call) {
    this.drawer = call
  }

  @action.bound
  setCurrentPage(page: number) {
    this.currentPage = page
  }

  @action.bound
  setPageSize(pageSize: number) {
    this.currentPage = 1
    this.pageSize = pageSize
  }

  @action.bound
  toggleSorting(key: string) {
    if (this.orderBy === key) {
      if (this.dir === 'asc') {
        this.dir = 'desc'
      } else if (this.dir === 'desc') {
        this.orderBy = null
        this.dir = null
      } else {
        this.dir = 'asc'
      }
    } else {
      this.orderBy = key
      this.dir = 'asc'
    }
    this.currentPage = 1
  }

  @action.bound
  setFilters(filters: { [k: string]: any }) {
    this.filters.replace(filters)
    this.currentPage = 1
  }

  @action.bound
  setFilter(key: string, value: any) {
    this.filters.set(key, value)
    this.currentPage = 1
  }

  @action.bound
  clearFilters() {
    this.filters.clear()
    this.currentPage = 1
  }

  @action
  refreshPage() {
    this.isLoading = appState.isRestricted() ? false : true
    const options = {
      page: this.currentPage,
      pageSize: this.pageSize,
      sort: this.orderBy,
      dir: this.dir,
      filters: this.filters,
      searchQuery: <string | undefined>undefined,
    }

    if (this.searchQueryNormalized) {
      options.searchQuery = this.searchQueryNormalized
    }
    if (!appState.isRestricted()) {
      getCalls(options)
        .then((data: any) => {
          const { meta, records } = data

          this.itemPerPageIds.replace(records)
          this.totalItemsPerPage = meta.totalCount
        })
        .catch((error: Error) => {
          console.error('[refreshPage] error', error)
          this.fail(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }

  @computed
  get itemsPerPage() {
    return this.itemPerPageIds.map((id) => callsRepo.get(id))
  }

  @action
  fetchAll() {
    this.isLoading = true
    const options = {
      page: 1,
      pageSize: 2000,
      sort: this.sort,
      dir: this.dir,
      filters: this.filters,
      searchQuery: <string | undefined>undefined,
    }

    if (this.searchQueryNormalized) {
      options.searchQuery = this.searchQueryNormalized
    }

    if (!appState.isRestricted()) {
      getCalls(options)
        .then((data: any) => {
          const { meta, records } = data
          this.itemIds.replace(records)
          this.totalItems = meta.totalCount

          this.previousSummary.countAll = this.summary.countAll
          this.previousSummary.countMissedCalls = this.summary.countMissedCalls
          this.previousSummary.countAppointments = this.summary.countAppointments
          this.previousSummary.countCancelledAppointments = this.summary.countCancelledAppointments

          // Recompute summary
          let countAll = this.items.length
          let countAppointments = 0
          let countMissedCalls = 0
          let countCancelledAppointments = 0

          records.forEach((id: string) => {
            const call = callsRepo.get(id)
            const appointmentsCount = call.appointmentsCounter
            const appt = call.appointments.reduce(outcomeReducer, undefined)
            const label = getOutcomeStatus(appt, appointmentsCount)
            if(label === 'Cancelled') {
              countCancelledAppointments += 1
            }

            countAppointments += appointmentsCount || 0
            const answeredBy = call.callAnsweredBy || ''

            if (
              ['voicemail', 'not answered'].includes(answeredBy.toLowerCase())
            ) {
              countMissedCalls++
            }
            if (this.drawer && this.drawer.id === id) {
              this.drawer = call
            }
          })

          this.summary.countAll = countAll
          this.summary.countMissedCalls = countMissedCalls
          this.summary.countAppointments = countAppointments
          this.summary.countCancelledAppointments = countCancelledAppointments
        })
        .catch((error: Error) => {
          console.error('[fetchAll] error', error)
          this.fail(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.isLoading = false
    }
  }

  @computed
  get searchQueryNormalized(): string {
    if (!/[A-z]/.test(this.searchQuery)) {
      return this.searchQuery.replace(/\D+/g, '')
    }

    return this.searchQuery
  }

  @computed
  get searchQueryPattern(): RegExp | undefined {
    if (this.searchQueryNormalized) {
      return new RegExp(this.searchQueryNormalized, 'gi')
    }

    return undefined
  }

  @action
  searchCalls(searchQuery: string) {
    this.searchQuery = searchQuery

    this.fetchAll()
    this.refreshPage()
  }

  @action
  clearSearch() {
    if (this.searchQuery !== '') {
      this.searchQuery = ''
      this.fetchAll()
      this.refreshPage()
    }
  }

  @computed
  get items() {
    return this.itemIds.map((id) => callsRepo.get(id))
  }

  @action
  fail(error: Error) {
    if (error instanceof Error) {
      this.error = error.message
    } else {
      this.error = null
    }
  }

  @action
  cleanStore() {
    this.searchQuery = ''
    this.error = null

    this.isOnCallsPages = false
    this.isLoading = false
    this.error = null

    this.isDirty = false
    this.itemPerPageIds.clear()
    this.totalItemsPerPage = 0
    this.itemIds.clear()
    this.totalItems = 0

    this.pageSize = 15
    this.currentPage = 1
    this.orderBy = 'callDateTime'
    this.dir = 'desc'
    this.filters.replace([['opportunity', true]])
    this.activeQuickFilter = 'opportunities'

    // States for Call details drawer
    this.drawer = null

    // States for Quick summary component
    this.previousSummary.countAll = 0
    this.previousSummary.countMissedCalls = 0
    this.previousSummary.countAppointments = 0

    this.summary.countAll = 0
    this.summary.countMissedCalls = 0
    this.summary.countAppointments = 0
  }
}

const callsStore = new CallsStore()
export default callsStore
