import { makeStyles, Theme } from '@material-ui/core'
import { defaultFont } from 'assets/jss/mvp'

export const useAuditedMailingCardInfoStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  detailsPanel: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  detail: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '28px 2px 12px;',
  },

  detailWithoutProduction: {
    flex: 'auto 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    padding: '40px 14px 23px;',
  },

  detailSeparator: {
    borderRight: 'solid 1px rgb(241, 241, 241)',
  },

  label: {
    ...defaultFont,
    flex: '0 0 auto',
    fontSize: '11px',
    fontWeight: 500,
    color: '#232323',
    height: 16,
    marginTop: 8,
  },

  labelWithoutProduction: {
    ...defaultFont,
    flex: '0 0 auto',
    fontSize: 17,
    fontWeight: 500,
    color: '#232323',
    height: 16,
    marginLeft: 7,
  },

  leadsCount: {
    fontSize: 17,
    marginTop: 6,
    marginLeft: 5,
    '& label': {
      marginLeft: 7,
    },
  },

  leadLabel: {
    fontWeight: 400,
    fontSize: 17,
    marginTop: 6,
    marginLeft: 2,
  },

  icon: {
    color: '#D3D3D3',
    width: 8,
    height: 8,
    marginRight: 4,
  },

  stats: {
    flex: '1 1 auto',
    ...defaultFont,
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 700,
    color: '#929292',
  },

  newPatientsStats: {
    flex: '1 1 auto',
    ...defaultFont,
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 700,
    color: '#929292',
    '& img': {
      marginRight: 5,
      width: '22px',
      marginBottom: 2,
    },
  },

  totalNewPatients: {
    color: '#000',
    fontSize: '25px',
    marginBottom: -2,
  },

  totalProduction: {
    flex: '1',
    ...defaultFont,
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: 700,
    color: '#929292',
    marginTop: 3,
    marginBottom: 1,
    '& span': {
      color: '#0e951c',
      fontSize: '21px',
    },
  },

  ratePanel: {
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}))
