import React, { Fragment, useContext } from 'react'
import cx from 'classnames'
import OutcomeIcon from '@material-ui/icons/CallReceived'
import { observer } from 'mobx-react'

import Call from 'models/Call'
import { repository as appointments } from 'models/Appointment'
import { HighlightContext } from 'components/Text'
import { getOutcomeStatus, outcomeReducer} from '../../../helpers/callOutcome'
import { useStores } from '../../../config/store'

interface OutcomeCellProps {
  call: Call
  classes: any
}

const OutcomeCell: React.FC<OutcomeCellProps> = ({ call, classes }) => {
  const {callsStore} = useStores()
  const count = call.appointmentsCounter
  const answeredByVoicemail = call.callAnsweredBy !== 'Voicemail'
  const icon = (
    <OutcomeIcon
      className={cx(
        classes.colOutcomeIcon,
        answeredByVoicemail ? classes.colOutcomeIconVoicemail : classes.colOutcomeIconNonVoicemail
      )}
    />
  )

  const appt = call.appointments.reduce(outcomeReducer, undefined)
  const label = getOutcomeStatus(appt, count)
  const highlightPattern = useContext(HighlightContext)

  if (count && count > 0 && call.appointments.length > 0 && highlightPattern) {
    const hasMatch = call.appointments.reduce((acc, apptId) => {
      const appt = appointments.get(apptId)

      if (appt && highlightPattern.test(appt.fullName)) {
        return true
      }

      return acc
    }, false)

    if (hasMatch) {
      return (
        <span style={{ backgroundColor: '#FFF3A1', fontWeight: 'bold' }}>
          {icon}
          {label}
        </span>
      )
    }
  }

  return (
    <Fragment>
      {icon}
      {label}
    </Fragment>
  )
}

export default observer(OutcomeCell)
