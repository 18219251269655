import { makeStyles, Theme } from '@material-ui/core'

export const useMailingMatchbackResultStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 15,
    flexDirection: 'row',
  },
  smallHeading: {
    display: 'block',
    color: 'rgb(124,124,124)',
    fontFamily: 'Roboto Condensed',
  },
  crStyle: {
    flexBasis: '145px',
  },
  progressStyle: {
    height: '145px',
    width: '145px',
    flexBasis: '145px',
    margin: -8,

    '& span': {
      marginTop: 8,
      fontSize: '28px',
    },
    '& span + span': {
      marginTop: 8,
      fontSize: '14px',
      lineHeight: '100%',
    },
  },
  statsWraper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stats: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto Condensed',
    '& label': {
      color: 'rgb(50,50,50)',
      fontWeight: '200',
      fontSize: '13px',
      position: 'relative',
      paddingLeft: '16px',
      '&:before': {
        content: '""',
        position: 'absolute',
        height: 8,
        width: 8,
        borderRadius: '100%',
        left: 0,
        top: 5,
      },
    },
    '& span': {
      fontSize: '30px',
      fontWeight: '600',
      paddingTop: '8px',
      paddingLeft: '15px',
    },
  },
  red: {
    '& span': {
      color: 'rgb(225,40,61)',
    },
    '& label:before': {
      background: 'rgb(225,40,61)',
    },
  },
  grey: {
    '& span': {
      color: 'rgb(128,128,128)',
    },
    '& label:before': {
      background: 'rgb(201,201,201)',
    },
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexBasis: 'calc(100% / 2.6)',
    alignItems: 'flex-end',
    opacity: 0.2,
    '& img': {
      height: 54,
    },
    '& svg': {
      height: 60,
      width: 60,
      fill: 'rgb(185,185,185)',
      marginBottom: -8,
    },
  },
  matchedAuditedResult: {
    padding: '20px 0px 0px',
    marginTop: -40,
    marginRight: -20,
    marginLeft: -20,
    borderBottom: 'solid 1px rgb(241, 241, 241)',
  },
}))
