import React, { useState, Fragment } from 'react'
import {
  Button,
  IconButton,
  CircularProgress
} from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { Observer } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles'
import HelpModal from '../../components/Settings/HelpModal'

import AppState from '../../config/store/AppState'
import settingsStore from '../../config/store/SettingsStore'
import accountStore from '../../config/store/AccountStore'

const styles = () => ({
  noShadow: {
    boxShadow: 'none',
    borderRadius: 0
  },
  help: {
    padding: 6,
    marginLeft: 8,
    color: '#717171'
  }
})

const SaveSettings = (props) => {
  const { classes } = props
  const [openHelp, setOpenHelp] = useState(false)
  const handleSave = async () => {
    settingsStore.isSaving = true

    const validate = [
      settingsStore.email && !settingsStore.newEmail,
      settingsStore.phone && !settingsStore.newPhone,
    ].some(v => v)
    if (validate) {
      settingsStore.isSaving = false
      AppState.errorMessage('Please check required fields')
      return
    }

    const settings = {
      email: settingsStore.newEmail,
      phoneNumber: settingsStore.newPhone,
      receivedSmsAlerts: settingsStore.smsAlerts,
      missedCallAlerts: settingsStore.callAlerts,
      missedCallAlertRecipients: settingsStore.newMissedCallAlertRecipients
    }

    try {
      await settingsStore.updateUserSettings(settings)

      settingsStore.email = settingsStore.newEmail
      settingsStore.phone = settingsStore.newPhone
      settingsStore.missedCallsEmail = settingsStore.newMissedCallsEmail
      settingsStore.missedCallsMobile = settingsStore.newMissedCallsMobile
      settingsStore.receiveSmsEmail = settingsStore.newReceiveSmsEmail
      settingsStore.receiveSmsMobile = settingsStore.newReceiveSmsMobile

      settingsStore.missedCallAlertRecipients = settingsStore.newMissedCallAlertRecipients

      AppState.successMessage('Your settings has been updated')
    } catch (e) {
      if (e.status === 400) {
        AppState.errorMessage(e.message)
      } else {
        AppState.errorMessage('Something went wrong. Please try again.')
      }
    }

    settingsStore.isSaving = false
  }

  return (
    <Observer render={() => (
      <Fragment>
        <HelpModal open={openHelp} hideModal={(hide) => setOpenHelp(!hide)} userData={accountStore.sfUserData}/>
        <Button
          style={{boxShadow: 'none', borderRadius: 0}}
          variant='contained'
          color='primary'
          disabled={
            settingsStore.isLoading ||
            settingsStore.isSaving ||
            !settingsStore.isFormUpdated
          }
          onClick={handleSave}
        >
          {
            settingsStore.isSaving ?
              <CircularProgress size={14} style={{ marginRight: 8, color: '#333', opacity: 0.9 }} /> :
              null
          }
          Save Changes
        </Button>
        <IconButton classes={{
          root: classes.help
        }} onClick={() => setOpenHelp(true)}><HelpOutline/></IconButton>
      </Fragment>
    )} />
  )
}

export default withStyles(styles)(SaveSettings)
