import React from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Assignment as OrderNumberIcon,
  Dashboard as MailingTypeIcon,
  DateRange as DeliveryWeekIcon,
  PermMedia as QuantityIcon,
} from '@material-ui/icons'

import {
  defaultFont
} from 'src/assets/jss/mvp'

const styles = theme => ({
  root: {
    ...defaultFont,
  },
  row: {
    padding: 20,
    paddingTop: 0,
    '&:last-of-type': {
      paddingBottom: 15
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detail: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 15,
    height: 15,
    color: '#929292',
    marginRight: 4
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#929292',
    marginRight: 4
  },
  text: {
    flex: '1',
    fontWeight: 400,
    fontSize: '13px',
    color: '#232323'
  },
})

const MailingDetailsInfo = ({ classes, mailingsStore, configStore, ...props }) => {
  const mailing = mailingsStore.drawer

  if (!mailing) {
    return <div />
  }

  const week = moment(mailing.deliveryWeek)
  const type = mailing.type
  const deliveryWeek = week.isValid() ? week.format(configStore.dateFormat) : '-'

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.detail}>
          <OrderNumberIcon className={classes.icon} />
          <span className={classes.label}>Order Number:</span>
          <span className={classes.text}>{mailing.name}</span>
        </div>
        <div className={classes.detail}>
          <DeliveryWeekIcon className={classes.icon} />
          <span className={classes.label}>Delivery Week:</span>
          <span className={classes.text}>{deliveryWeek}</span>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.detail}>
          <MailingTypeIcon className={classes.icon} />
          <span className={classes.label}>Mailing Type:</span>
          <span className={classes.text} style={{ textTransform: 'capitalize' }}>
            {mailing.campaignType && mailing.campaignType.toLowerCase() || '-'}
          </span>
        </div>
        <div className={classes.detail}>
          <QuantityIcon className={classes.icon} />
          <span className={classes.label}>Quantity:</span>
          <span className={classes.text}>
            {
              Number(mailing.quantity)
                .toLocaleString(undefined, {
                  maximumFractionDigits: 0,
                })
            }
            {' pcs'}
          </span>
        </div>
      </div>
    </div>
  )
}

export default inject('configStore', 'mailingsStore')(
  withStyles(styles)(
    observer(MailingDetailsInfo)
  )
)
