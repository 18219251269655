import React from 'react'
import moment from 'moment'
import cx from 'classnames'
import Star from '@material-ui/icons/Star'
import DescriptionIcon from '@material-ui/icons/Description'
import Tooltip from '@material-ui/core/Tooltip'

import Call from 'models/Call'
import CallsBadge from 'components/Calls/CallsBadge'
import HighlightsTooltip from 'views/Calls/tooltip'

import VoiceMailIcon from 'assets/img/voicemail.svg'

import AddAppointmentButton from './AddAppointmentButton'
import PlayAudioButton from './PlayAudioButton'
import CallerCell from './CallerCell'
import OutcomeCell from './OutcomeCell'

interface renderColumnsArgs {
  classes: any
  stopAudio: boolean
}

export const renderColumns = function({
  classes,
  stopAudio
}: renderColumnsArgs) {
  // const { classes } = _this.props

  return [
    {
      id: 'icon',
      label: '',
      sortable: false,
      styles: {
        tableCell: {
          minWidth: 58,
          maxWidth: 58
        }
      },
      render: function(_data: any, row: Call) {
        return <CallsBadge content={row.appointmentsCounter} />
      }
    },
    {
      id: 'callDateTime',
      label: 'Date & Time',
      sortable: true,
      render: function(_data: any, row: Call) {
        const dateTime = moment(row.callDateTime)
        return dateTime.isValid() ? dateTime.format('M/D/YY @ h:mm a') : '-'
      }
    },
    {
      id: 'mailing',
      label: 'Mailing',
      sortable: true,
      render: function(_data: any, row: Call) {
        const week = moment(row.campaignTargetDeliveryWeek)
        const type = row.campaignType
        return (
          <div className={classes.colStackedText}>
            <div>{week.isValid() ? week.format('D MMM YYYY') : ''}</div>
            <div>{type ? `${type}` : ''}</div>
          </div>
        )
      }
    },
    {
      id: 'caller',
      label: 'Caller',
      sortable: false,
      render: function(_data: any, row: Call) {
        return <CallerCell call={row} classes={classes} />
      }
    },
    {
      id: 'patientType',
      label: 'Patient Type',
      sortable: false,
      render: function(_data: any, row: Call) {
        return row.patientType || '--'
      }
    },
    {
      id: 'opportunity',
      label: 'Opportunity',
      sortable: false,
      render: function(_data: any, { opportunity }: Call) {
        return (
          <Star
            className={cx(
              classes.colOpp,
              opportunity ? classes.colOppEnabled : classes.colOppDisabled
            )}
          />
        )
      }
    },
    {
      id: 'outcome',
      label: 'Outcome',
      sortable: true,
      render: function(_data: any, row: Call) {
        return <OutcomeCell call={row} classes={classes} />
      }
    },
    {
      id: 'answeredBy',
      label: 'Answered By',
      sortable: false,
      render: function(_data: any, row: Call) {
        const answeredBy = (
          <div>{row.answeredBy || '--'}</div>
        )

        const icon = row.voicemailLeftMessage && (
          <img src={VoiceMailIcon} className={classes.voicemailIcon} />
        )

        return (
          <div className={classes.isFlex}>
            {answeredBy}
            {icon}
          </div>
        )
      }
    },
    {
      id: 'duration',
      label: 'Recording',
      sortable: true,
      render: function(_data: any, row: Call) {
        const duration = row.callDuration
        const audioFile = row.callAudio

        if (duration) {
          return (
            <PlayAudioButton
              audioFile={audioFile}
              audioDuration={duration}
              stopAudio={stopAudio}
            />
          )
        }

        return <div />
      }
    },
    {
      id: 'highlights',
      label: '',
      sortable: false,
      render: (_data: any, row: Call) => {
        return (
          <Tooltip
            interactive
            classes={{
              tooltip: classes.tooltipRoot
            }}
            PopperProps={{
              onClick: e => {
                e.preventDefault()
                e.stopPropagation()
              }
            }}
            title={<HighlightsTooltip row={row} />}
            placement="top"
          >
            <DescriptionIcon className={classes.highlightIcon} />
          </Tooltip>
        )
      }
    },
    {
      id: 'action',
      label: '',
      sortable: false,
      render: function(_data: any, row: Call) {
        const duration = row.callDuration
        return duration ? <AddAppointmentButton /> : <div />
      }
    }
  ]
}
