import { observable, computed, action } from 'mobx'

import getCallById from 'services/calls/getCallById'
import apiCreateAppointment from 'services/calls/createAppointment'
import AppState from 'config/store/AppState'

import Appointment, { repository as appointmentsRepo } from './Appointment'
import Repository from './Repository'

export const repository = new Repository<Call>()

export default class Call {
  // State
  @observable isLoading = 0

  // Details
  @observable id: string
  @observable callDateTime?: string
  @observable callAudio?: string
  @observable callDuration?: number
  @observable callerPhoneNumber?: string
  @observable callerPhoneNumberRaw?: string
  @observable appointmentsCounter?: number
  @observable patientType?: string
  @observable highlights?: string

  @observable accountId?: string

  // Campaign info
  @observable campaignId?: string
  @observable campaignName?: string
  @observable campaignTargetDeliveryWeek?: string
  @observable campaignType?: string

  appointments = observable.array<string>([])

  // Latest Score
  @observable scoreId?: string
  @observable callerName?: string
  @observable callerIdName?: string
  @observable opportunity?: boolean
  @observable callAnsweredBy?: string
  @observable handlerName?: string
  @observable voicemailLeftMessage?: boolean
  @observable voicemailWantsAppointment?: boolean

  relatedCalls = observable.array<Call>([])

  @observable isDirty?: boolean

  constructor(call: any) {
    this.id = call.id
  }

  @action.bound
  createAppointment(appointment: Appointment) {
    appointment.loading(true)
    const id = appointmentsRepo.upsert(appointment)

    if (!this.appointments.includes(id)) {
      this.appointments.push(id)
    }

    apiCreateAppointment(this, appointment.dateTime, appointment.firstName, appointment.lastName)
      .then((newAppointment: Appointment) => {
        appointmentsRepo.replace(appointment, newAppointment)
        this.appointments.remove(appointment.id)
        this.appointments.push(newAppointment.id)
        this.appointmentsCounter = this.appointmentsCounter ? this.appointmentsCounter + 1 : 1

        AppState.successMessage(`New appointment for ${newAppointment.fullName} created`)
      })
      .catch(() => {
        appointment.loading(false)
        appointment.isError = true
      })
  }

  @action.bound
  loading(isLoading = true) {
    this.isLoading += isLoading ? 1 : -1
    if (this.isLoading < 0) {
      this.isLoading = 0
    }
  }

  @action.bound
  async update(forceRefresh = false) {
    if (forceRefresh || this.isDirty || typeof this.isDirty === 'undefined') {
      this.loading(true)
      try {
        await getCallById(this.id)
        this.isDirty = false
      } catch (e) {
        /**
         * @todo Do something with the error
         */
        console.log(e)
      }
      this.loading(false)
    }
  }

  getCallerNameMatched(exp: RegExp | undefined): string | undefined {
    if (typeof exp === 'undefined') {
      return this.callerName ?? this.callerIdNameFmt
    }

    if (exp.test(this.callerName ?? '')) {
      return this.callerName
    }

    if (exp.test(this.callerIdNameFmt ?? '')) {
      return this.callerIdNameFmt
    }

    return this.callerName
  }

  @computed
  get caller() {
    if (this.callerName) return this.callerName

    if (this.callerIdName) return this.callerIdNameFmt as string

    return this.callerPhoneNumber
  }

  @computed
  get callerIdNameFmt(): string | undefined {
    if (!this.callerIdName) return

    return this.callerIdName
      .trim()
      .split(' ')
      .map(s => {
        if (!s) return ''

        return `${s[0].toUpperCase()}${s.slice(1).toLowerCase()}`
      })
      .join(' ')
  }

  @computed
  get accountName() {
    const account = AppState.accounts.find(({ id }: { id: string }) => id === this.accountId)
    if (account === null || typeof account == 'undefined') {
      return null
    }

    return account.name
  }

  @computed
  get answeredBy() {
    const handler = this.handlerName
    const answeredBy = this.callAnsweredBy

    if (handler && answeredBy) {
      return `${handler}`
    }
    if (answeredBy) {
      return answeredBy
    }
    return ''
  }
}
