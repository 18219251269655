import React from 'react'
import { observer, MobXProviderContext } from 'mobx-react'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Star,
  PhoneMissed as PhoneMissedIcon,
  PermMedia as MailingIcon,
  Person as PatientTypeIcon,
  CheckBox as OpportunityIcon,
  CheckBoxOutlineBlank as NotOpportunityIcon,
  CallReceived as AppointmentsIcon,
  LocationOn as LocationIcon
} from '@material-ui/icons'

import {
  defaultFont
} from 'src/assets/jss/mvp'
import { getOutcomeStatus, outcomeReducer } from '../../helpers/callOutcome'

const starStyle = {
  height: '20px',
  width: '20px',
  display: 'block',
  margin: '0 auto',
  marginRight: '4px'
}

const styles = theme => ({
  root: {
    ...defaultFont,
  },
  header: {
    paddingLeft: 35,
    paddingRight: 35,
    minHeight: 40,
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    display: 'flex',
    alignItems: 'center',
    color: '#232323',
    fontSize: '16px',
    fontWeight: '400'
  },
  row: {
    padding: '15px 35px',
    paddingBottom: 0,
    '&:last-of-type': {
      paddingBottom: 15
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  detail: {
    display: 'flex',
    alignItems: 'center'
  },
  detailAlignTop: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 6
  },
  text: {
    flex: '1',
    fontWeight: 400,
    fontSize: '14px',
    color: '#232323'
  },
})

const CallDetailsInfo = ({ classes,...props }) => {
  const { appState, callsStore } = React.useContext(MobXProviderContext)
  const call = callsStore.drawer

  if (!call) {
    return <div />
  }

  const count = call.appointmentsCounter
  const appt = call.appointments.reduce(outcomeReducer, undefined)
  const week = moment(call.campaignTargetDeliveryWeek)
  const type = call.campaignType
  const mailing = `Mailing: ${week.isValid() ? week.format('D MMM') : ''} ` +
      `${type ? `(${type})` : ''}`

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <span  className={classes.headerTitle}>
          Details
        </span>
      </div>
      {appState.accounts.length > 1 && (
        <div className={classes.row}>
          <span className={classes.detailAlignTop}>
            <LocationIcon className={classes.icon} />
            <span className={classes.text}>{call.accountName}</span>
          </span>
        </div>
      )}
      <div className={classes.row}>
        <span className={classes.detail}>
          <MailingIcon className={classes.icon} />
          <span className={classes.text}>{mailing}</span>
        </span>
        <span className={classes.detail}>
          <PatientTypeIcon className={classes.icon} />
          <span className={classes.text}>{call.patientType}</span>
        </span>
      </div>
      <div className={classes.row}>
        <span className={classes.detail}>
          {
            call.opportunity ?
            <Star style={{color: '#ED154E', ...starStyle}}/> :
            <Star style={{color: '#D3D3D3', ...starStyle}} />
          }
          <span className={classes.text}>Opportunity</span>
        </span>
        <span className={classes.detail}>
          <AppointmentsIcon
            style={{color: call.callAnsweredBy !== 'Voicemail' ? '#0D951B' : '#ED154E',}}
            className={classes.icon} />
          <span className={classes.text}>
            {getOutcomeStatus(appt, count)}
          </span>
        </span>
      </div>
    </div>
  )
}

export default withStyles(styles)(observer(CallDetailsInfo))