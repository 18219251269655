import React, { Component } from 'react'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import Img from 'react-image'

import BodyEnd from '../MVP/Helpers/BodyEnd'

import { FormatAlignLeft, ErrorOutline, Close } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'

import withStyles from '@material-ui/core/styles/withStyles'

import { defaultFont } from 'src/assets/jss/mvp'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    width: 'calc(100vw - 70px)',
    height: '100vh',
    right: 0,
    top: 0,
    backgroundColor: 'rgb(0,0,0,.9)',
    zIndex: 9999,
  },
  preview: {
    width: 'calc(100% - 430px)',
    padding: '16px',
    overflowX: 'auto',
    '& h2': {
      fontFamily: 'Roboto Condensed',
      fontWeight: '400',
      color: '#fff',
      fontSize: '30px',
      marginTop: '8px',
      marginBottom: '16px',
    },
    '& nav': {
      padding: '8px 0px',
      marginBottom: '20px',
    },
    '& nav a': {
      textDecoration: 'none',
      fontFamily: 'Roboto Condensed',
      color: '#fff',
      fontSize: '15px',
      padding: '10px 5px',
      cursor: 'pointer',
      marginRight: 12,
      '&:hover': {
        color: '#fff',
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 'calc(40% - 25px)',
    left: 'calc(50% - 25px)',
    marginTop: '20%',
    '& svg': {
      color: '#e13765',
    },
  },
  previewContainer: {
    display: 'flex',
    height: '100vh',
  },
  statusContainer: {
    background: '#fff',
    width: '430px',
  },
  active: {
    borderBottom: 'solid 2px #F50D0D',
  },
  imgPreview: {
    textAlign: 'center',
    position: 'relative',
    height: 'auto',
    '& img': {
      width: '100%',
      position: 'relative',
    },
  },
  statusHeading: {
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    padding: '16px',
    borderBottom: 'solid 1px rgb(220,220,221)',
    '& h4': {
      margin: 0,
      fontFamily: 'Roboto Condensed',
      fontSize: '18px',
      marginLeft: '16px',
    },
  },
  statusBody: {
    padding: '16px',
    backgroundColor: 'rgb(239,239,239)',
    height: '100%',
    textAlign: 'center',
    paddingTop: 32,
    '& p': {
      fontFamily: 'Roboto Condensed',
      fontSize: 14,
      lineHeight: 1.5,
      color: 'rgb(144,144,145)',
      '& strong': {
        color: 'rgb(50,50,50)',
      },
      '& a': {
        color: 'rgb(225,55,101)',
        textDecoration: 'underline',
      },
    },
  },
  statusHeadingIcon: {
    color: 'rgb(201,201,201)',
  },
  status: {
    color: 'rgb(168,30,47)',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  emptyIcon: {
    height: '40px',
    width: '40px',
    color: 'rgb(201,201,201)',
  },
  emptyBody: {
    paddingTop: 64,
  },
  closeBtn: {
    position: 'absolute',
    color: '#fff',
    marginLeft: '-53px',
    top: '31px',
    cursor: 'pointer',
  },
  statusItem: {
    fontSize: '14px',
    border: 'solid 1px #e4e4e4',
    padding: '16px',
    lineHeight: '1.5',
    marginBottom: '16px',
  },
})

let formatDate = (theDate) => {
  const week = moment(theDate)

  return week.isValid() ? week.format('MM/DD/YYYY') : '-'
}

@inject('mailingsStore')
@withStyles(styles)
class MailingDesignPreview extends Component {
  state = {
    frontActive: true,
    backActive: false,
  }

  componentDidMount() {
    if (this.props.previewFace === 'front') {
      this.setState({ frontActive: true })
      this.setState({ backActive: false })
    } else {
      this.setState({ frontActive: false })
      this.setState({ backActive: true })
    }
  }

  handleClose = () => {
    this.props.closePreview()
  }

  handleFront = () => {
    this.setState({ frontActive: true })
    this.setState({ backActive: false })
  }

  handleBack = () => {
    this.setState({ backActive: true })
    this.setState({ frontActive: false })
  }

  render() {
    const { mailingsStore, classes } = this.props
    const mailing = mailingsStore.drawer

    const mailingDate = mailing.deliveryWeek
      ? moment(mailing.deliveryWeek).format('MMM D')
      : ''
    return (
      <BodyEnd>
        <div className={classes.root}>
          <div className={classes.previewContainer}>
            <div className={classes.preview}>
              <h2>
                Mailing {mailingDate} - {mailing.campaignType}
              </h2>
              <nav>
                <a
                  onClick={this.handleFront}
                  className={`${this.state.frontActive ? classes.active : ''}`}
                >
                  Front
                </a>
                <a
                  onClick={this.handleBack}
                  className={`${this.state.backActive ? classes.active : ''}`}
                >
                  Back
                </a>
              </nav>
              <div className={classes.imgPreview}>
                <Img
                  src={`${
                    this.state.frontActive
                      ? mailing.frontDesignUrl
                      : mailing.backDesignUrl
                  }`}
                  loader={
                    <CircularProgress className={classes.loader} size={50} />
                  }
                />
              </div>
            </div>

            <div className={classes.statusContainer}>
              <Close className={classes.closeBtn} onClick={this.handleClose} />
              <div className={classes.statusHeading}>
                <FormatAlignLeft className={classes.statusHeadingIcon} />
                <h4>
                  Status:{' '}
                  <span className={classes.status}>{mailing.status}</span>
                </h4>
              </div>
              <div className={classes.statusBody}>
                {mailing.status === 'ordered' ? (
                  <div className={classes.statusItem}>
                    This mailing design was ordered on{' '}
                    <strong>{formatDate(mailing.orderDate)}</strong>
                  </div>
                ) : null}
                {mailing.status === 'approved' ? (
                  <div className={classes.statusItem}>
                    This mailing design was approved by{' '}
                    <strong>{mailing.approvedBy}</strong>
                  </div>
                ) : null}
                {mailing.status === 'printed' ? (
                  <div className={classes.statusItem}>
                    This mailing design was printed on{' '}
                    <strong>{mailing.printDate}</strong>
                  </div>
                ) : null}
                {mailing.status === 'shipped' ? (
                  <div className={classes.statusItem}>
                    This mailing design was shipped on{' '}
                    <strong>{mailing.shipped}</strong>
                  </div>
                ) : null}
                {mailing.status === 'delivered' ||
                mailing.status === 'deliveredInFirstCall' ? (
                  <div className={classes.statusItem}>
                    This mailing design was approved by{' '}
                    <strong>{mailing.approvedBy}</strong> and delivered on{' '}
                    <strong>{formatDate(mailing.deliveryDate)}</strong>
                  </div>
                ) : null}
                {/*<div className={classes.emptyBody}>
									<ErrorOutline className={classes.emptyIcon}/>
									<p><strong>Need to request design changes?</strong></p>
									<p>Email us at <a href='mailto:mvpmailhouse@gmail.com'>mvpmailhouse@gmail.com</a><br/>
									We are happy to help tailor-fit your mailings to meet your personal preferences.</p>
								</div>*/}
              </div>
            </div>
          </div>
        </div>
      </BodyEnd>
    )
  }
}

export default withStyles(styles)(MailingDesignPreview)
