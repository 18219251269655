import _get from 'lodash/get'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { camelCase } from 'lodash'

import { authClient } from 'src/helpers/auth0'
import env from 'src/env'
import getRelatedAccounts from 'src/services/users/getRelatedAccounts'

/**
 * Verifies the email/password combination via the Identity API
 * and sets the current session
 *
 */
export const authenticateUser = async (login, passwordless) => {
  const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  }

  const data = await new Promise((resolve, reject) => {
    // Authenticate with email and password
    if(login) {
      authClient.login({
        username: login.email,
        password: login.password,
        audience: env.auth0.audience,
        scope: 'openid email profile',
        realm: env.auth0.realm
      }, (error, data) => {
        if (error) {
          console.log(error)
          reject(new Error('Invalid email/password combination'))
        }

        resolve(data)
      })
    }

    // Authenticate with email/phone and otp for passwordless login
    if(passwordless) {
      const { connection, verificationCode, phoneNumber, email } = passwordless

      axios.post(`https://${env.auth0.domain}/oauth/token`, {
        grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
        client_id: env.auth0.clientIdPasswordless,
        username: connection === 'email' ? email : phoneNumber,
        otp: verificationCode,
        realm: connection,
        audience: env.auth0.audience,
        scope: "openid profile email"
      })
        .then((res) => {
          resolve(camelizeKeys(res.data))
         })
        .catch(error => {
          reject(error)
        })
    }
  })


  const {
    accessToken,
    tokenType,
    idToken
  } = data

  const identity = jwtDecode(idToken)
  const {
    name,
    picture,
    exp
  } = identity
  const expiresAt = exp * 1000
  const id = _get(identity, [
    'https://mvpmailhouse.com/user_metadata',
    'sf_contact_id'
  ])
  const accounts = await getRelatedAccounts({
    headers: {
      Authorization: `${tokenType} ${accessToken}`,
    }
  })

  const session = {
    expiresAt,
    accessToken,
    tokenType,
    picture,
    userInfo: {
      id,
      name,
      accounts
    }
  }
  return session
}

export default authenticateUser
