import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'

import cx from 'classnames'

import UserAccountSelector from './UserAccountSelector'
import NavigationBarLinks from './NavigationBarLinks'


import logo from 'src/assets/img/logo.png'

const drawCollapsedWidth = 70
const drawerWidth = 250

const styles = theme => ({
  root: {
    position: 'relative',
    paddingRight: 0,
    '&:hover $hoverBar': {
      opacity: 0.9
    }
  },
  container: {
    top: 0,
    flex: '1 0 auto',
    display: 'flex',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexDirection: 'column',
    '-webkit-overflow-scrolling': 'touch',
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)'
  },
  drawerPaper: {
    overflow: 'hidden',
    backgroundColor: '#232323',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    height: '100vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawCollapsedWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawCollapsedWidth
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  logoPanel: {
    backgroundColor: '#141414',
    position: 'relative',
    width: drawerWidth,
    height: 82
  },
  logo: {
    position: 'absolute',
    left: 17,
    top: 16,
    display: 'inline-block',
    backgroundImage: `url(${logo})`,
    backgroundRepeat: 'no-repeat',
    width: 123,
    height: 49,
    '&.$hide': {
      transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest
      })
    }
  },
  logoExpanded: {
    backgroundSize: '115px'
  },

  logoCollapsed: {
    width: 40,
    backgroundSize: '115px'
  },
  hide: {
    opacity: 0
  },
  hoverBar: {
    cursor: 'pointer',
    padding: '5px 7px',
    opacity: 0,
    height: 26,
    width: 17,
    backgroundColor: 'transparent',
    position: 'absolute',
    right: -23,
    top: 'calc(50% - 26px)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: '0.3s'
    }),
    '&:before,&:after': {
      borderRadius: 4,
      position: 'relative',
      height: 12,
      backgroundColor: '#ED154E',
      content: '""',
      display: 'block',
      width: '100%',
      transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest
      })
    },
    '&:before': {
      top: 3,
    },
    '&:after': {
      bottom: 2,
    },
    '&:hover:before': {
      transform: 'rotate(-40deg)'
    },
    '&$hoverBarExpanded:hover:before': {
      transform: 'rotate(40deg)'
    },
    '&:hover:after': {
      transform: 'rotate(40deg)'
    },
    '&$hoverBarExpanded:hover:after': {
      transform: 'rotate(-40deg)'
    }
  },

  hoverBarExpanded: {

  }
})

@inject('appState')
@withStyles(styles)
@observer
export default class NavigationBar extends Component {

  constructor(props) {
    super(props)
    this.scrollBarRef = React.createRef()
  }

  handleDrawerOpen = () => {
    this.props.appState.setNavDrawer(true)
  }

  handleDrawerClose = () => {
    this.props.appState.setNavDrawer(false)
  }

  render() {
    const { classes, theme } = this.props
    const { isDrawerOpen } = this.props.appState
    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          classes={{
            paper: cx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
          }}
          open={isDrawerOpen}
        >
          <div className={classes.logoPanel}>
            <span className={cx(classes.logo, classes.logoExpanded, !isDrawerOpen && classes.hide)} />
            <span className={cx(classes.logo, classes.logoCollapsed, isDrawerOpen && classes.hide)} />
          </div>

          <Divider className={classes.divider} />

          <UserAccountSelector expanded={isDrawerOpen} onToggle={this.handleAccountSelectorToggle} drawerOpen={this.handleDrawerOpen} />

          <Divider className={classes.divider} />

          <NavigationBarLinks
            expanded={isDrawerOpen}
            onToggleClick={() => isDrawerOpen ? this.handleDrawerClose() : this.handleDrawerOpen()}
          />

        </Drawer>
        <div
          className={cx(
            classes.hoverBar,
            isDrawerOpen ? classes.hoverBarExpanded : null
          )}
          onClick={() => isDrawerOpen ? this.handleDrawerClose() : this.handleDrawerOpen()}
        />
      </div>
    )
  }
}
