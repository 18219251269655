import React from 'react'
import PropTypes from 'prop-types'

// import {
//   ContentCopy,
//   Store,
//   InfoOutline,
//   Warning,
//   DateRange,
//   LocalOffer,
//   Update,
//   ArrowUpward,
//   AccessTime,
//   Accessibility
// } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

// import {
//   Table,
//   ItemGrid
// } from '../../components'

import AppState from 'src/config/store/AppState'

import dashboardStyle from 'src/assets/jss/material-dashboard-react/dashboardStyle'

class Dashboard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      value: 0
    }
  }

  handleChange(event, value) {
    this.setState({ value })
  }

  handleChangeIndex(index) {
    this.setState({ value: index })
  }

  testMessage = type => () => {
    AppState[`${type}Message`](`This is message is of type ${type}`)
  }

  render() {
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            {/* {
              ['flash', 'success', 'error', 'info', 'warning'].map(type => {
                return (
                  <Button key={type} onClick={this.testMessage(type)}>{`Test ${type} message`}</Button>
                )
              })
            } */}
          </Grid>
        </Grid>
      </div>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(Dashboard)
