import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames/dedupe'
import withStyles from '@material-ui/core/styles/withStyles'
import MUITable from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import CircularProgress from '@material-ui/core/CircularProgress'
import ErrorIcon from '@material-ui/icons/Warning'

import TableHead from './Head'
import TablePagination from './Pagination'

const tableStyles = theme => ({
  table: {
    position: 'relative',
    marginTop: '0px',
    border: '1px solid #d7d7d7',
    borderBottom: '2px solid #d7d7d7',
    '& thead': {
      backgroundColor: 'rgb(255, 255, 255)',
      borderBottom: '3px solid rgb(242,242,242)'
    },
    '& $tableBodyRow': {
    }
  },
  tableLoading: {
    '& $tableBodyRow': {
      filter: 'blur(3px)'
    }
  },
  tableHeaderRow: {

  },
  tableBodyRow: {
    borderBottom: 'solid 1px #f7f7f7'
  },
  tableBodyRowClickable: {
    cursor: 'pointer'
  },
  loader: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  loaderContent: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%'
  },
  loaderIcon: {
  },

  loaderCaption: {
    marginTop: -15,
    fontWeight: 400,
    fontSize: '18px',
    color: '#666'
  }
})

const propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,

  isLoading: PropTypes.bool,
  isError: PropTypes.bool,

  orderBy:  PropTypes.string,
  dir: PropTypes.string,
  error: PropTypes.string,

  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),

  onColumnSort: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  onDataRowClick: PropTypes.func
}

const defaultProps = {
  isLoading: false,
  isError: false,
  orderBy:  null,
  dir: null,
  showHeader: true,
  showFooter: false
}

@withStyles(tableStyles)
class Table extends React.Component {
  constructor(props) {
    super(props)
  }

  handleRowClick = (row, rowIndex) => () => {
    if (this.props.onDataRowClick) {
      this.props.onDataRowClick(row, rowIndex)
    }
  }

  renderLoading() {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell style={{ height: 0 }}>
          <div className={classes.loader}>
            <div className={classes.loaderContent}>
              <div className={classes.loaderIcon}>
                <CircularProgress size={60} color="primary" />
              </div>
              <div className={classes.loaderCaption}>Loading data. Please wait...</div>
            </div>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  renderError() {
    const { classes } = this.props
    return (
      <TableRow>
        <TableCell style={{ height: 0 }}>
          <div className={classes.loader}>
            <div className={classes.loaderContent} style={{ cursor: 'pointer' }}>
              <div className={classes.loaderIcon}>
                <ErrorIcon style={{ width: 60, height: 60 }} color="error" />
              </div>
              <div className={classes.loaderCaption}>Oops...we failed to retrieve your data. Try again?</div>
            </div>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  renderCollectionBody() {
    const { classes, columns, data, onDataRowClick, noResultsText } = this.props

    if (data.length === 0) {
      return (
        <TableRow className={classes.tableBodyRow}>
          <TableCell colSpan={columns.length}>
            {noResultsText ?? 'No items to show ...'}
          </TableCell>
        </TableRow>
      )
    }

    return (
      <React.Fragment>
        {data.map((row, rowIndex) => {
          return (
            <TableRow
              className={cx(
                classes.tableBodyRow,
                onDataRowClick ? classes.tableBodyRowClickable : null
              )}
              hover
              key={rowIndex}
              onClick={this.handleRowClick(row, rowIndex)}
            >
              {columns.map((column, colIndex) => {
                const style = column && column.withMoreStyles && column.withMoreStyles.tableCell || {}
                return (
                  <TableCell key={`row-${rowIndex}-col-${colIndex}`} style={style}>
                    {column.render ? (
                      column.render(row[column.id], row, rowIndex, colIndex)
                    ) : (
                      row[column.id]
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </React.Fragment>
    )
  }

  render() {
    const {
      classes,
      showHeader,
      showFooter,
      columns,
      data,
      totalItems,
      currentPage,
      pageSize,
      orderBy,
      dir,
      isLoading,
      isError,
      onColumnSort,
      onChangePage,
      onChangePageSize
    } = this.props

    return (
      <div>
        <div className={classes.tableResponsive}>
          <MUITable
            className={cx(classes.table, (isLoading || isError) ? classes.tableLoading : null)}
            size="small"
          >
            {showHeader ? (
              <TableHead
                columns={columns}
                orderBy={orderBy}
                dir={dir}
                onColumnSort={onColumnSort}
              />
            ) : null}
            <TableBody>
              {this.renderCollectionBody()}
              {isError ? this.renderError() : isLoading ? this.renderLoading() : null}
            </TableBody>
          </MUITable>
        </div>
        <React.Fragment>
          {
          data.length ?
            <MUITable>
              <TablePagination
                totalItems={totalItems}
                currentPage={currentPage}
                pageSize={pageSize}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </MUITable> : null
          }
        </React.Fragment>
      </div>
    )
  }
}

Table.propTypes = propTypes
Table.defaultProps = defaultProps

export default Table
