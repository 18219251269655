import React, { Component } from 'react'
import cx from 'classnames'
import { inject, observer } from 'mobx-react'
import moment from 'moment'

import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import ResetIcon from '@material-ui/icons/SettingsBackupRestore'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { Scrollbars } from 'react-custom-scrollbars'
import Select from '../MVP/MVPSelect'
import DateRangePicker from '../MVP/Pickers/MVPDateRangePicker'

const MAILING_TYPES = [
  { label: 'All', value: '' },
  { label: 'New Mover Mail', value: 'New Mover Mail' },
  { label: 'MVP Mail', value: 'MVP Mail' }
]

const MAP_OPTIONS = [
  // { label: 'Missed', value: 'Missed Call' },
  // { label: 'Appointment', value: 'Appointment' }
]

const EMPTY = []

import {
  defaultFont
} from 'src/assets/jss/mvp'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap : 'nowrap',
    padding: 0,
  },
  header: {
    display: 'flex',
    padding: '20px 16px',
    flex: '0 0 auto',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    ...defaultFont,
    fontSize: '18px',
    fontWeight: 700,
    padding: 0,
    margin: 0
  },
  headerIcon: {
    fontSize: 26
  },
  content: {
    display: 'flex',
    width: '100%',
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    flex: '1 1 auto'
  },

  contentWrapper: {
    padding: '20px 0',
    flex: '1 1 auto',
    '&>div>div:first-child': {
      position: 'inherit !important'
    }
  },
  control: {
    paddingLeft: 16,
    paddingRight: 16,
    marginBottom: 18,
    '& div': {
      fontWeight: '400',
      fontFamily: 'Roboto Condensed'
    },
    '& div[role*="listbox"]': {
      position: 'relative',
      zIndex: '999'
    }
  },
  hidden: {
    display: 'none'
  },
  footer: {
    width: '100%',
    padding: '20px 16px',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'space-between'
  },
  dropdownStyle: {
    border: '1px solid black !important',
    borderRadius: '5%',
    backgroundColor: 'lightgrey !important',
  }
})

@inject('mailingsStore')
@withStyles(styles)
@observer
export default class CallsAdvancedFilters extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => {
    const {
      mailingsStore: {
        filters
      }
    } = this.props
    const mailingsStoreFilters = filters.toJSON()
    const {
      gte,
      lte
    } = mailingsStoreFilters.deliveryWeek || {}
    const from = gte ? moment(gte).toDate() : undefined
    const to = lte ? moment(lte).toDate() : undefined
    const campaignType = mailingsStoreFilters.campaignType || ''

    return {
      deliveryWeek: {
        from,
        to
      },
      campaignType
    }
  }

  handleReset = () => {
    const { mailingsStore, onClose } = this.props
    mailingsStore.setQuickFilter('opportunities')
    this.setState(this.getInitialState())

    onClose()
  }

  handleApply = () => {
    const { mailingsStore, onClose } = this.props
    const {
      deliveryWeek: {
        from,
        to
      },
      campaignType
    } = this.state

    let hasFilters = false
    const filters = {}

    if (to || from) {
      hasFilters = true
      filters.deliveryWeek = {
        gte: from && moment(from).format('YYYY-MM-DD'),
        lte: to && moment(to).format('YYYY-MM-DD')
      }
    }
    if (campaignType) {
      hasFilters = true
      filters.campaignType = campaignType
    }

    mailingsStore.setFilters(filters)
    if (hasFilters) {
      mailingsStore.setQuickFilter('advanced')
    } else {
      mailingsStore.setQuickFilter('all')
    }

    onClose()
  }

  handleDateRangeChange = range => {
    this.setState({
      deliveryWeek: range
    })
  }

  handleSelectChange = id => value => {
    this.setState({
      [id]: value && value.value || ''
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <h1 className={classes.title}>Advanced Filters</h1>
          {/*<HelpIcon color="primary" className={classes.headerIcon} />*/}
        </div>
        <div className={classes.content}>
          <div className={classes.contentWrapper}>
            <Scrollbars>

              <div className={classes.control}>
                <DateRangePicker
                  id="deliveryWeek"
                  label="Mailing Date"
                  value={this.state.deliveryWeek}
                  placeholder="Recent Mailings"
                  onChange={this.handleDateRangeChange}
                />
              </div>

              <div className={classes.control}>
                <Select
                  menuPlacement="auto"
                  label="Mailing Type"
                  placeholder="All"
                  options={MAILING_TYPES}
                  value={this.state.campaignType}
                  isClearable={false}
                  onChange={this.handleSelectChange('campaignType')}
                  isFixedPosition={true}
                />
              </div>

              <div className={classes.control}>
                <Select
                  menuPlacement="auto"
                  label="Map"
                  options={MAP_OPTIONS}
                  value={this.state.map}
                  placeholder="All"
                  isClearable
                  onChange={this.handleSelectChange('map')}
                  isFixedPosition={true}
                  isDisabled={true}
                />
              </div>

            </Scrollbars>
          </div>
        </div>
        <div className={classes.footer}>
          <Button onClick={this.handleReset} color="primary">
            <ResetIcon />
            Reset
          </Button>
          <Button onClick={this.handleApply} color="primary" variant="contained" style={{ borderRadius: '0px', boxShadow: 'none' }}>
            Apply
          </Button>
        </div>
      </div>
    )
  }
}
