import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import ApprovedStatusIcon from '@material-ui/icons/ThumbUp'
import OrderedStatusIcon from '@material-ui/icons/ShoppingBasket'
import PrintedStatusIcon from '@material-ui/icons/Print'
import ShippedStatusIcon from '@material-ui/icons/LocalShipping'
import DeliveredStatusIcon from '@material-ui/icons/MarkunreadMailbox'

const styles = (theme) => ({})

const icons = {
  approved: ApprovedStatusIcon,
  ordered: OrderedStatusIcon,
  printed: PrintedStatusIcon,
  shipped: ShippedStatusIcon,
  delivered: DeliveredStatusIcon,
  deliveredInFirstCall: DeliveredStatusIcon,
}

const MailingStatusIcon = ({ status, ...props }) => {
  const Icon = icons[status]
  return <Icon {...props} />
}

MailingStatusIcon.propTypes = {
  status: PropTypes.oneOf([
    'approved',
    'ordered',
    'printed',
    'shipped',
    'delivered',
    'deliveredInFirstCall',
  ]).isRequired,
}

export default withStyles(styles)(MailingStatusIcon)
