import React, { useState, useEffect } from 'react'
import { Observer } from 'mobx-react'

import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import { PlayArrow, Pause } from '@material-ui/icons'

import { withMediaProps, utils } from 'react-media-player'
import SeekBar from '../../AudioPlayer/SeekBar'

import { repository as markerRepo } from 'src/models/PatientListLayer/HouseholdMarker'

const { formatTime } = utils

const styles = theme => ({
  playBtnStyle: {
    position: 'relative',
    top: '-2px',
    cursor: 'pointer'
  },
  containerStyle: {
    color: '#232323',
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  timeStyle: {
    fontSize: 14,
    fontWeight: 400,
    minWidth: 54,
    marginTop: -6,
    lineHeight: 1.3,
    display: 'block',
    textAlign: 'center',
    color: '#e1283d'
  },
  primary: {
    fill: '#e1283d !important'
  }
})

const CallAudioControls = (props) => {
  const { media, classes, callDuration, householdId } = props
  const marker = markerRepo.get(householdId)
  const playButtonRef = React.createRef()
  const timeIndicator = media.currentTime / 60 >= 1 ? 'min' : 'sec'
  const initialTimeIndicator = callDuration / 60 >= 1 ? 'min' : 'sec'
  const [currentTime, setCurentTime] = useState(formatTime(media.currentTime))

  useEffect(() => {
    setCurentTime(formatTime(media.currentTime))
  }, [media.currentTime])

  const _handlePlayPause = (e) => {
    media.playPause()
    setCurentTime(formatTime(media.currentTime))
    marker.isCallAudioPlaying = !marker.isCallAudioPlaying
    if (e) {
      e.stopPropagation()
    }
  }

  return(
    <Observer>
      {() => (
        <div className={classes.containerStyle}>
          {media.isPlaying ?
            <IconButton
              id="playPause"
              className={classes.playBtnStyle}
              color="primary"
              onClick={_handlePlayPause}
            >
              <Pause className={classes.primary} />
            </IconButton> :
            <IconButton
              id="playPause"
              buttonRef={playButtonRef}
              className={classes.playBtnStyle}
              color="primary"
              onClick={_handlePlayPause}
            >
              <PlayArrow className={classes.primary}/>
            </IconButton>
          }
          <SeekBar defaultPlayer={true}/>
          { marker.isCallAudioPlaying ?
            <time className={classes.timeStyle}> {currentTime} {timeIndicator}</time> :
            <time className={classes.timeStyle}>
              { !media.currentTime ? formatTime(callDuration) : currentTime }&nbsp;
              { !media.currentTime ? initialTimeIndicator : timeIndicator}
            </time>
          }
        </div>
      )}
    </Observer>
  )
}

export default withMediaProps(withStyles(styles)(CallAudioControls))

