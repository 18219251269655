import React, { Component } from 'react'
import { observer } from 'mobx-react'
import cx from 'classnames'
import onClickOutside from 'react-onclickoutside'

import withStyles from '@material-ui/core/styles/withStyles'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import MapStore from 'src/config/store/MapsStore'

const pickerTheme = {
  width: 'auto',
  zIndex: 999
}

const styles = theme => ({
  root: {
    position: 'absolute',
    background: '#fff',
    width: pickerTheme.width,
    zIndex: pickerTheme.zIndex,
    '-webkit-box-shadow': '0px 0px 21px 0px rgba(50, 50, 50, 0.48)',
    '-moz-box-shadow': '0px 0px 21px 0px rgba(50, 50, 50, 0.48)',
    'box-shadow': '0px 0px 21px 0px rgba(50, 50, 50, 0.48)',
    '& header': {
      padding: 12,
      borderBottom: 'solid 1px rgb(217, 217, 217)',
      display: 'flex',
      alignItems: 'center'
    },
    '& header h4': {
      color: 'rgb(26, 26, 26)',
      fontFamily: 'Roboto Condensed',
      fontWeight: 600,
      fontSize: 15,
      marginLeft: 8
    },
    '& header svg': {
      color: 'rgb(128,128,128)',
      height: '20px',
      width: '20px'
    },
    '&:before': {
      content: '""',
      width: 0,
      borderStyle: 'solid',
      height: 0,
      borderWidth: '10px 0 10px 10px',
      borderColor: 'transparent transparent transparent #ffffff',
      position: 'absolute',
      top: 'calc(50% - 10px)',
      right: '-10px'
    }
  },
  colorActionHeading: {
    color: 'rgb(26, 26, 26)',
    margin: 0,
    fontSize: 13,
    lineHeight: 1,
    marginBottom: 8
  },
  colorWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '4px',
    '& $color': {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 3
    }
  },
  color: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'red',
    height: 15,
    width: 15,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    '&:not($selected):hover': {
      transition: 'all 0.2s ease',
      '-webkit-box-shadow': '0px 0px 0 2px',
      '-moz-box-shadow': '0px 0px 0 2px',
      'box-shadow': '0px 0px 0 2px'
    }
  },
  selected: {
    '-webkit-box-shadow': '0px 0px 0px 1px #141414',
    '-moz-box-shadow': '0px 0px 0px 1px #141414',
    'box-shadow': '0px 0px 0px 1px #141414'
  },
  check: {
    display: 'none',
    color: '#fff',
    width: 16,
    height: 16,
    '$selected > &': {
      display: 'inline-block'
    }
  },
  close: {
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  actionsWrapper: {
    padding: 12
  }
})

@withStyles(styles)
@observer
@onClickOutside
export default class ColorPicker extends Component {
  state = {
    defaultColor: this.props.item.color
  }

  handleClose = () => {
    this.props.close()
  }

  handleClickOutside = () => {
    this.handleClose()
  }

  handleSelect = (color) => () => {
    const { item } = this.props
    MapStore.updateFeatureColor(item, color)
    MapStore.setFeatureColor(item, color)
    this.setState({ defaultColor: color })
    this.handleClose()
  }

  handlePreviewColor = color => event => {
    MapStore.setFeatureColor(this.props.item, color)
  }

  handleDefaultColor = () => {
    MapStore.setFeatureColor(this.props.item, this.state.defaultColor)
  }

  render() {
    const { classes, item, colorOptions, position, ...props } = this.props
    const { defaultColor } = this.state
    const width = 240
    const height = 110
    let top = (position.y - height / 2)
    if (top > (window.innerHeight - height - 10)) {
      top = window.innerHeight - height - 10
    }
    const left = position.x - width
    return (
      <div>
        <div
          className={classes.root}
          style={{ top: `${top}px`, left: `${left}px` }}
        >
          <header>
            <span className={classes.color} style={{ background: item.color }} />
            <h4>{item.name}</h4>
            <CloseIcon onClick={this.handleClose} className={classes.close} />
          </header>
          <div className={classes.actionsWrapper}>
            <p className={classes.colorActionHeading}>Choose a color:</p>
            <div className={classes.colorWrapper}>
              {colorOptions.map((color, index) => {
                return (
                  <div
                    className={cx(classes.color, defaultColor === color ? classes.selected : null)}
                    key={index} style={{ background: color, color }}
                    onMouseEnter={this.handlePreviewColor(color)}
                    onMouseOut={this.handleDefaultColor}
                    onClick={this.handleSelect(color)}
                  >
                    <CheckIcon className={classes.check} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
