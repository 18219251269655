import React from 'react'
import { Modal } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  withTheme
} from '@material-ui/core'

import styles from './UserAccountSelectorModal.styles'

const UserAccountSelectorModal = (props) => {
  const {
    classes,
    isOpen,
    onClose,
    children,
    title,
    applyCallback
  } = props

  return (
    <Modal
      open={isOpen}
    >
      <div className={classes.root}>
        <div className={classes.paper}>
          <header className={classes.heading}>
            <span className={classes.title}>
              {title}
            </span>
            <Close onClick={onClose} />
          </header>
          <div className={classes.bodyWrapper}>
            {children}
          </div>
          <footer className={classes.buttonWrapper}>
            <Button
              color="primary"
              className={classes.btnGray}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={applyCallback}
            >
              Apply
            </Button>
          </footer>
        </div>
      </div>
    </Modal>
  )
}

export default withTheme(withStyles(styles)(UserAccountSelectorModal))