import API, { IDENTITY_API } from 'src/helpers/api'

const updatePassword = async (data) => {
  await API.put({
    api: IDENTITY_API,
    url: 'user/password',
    data: {
      'oldPassword': data.oldPassword,
      'newPassword': data.newPassword
    }
  })
  return true
}

export default updatePassword
