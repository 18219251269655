import React, { Fragment } from 'react'
import { HelpModal as HelpModalComponent } from '@mvp/mvp-react'

interface HelpModalProps {
  userData: any
  open: boolean
  hideModal: (b: boolean) => void
}

const HelpModal: React.FC<HelpModalProps> = (props) => {
  const { userData, open, hideModal } = props
  const { email, name, phone, photoUrl, role } = userData

  return (
    <HelpModalComponent
      header={
        <Fragment>
          Need help or have questions?
          <br />
          Just give me a call
        </Fragment>
      }
      open={open}
      onBackdropClick={() => {
        hideModal(true)
      }}
      coordinator={{
        avatar: `data:image/png;base64,${photoUrl}`,
        name: name,
        email: email,
        position: role,
        phone: phone,
      }}
    />
  )
}

export default HelpModal
