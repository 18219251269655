import React from 'react'
import { useHistory } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'

import AppState from 'src/config/store/AppState'

const Verify = (props) => {
    const history = useHistory() 
    const { query } = props.queryString
    const { email, otp } = query

    AppState.login(undefined, {
            verificationCode: otp,
            connection: 'email',
            email: email
        }).then(() => history.push('/mailings')).catch((e) => {
            history.push('/login')
            AppState.errorMessage('Invalid verification code')  
    }) 

    return(<div style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }}>
        <CircularProgress size={60} color="primary" /><span style={{
          marginTop: 16
        }}>Authenticating ...</span></div>)
}

export default Verify