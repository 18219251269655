// @flow

import _isEmpty from 'lodash/isEmpty'

import API, { DATA_API } from 'src/helpers/api'
import { repository as callsRepo } from 'src/models/Call'

import processCallData from './processCallData'
import accountStore from 'src/config/store/AccountStore'

/**
 *
 * @param {object} options
 */
export default async ({ page, pageSize, sort, dir, filters, searchQuery }) => {
  const accountIds = (accountStore.selectedAccounts || []).slice()

  if (_isEmpty(accountIds)) {
    return {
      meta: {},
      records: []
    }
  }

  const data = {
    accountIds,
    page,
    pageSize,
    sort,
    dir,
    filters,
    query: searchQuery
  }

  const result = await API.get({
    api: DATA_API,
    url: '/calls',
    params: data
  })

  const { meta, records } = result

  const calls = records.map(row => {
    const call = processCallData(row)
    const mergeRelated = row && row.related

    return callsRepo.upsert(
      call,
      (!mergeRelated && {
        skipMergeOnProps: ['relatedCalls']
      }) ||
        undefined
    )
  })

  return {
    meta: {
      totalCount: meta.total_count,
      pageCount: meta.page_count,
      perPage: meta.per_page,
      page: meta.page
    },
    records: calls
  }
}
