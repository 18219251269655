import _isEmpty from 'lodash/isEmpty'

import accountStore from 'src/config/store/AccountStore'
import API, { DATA_API } from 'src/helpers/api'

/**
 *
 * @param {object} options
 */
export default async ({
    filters,
    cancelToken
  }) => {

  const accountIds = (accountStore.selectedAccounts || []).slice()

  if(_isEmpty(accountIds)) {
    return {
      leads: 0,
      appointments: 0,
      conversionRate: 0
    }
  }

  const data = {
    accounts: accountIds,
    filters
  }

  const result = await API.get({
    api: DATA_API,
    url: '/mailings/summary',
    params: data,
    cancelToken: cancelToken && cancelToken.token
  })

  const {
    leads,
    appointments,
    conversionRate
	} = result

  return {
    leads,
    appointments,
    conversionRate
  }
}
