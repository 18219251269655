import { repository as appointments } from '../models/Appointment'

enum OutcomeLevel {
  None,
  Cancelled,
  Rescheduled,
  Appointment
}

export const getOutcomeStatus = (apptId: any, apptCount: number = 0): string => {
  const appt = appointments.get(apptId)
  const label = apptCount > 0 ? `${apptCount} Appointment${apptCount > 1 ? 's' : ''}` : 'No Appointments'

  if(!appt) {
    return label
  }

  if(appt.outcome === 'Rescheduled' && appt.previousAppointmentId) {
    return appt.outcome
  }

  if(appt.outcome === 'Cancelled' && appt.previousAppointmentId) {
    return appt.outcome
  }

  return label
}

export const outcomeReducer = (prev: any, curr: any) => {
  const prevOutcomeLevel = (prev && getOutcomeLevel(prev)) || -Infinity
  const currOutcomeLevel = getOutcomeLevel(curr)

  return currOutcomeLevel > prevOutcomeLevel ? curr : prev
}

const getOutcomeLevel = (apptId: any): OutcomeLevel => {
  const appt = appointments.get(apptId)

  if(appt.outcome === null) {
    return OutcomeLevel.Appointment
  }

  if (appt.outcome === 'Rescheduled' && appt.previousAppointmentId) {
    return OutcomeLevel.Rescheduled
  }

  if (appt.outcome === 'Cancelled' && appt.previousAppointmentId) {
    return OutcomeLevel.Cancelled
  }

  return OutcomeLevel.None
}


