
/* global google */
import React from 'react'
import {
  withStyles,
  Typography
} from '@material-ui/core'
import { Observer } from 'mobx-react'
import _isEmpty from 'lodash/isEmpty'
import { default as styled } from 'styled-components'
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import OutsideClickListener from 'react-outside-click-handler'
import numeral from 'numeral'

import CallAudioPlayer from './CallAudioPlayer'

import { repository as markerRepo } from 'src/models/PatientListLayer/HouseholdMarker'

const styles = () => ({
  infobox: {
    margin: '3px',
    marginLeft: '20px',
    overflow: 'visible!important',
    '& > img': {
      display: 'none'
    }
  },
  auditHeading: {
    fontSize: 20,
    color: 'rgb(225, 40, 61)',
    flex: 1
  },
  title: {
    color: 'rgb(225, 40, 61)',
    flex: 1
  },
  address: {
    fontSize: 14,
    marginBottom: 4
  },
})

const HouseholdMarkerInfobox = props => {
  const {
    classes,
    id,
    handleClose
  } = props
  const marker = markerRepo.get(id)
  const { call, infoBoxHeader, auditedAddress } = marker
  const position = new google.maps.LatLng(
    marker.lat,
    marker.lng,
    false
  )
  const productionStyle = infoBoxHeader.isMatchFound
    ? { color: '#087700', fontWeight: 'bold' }
    : { color: '#929292', fontWeight: 'normal' }
  const production = infoBoxHeader.production

  return (
    <Observer>
      {() => (
        <InfoBox
          options={{
            boxClass: classes.infobox,
            position,
            alignBottom: true,
            pixelOffset: new google.maps.Size(-180, -30)
          }}
          onDomReady={() => {
            const playPause = document.getElementById('playPause')
            const callAudio = document.getElementById('call-audio')

            playPause && playPause.addEventListener('click', (e) => {
              if (marker.isCallAudioPlaying) {
                callAudio.pause()
                marker.isCallAudioPlaying = false
              } else {
                callAudio.play()
                marker.isCallAudioPlaying = true
              }
            })
          }}
        >
          <OutsideClickListener onOutsideClick={(e) => {
            // Material UI ClickAwayListener has issues with media player, instead we are using different package for getting outside clicks
            handleClose()
          }}>
            <Dialog>
              <ContentContainer>
              <Header className={classes.auditHeading}>
                  <span className={classes.title}>{infoBoxHeader.main || 'Household'}</span>
                  {
                    production > 0 && <span style={productionStyle}>{numeral(production).format("$0,0")}</span>
                  }
                </Header>
                <Header className={classes.auditHeading}>{infoBoxHeader.sub || ''}</Header>
                <div className={classes.body}>
                  <Typography className={classes.address}>{auditedAddress || marker.address}</Typography>
                </div>
              </ContentContainer>

              { (!_isEmpty(call) && call.callAudioUrl && call.callAudioDuration) &&
                <Footer>
                  <CallAudioPlayer householdId={id} call={{
                    callAudio: call.callAudioUrl,
                    callDuration: call.callAudioDuration
                  }}/>
                </Footer>
              }
            </Dialog>
          </OutsideClickListener>
        </InfoBox>
      )}
    </Observer>
  )
}

export default withStyles(styles)(HouseholdMarkerInfobox)

const Dialog = styled.div`
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  position: relative;
  background: #fff;
  border-radius: 0;
  width: 320px;
  ::before {
    top: calc(100% + 2px);
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: rgba(0, 0, 0, 0.25);
    border-width: 17px;
    margin-left: -17px;
  }
  ::after {
    top: calc(100% - 1px);
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #ffffff;
    border-width: 16px;
    margin-left: -16px;
  }
`

const ContentContainer = styled.div`
  padding: 16px;
`

const Header = styled.div`
  display: flex;
  max-width: 100%;
  flex: 0 0 auto;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: 0.5rem;
`

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  flex: 0 0 auto;
  padding: 8px;
  border-top: 1px solid #E1E1E1;
`

const Item = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #4d4d4d;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`
