import React from 'react'
import { Observer } from 'mobx-react'
import { withStyles } from '@material-ui/core/styles'

import callsStore from '../../config/store/CallsStore'
import { defaultFont } from 'src/assets/jss/mvp'

const styles = () => ({
  root: {
    ...defaultFont,
    transition: 'all 0.5s ease',
    transitionProperty: 'left, top, right, bottom',
    willChange: 'left, top, right, bottom, position'
  },
  header: {
    paddingLeft: 35,
    paddingRight: 35,
    minHeight: 40,
    backgroundColor: 'rgba(211, 211, 211, 0.4)',
    display: 'flex',
    alignItems: 'center'
  },
  headerTitle: {
    flex: 1,
    fontSize: 16,
    color: '#232323',
    fontWeight: 500
  },
  highlights: {
    padding: '15px 35px',
    lineHeight: 1.3,
    fontSize: 14,
    '&:first-letter': {
      textTransform: 'capitalize'
    }
  },
  noHighlights: {
    textAlign: 'center',
    color: '#D2D2D2',
    boxShadow: 'inset 0 -1px rgba(0, 0, 0, 0.06)',
    fontSize: 14,
    padding: 12
  }
})

const CallDetailsHighlights = (props) => {
  const { classes } = props

  return (
    <Observer render={() => {
      const call = callsStore.drawer

      return (
        call ? (
          <div className={classes.root}>
            <div className={classes.header}>
              <span className={classes.headerTitle}>
                Call Summary
              </span>
            </div>
            {call.highlights ? (
              <div className={classes.highlights}>
                {call.highlights}
              </div>
            ) : (
              <div className={classes.noHighlights}>No call summary</div>
            )}
          </div>
        ) : null
      )
    }} />
  )
}

export default withStyles(styles)(CallDetailsHighlights)