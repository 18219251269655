import React, { useEffect, useState } from 'react'
import { Observer } from 'mobx-react'
import withStyles from '@material-ui/core/styles/withStyles'
import _get from 'lodash/get'

import ProfileSettings from 'src/components/Settings/ProfileSettings'
import AlertSettings from 'src/components/Settings/AlertSettings'
import AppState from 'src/config/store/AppState'
import settingsStore from 'src/config/store/SettingsStore'

const styles = () => ({
  container: {
    padding: '28px 24px',
    backgroundColor: '#fff',
  },
})

const Settings = (props) => {
  const { classes } = props

  const getUserSettings = async () => {
    try {
      await settingsStore.getUserSettings()
    } catch (e) {
      console.error(e)
      AppState.errorMessage(
        'Something went wrong retrieving your settings. Please try again.'
      )
    } finally {
      settingsStore.isLoading = false
    }
  }

  if (!AppState.isRestricted()) {
    useEffect(() => {
      getUserSettings()
    }, [])
  }

  return (
    <Observer
      render={() => (
        <div className={classes.container}>
          <ProfileSettings />
          <AlertSettings />
        </div>
      )}
    />
  )
}

export default withStyles(styles)(Settings)
