import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import cx from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import CallsModuleIcon from '@material-ui/icons/PermPhoneMsg'
import MailingsModuleIcon from '@material-ui/icons/PermMedia'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SettingsIcon from '@material-ui/icons/Settings'
import Logout from '@material-ui/icons/PowerSettingsNew'

import ConfirmDialog from '../Dialog/ConfirmDialog'

import MapIq from 'src/assets/img/mapiq.svg'
import MapIqActive from 'src/assets/img/mapiq-active.svg'

const styles = (theme) => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listMain: {
    marginBottom: 'auto',
  },
  listFooter: {
    marginTop: 'auto',
  },
  listItem: {
    paddingLeft: 22,
    paddingRight: 22,
    paddingTop: 16,
    paddingBottom: 18,
  },
  listItemButton: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },
  },
  listItemActive: {
    boxShadow: 'inset 3px 0 #ED154E',
    backgroundColor: 'rgba(255, 255, 255, 0.03) !important',
    '& $listIcon': {
      color: '#ED154E',
    },
  },
  listIcon: {
    color: '#fff',
    fontSize: '24px',
    marginRight: 10,
    transform: 'scale(0.9)',
    minWidth: 36,
  },
  listText: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    margin: 0,
  },
  listTextPrimary: {
    fontSize: '16px',
    color: '#fff',
    fontFamily: 'Roboto Condensed',
  },
  listTextSecondary: {
    color: '#727272',
  },
  expanded: {},
  badge: {
    transition: theme.transitions.create('opacity', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  },
  badgeExpanded: {
    position: 'absolute',
    left: 220,
  },
  badgeInner: {
    right: 0,
  },
  divider: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  hide: {
    opacity: 0,
  },
  collapseLink: {
    color: 'rgba(211, 211, 211, 0.5)',
  },
  mapIntel: {
    background: `url(${MapIq}) no-repeat`,
    height: 23,
    width: 24,
  },
  mapIntelActive: {
    background: `url(${MapIqActive}) no-repeat`,
    height: 23,
    width: 24,
  },
  logoutIcon: {
    position: 'relative',
    right: '2px',
  },
  logoff: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px 20px 20px',
  },
  modalContainer: {
    position: 'absolute !important',
    filter: 'blur(0px) !important',
    '& *': {
      filter: 'blur(0px) !important',
    },
  },
})

const propTypes = {
  expanded: PropTypes.bool,
  onToggleClick: PropTypes.func.isRequired,
}

const defaultProps = {
  expanded: false,
}

const links = ({ classes, expanded }) => [
  {
    route: '/mailings',
    primary: 'Mailings',
    icon: <MailingsModuleIcon />,
  },
  {
    route: '/calls',
    primary: 'Calls',
    icon: <CallsModuleIcon />,
  },
]

@withRouter
@withStyles(styles)
class NavigationBarLinks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmLogout: false,
      isLoggedOut: false,
    }
  }

  render() {
    const { location, classes, expanded, onToggleClick, history } = this.props
    const { confirmLogout, isLoggedOut } = this.state

    return (
      <React.Fragment>
        <List component="nav" className={cx(classes.list, classes.listMain)}>
          {links(this.props).map((link, index) => (
            <Link key={index} to={link.route}>
              <ListItem
                button
                classes={{
                  root: cx(
                    classes.listItem,
                    location.pathname.includes(link.route)
                      ? classes.listItemActive
                      : null
                  ),
                  button: classes.listItemButton,
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  {link.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: cx(classes.listText, !expanded ? classes.hide : null),
                    primary: classes.listTextPrimary,
                    secondary: classes.listTextSecondary,
                  }}
                  primary={link.primary}
                  secondary={link.secondary}
                />
                {expanded ? link.secondaryAction : null}
              </ListItem>
            </Link>
          ))}
          <Link to="/maps">
            <ListItem
              button
              classes={{
                root: cx(
                  classes.listItem,
                  location.pathname.includes('/maps')
                    ? classes.listItemActive
                    : null
                ),
                button: classes.listItemButton,
              }}
            >
              <ListItemIcon className={classes.listIcon}>
                <span
                  className={cx(
                    location.pathname.includes('/maps')
                      ? classes.mapIntelActive
                      : classes.mapIntel
                  )}
                ></span>
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: cx(classes.listText, !expanded ? classes.hide : null),
                  primary: classes.listTextPrimary,
                  secondary: classes.listTextSecondary,
                }}
                primary="Map IQ"
              />
            </ListItem>
          </Link>
        </List>
        <div className={cx(classes.listFooter)}>
          <List component="nav" className={classes.list}>
            <Divider className={classes.divider} />
            <Link
              onClick={(e) => {
                if (!confirmLogout) {
                  this.setState({ confirmLogout: true })
                }
                e.preventDefault()
              }}
              to="/logout"
            >
              <ListItem
                classes={{
                  root: classes.listItem,
                  button: classes.listItemButton,
                }}
                button
              >
                <ListItemIcon className={classes.listIcon}>
                  <Logout className={classes.logoutIcon} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  classes={{
                    root: classes.listText,
                    primary: classes.listTextPrimary,
                    secondary: classes.listTextSecondary,
                  }}
                />
              </ListItem>
            </Link>
            <Divider className={classes.divider} />
            <Link to="/settings">
              <ListItem
                button
                classes={{
                  root: cx(
                    classes.listItem,
                    '/settings' === location.pathname
                      ? classes.listItemActive
                      : null
                  ),
                  button: classes.listItemButton,
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    root: cx(classes.listText, !expanded ? classes.hide : null),
                    primary: classes.listTextPrimary,
                    secondary: classes.listTextSecondary,
                  }}
                  primary="Settings"
                />
              </ListItem>
            </Link>
            <Divider className={classes.divider} />
            <ListItem
              button
              classes={{
                root: classes.listItem,
                button: classes.listItemButton,
              }}
              onClick={onToggleClick}
            >
              <ListItemIcon className={classes.listIcon}>
                {expanded ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: cx(classes.listText, !expanded ? classes.hide : null),
                  primary: cx(classes.listTextPrimary, classes.collapseLink),
                  secondary: classes.listTextSecondary,
                }}
                primary="Collapse Menu"
              />
            </ListItem>
          </List>
          <ConfirmDialog
            classes={{
              root: classes.modalContainer,
            }}
            container={() => document.getElementById('main-section')}
            onCancel={() => {
              this.setState({ confirmLogout: false })
            }}
            onConfirm={() => {
              this.setState({ confirmLogout: true })
              this.setState({ isLoggedOut: true })
              window.location = '/logout'
            }}
            hideActions={isLoggedOut}
            open={confirmLogout}
            title={!isLoggedOut && 'Are you sure you want to logout?'}
            children={
              isLoggedOut && (
                <center className={classes.logoff}>
                  <CircularProgress
                    size={60}
                    color="primary"
                    style={{ marginBottom: 16 }}
                  />
                  <span>Logging off ...</span>
                </center>
              )
            }
          />
        </div>
      </React.Fragment>
    )
  }
}

NavigationBarLinks.propTypes = propTypes
NavigationBarLinks.defaultProps = defaultProps

export default NavigationBarLinks
